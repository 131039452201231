<template>
    <div class="space-y-12">
        <div class="border-b border-white/10 pb-12">
            <h2 class="text-base/7 font-semibold text-white">{{ $t('location.information')}}</h2>
            <p class="mt-1 text-sm/6 text-gray-400">{{ $t('information.public')}}</p>

            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <Input name="name" type="text" :label="$t('name')" size="30" v-model="props.form.name" :placeholder="$t('location.name')" :error="props.form.errors.name"/>
                <TextArea name="description" :label="$t('description')" :rows=5 v-model="props.form.description" :placeholder="$t('description')" :error="props.form.errors.description" :instructions="$t('instructions.describe.location')"/>
            </div>
        </div>

        <div class="border-b border-white/10 pb-12">
            <h2 class="text-base/7 font-semibold text-white">{{$t('location.details')}}</h2>
            <p class="mt-1 text-sm/6 text-gray-400">{{$t('information.location.find')}}</p>
            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <Input name="phone" type="tel" :label="$t('phone.number')" size="30" v-model="props.form.phone" :placeholder="$t('phone.number')" :error="props.form.errors.phone"/>
                <Input name="email" type="email" :label="$t('email.address')" size="30" v-model="props.form.email" :placeholder="$t('email.address')" :error="props.form.errors.email"/>
                <Input name="website" type="url" :label="$t('website')" size="30" v-model="props.form.website" :placeholder="$t('website')" :error="props.form.errors.website"/>
                <Select name="country" :label="$t('country')" :options="countries" :flag=true :error="props.form.errors.country" v-model="props.form.country"  />
                <Input name="address" type="text" :label="$t('address')" size="30" v-model="props.form.address" :placeholder="$t('address')" :error="props.form.errors.address" class="col-span-full" />
                <Input name="city" type="text" :label="$t('city')" size="30" v-model="props.form.city" :placeholder="$t('city')" :error="props.form.errors.city" class="sm:col-span-2 sm:col-start-1"/>
                <Input name="state" type="text" :label="$t('state')" size="30" v-model="props.form.state" :placeholder="$t('state')" :error="props.form.errors.state" class="sm:col-span-2"/>
                <Input name="zip" type="text" :label="$t('zip.code')" size="30" v-model="props.form.zip" :placeholder="$t('zip.code')" :error="props.form.errors.zip" class="sm:col-span-2"/>
            </div>
        </div>
    </div>

</template>
<script setup lang="ts">
import Select from "../../../Components/Form/Select.vue";
import TextArea from "../../../Components/Form/TextArea.vue";
import Input from "../../../Components/Form/Input.vue";

const props = defineProps({
    form: {
        type: Object,
        required: true,
    },
    countries: {
        type: Array,
        required: true,
    }
})
</script>
