<template>
    <div class="space-y-12">
        <div class="border-b border-white/10 pb-12">
            <h2 class="text-base/7 font-semibold text-white">{{ $t('subscription.information')}}</h2>
            <p class="mt-1 text-sm/6 text-gray-400">{{ $t('information.public')}}</p>


            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <Input name="start_date" type="date" :label="$t('valid.from')" size="30" v-model="props.form.start_date" :placeholder="$t('valid.from')" :error="props.form.errors.start_date"/>
                <Input name="end_date" type="date" :label="$t('valid.until')" size="30" v-model="props.form.end_date" :placeholder="$t('valid.until')" :error="props.form.errors.end_date"/>
                <Select name="status" :label="$t('status')" size="30" v-model="props.form.status" :error="props.form.errors.status" :options="statusOptions"/>
            </div>
        </div>
    </div>
</template>

<script setup>
import Select from "../../../../Components/Form/Select.vue";
import Input from "../../../../Components/Form/Input.vue";

const props = defineProps({
    form: {
        type: Object,
        required: true,
    },
    statusOptions: {
        type: Array,
        required: true,
    }
})
</script>
