<template>
    <TransitionRoot as="template" :show="props.open">
        <Dialog class="relative z-10" @close="emit('modal-close')">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                             leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500/75 transition-opacity"/>
            </TransitionChild>

            <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <TransitionChild as="template" enter="ease-out duration-300"
                                     enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                     enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                                     leave-from="opacity-100 translate-y-0 sm:scale-100"
                                     leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel
                            class="relative transform overflow-hidden rounded-lg bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                            <div class="sm:flex sm:items-start">
                                <div
                                    class="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:size-10">
                                    <QuestionMarkCircleIcon class="size-6 text-green-500" aria-hidden="true"/>
                                </div>
                                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                    <DialogTitle as="h3" class="text-base font-semibold text-gray-100">
                                        {{ $t('team.member.add') }}
                                    </DialogTitle>
                                    <div class="mt-2">
                                        <Input name="email"
                                            :label="$t('email.address')"
                                                  :placeholder="$t('email.address')"
                                                  type="text"
                                               autocomplete="email"
                                                  v-model="form.email"
                                                  :error="form.errors.email"
                                                  />
                                        <Input name="name"
                                               :label="$t('name')"
                                               :placeholder="$t('name')"
                                               type="text"
                                               autocomplete="name"
                                               v-model="form.name"
                                               :error="form.errors.name"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <button type="button"
                                        class="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-green-500 sm:ml-3 sm:w-auto"
                                        @click="send">{{ $t('send') }}
                                </button>
                                <button type="button"
                                        class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-xs ring-1 ring-inset ring-gray-300 hover:bg-gray-200 sm:mt-0 sm:w-auto"
                                        @click="emit('modal-close')" ref="cancelButtonRef">{{ $t('cancel') }}
                                </button>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue'
import {onClickOutside} from '@vueuse/core'
import {inject, ref} from 'vue'
import {QuestionMarkCircleIcon} from "@heroicons/vue/24/outline/index.js";
import {useForm} from "@inertiajs/vue3";
import axios from "axios";
import Input from "../../../Components/Form/Input.vue";
const route = inject('route');

const props = defineProps({
    open: {
        type: Boolean,
        required: true,
        default: false,
    }
})

const form = useForm({
    email: '',
    name: '',
})
const emit = defineEmits(["modal-close", "modal-confirmed","member-added"]);


const target = ref(null)
onClickOutside(target, () => emit('modal-close'))


function send() {
    axios.post(route('main.settings.add.team.member'), {
        email: form.email,
        name: form.name,
    }).then((response) => {
        emit('member-added',response.data.userPermission)
        emit('modal-close')
    }).catch((error) => {
        if (error.response.status === 422) {
            form.errors = error.response.data.errors
        }
    })
}
</script>
