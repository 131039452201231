<script setup>
import Layout from "../../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../../Components/Main/Breadcrumbs.vue";
import {Link, router, useForm} from "@inertiajs/vue3";
import { Head } from '@inertiajs/vue3'
import SelectModel from "../../../../Components/Form/SelectModel.vue";
import { inject } from 'vue';
import {localeDate} from "../../../../helpers.js";

const route = inject('route');

const props = defineProps({
    student: {
        type: Object,
        required: true,
    },
    contract: {
        type: Object,
        required: true,
    },
    availableTimes: {
        type: Array,
        required: true,
    },
    futureClass: {
        type: Object,
        required: true,
    }
})

const availableTimes = props.availableTimes.map(time => {
    return {
        name:  localeDate(time.start_date,'long') + " - " + localeDate(time.end_date,'short'),
        value: time.start_date,
        id: time.start_date
    }
})

const pageTree = [
    {name: 'Student schedules', href: route('main.schedule.student'), current: false},
    {name: props.student.name, href: route('main.schedule.student.detail',[props.student,props.contract]), current: false},
    {name: 'Add class', href: route('main.schedule.student.add',[props.student,props.contract]), current: true}
]

const form = useForm({
    status: 'pending',
    contract_id: null,
    time: '',
})

function submit() {
    form.post(route('main.schedule.student.store',[props.student,props.contract]), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.schedule.student.detail',[props.student,props.contract]))
        }
    })
}

</script>

<template>
    <Head title="Add scheduled class" />
    <Layout>
        <Breadcrumbs :pageTree=pageTree />
        <form @submit.prevent="submit" >
            <div class="space-y-12">
                <div class="border-b border-white/10 pb-12">
                    <h2 class="text-base/7 font-semibold text-white">{{  $t('scheduled.class') }} - {{ props.futureClass.activity.name}}</h2>
                    <p class="mt-1 text-sm/6 text-gray-400">{{ props.futureClass.location.name }}, {{ props.futureClass.room.name}} {{ $t('with')}} {{ props.futureClass.trainer.name}}</p>

                    <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <SelectModel name="time" :label="$t('date.and.time')" :options="availableTimes" v-model="form.time" :error="form.errors.time" />
                    </div>
                </div>


            </div>

            <div class="mt-6 flex items-center justify-end gap-x-6">
                <Link :href="route('main.schedule.student.detail',[props.student,props.contract])" as="button" class="text-sm/6 font-semibold text-white">Cancel
                </Link>
                <button type="submit"
                        class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    Save
                </button>
            </div>
        </form>
    </Layout>
</template>
