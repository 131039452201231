<template>
    <Head :title="$t('profile')"/>
    <Layout>
        <div class="flex flex-row align-middle justify-between">
            <Breadcrumbs :pageTree="pageTree"/>
        </div>
        <form @submit.prevent="submit">

            <div class="space-y-12">
                <div class="border-b border-white/10 pb-12">
                    <h2 class="text-base/7 font-semibold text-white">{{ $t('profile.information')}}</h2>
                    <div class="mt-10 grid gap-x-6 gap-y-8 w-60">
                        <Input name="name" type="text" :label="$t('name')" size="30" v-model="form.name" :placeholder="$t('name')" :error="form.errors.name"/>
                    </div>
                </div>

                <div class="border-b border-white/10 pb-12">
                    <h2 class="text-base/7 font-semibold text-white">{{  $t('profile.infromation.login') }}</h2>
                    <p class="mt-1 text-sm/6 text-gray-400">{{ $t('information.affects.login')}}</p>
                    <div class="mt-10 grid gap-x-6 gap-y-8 w-60">
                        <Input name="email" type="email" :label="$t('email.address')" size="30" v-model="form.email" :placeholder="$t('email.address')" :error="form.errors.email"/>
                        <Input name="password" type="password" :label="$t('password')" size="30" v-model="form.password" :placeholder="$t('password')" :error="form.errors.password"/>
                        <Input name="password_confirmation" type="password" :label="$t('password.confirmation')" size="30" v-model="form.password_confirmation" :placeholder="$t('password.confirmation')" :error="form.errors.password_confirmation"/>
                    </div>
                    <div v-if="props.sessionInfo" class="text-indigo-700">{{ props.sessionInfo }}</div>

                </div>
                <div class="border-b border-white/10 pb-12">
                    <h2 class="text-base/7 font-semibold text-white">{{  $t('profile.infromation.socials') }}</h2>
                    <p class="mt-1 text-sm/6 text-gray-400">{{ $t('information.login.socials')}}</p>
                    <div class="mt-10 grid gap-x-6 gap-y-8 w-60">
                        <a v-if="!checkIfUserHasSocial('google')" :href="route('socialite.redirect.associate','google')" class="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent">
                            <svg class="h-5 w-5" aria-hidden="true" viewBox="0 0 24 24">
                                <path d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z" fill="#EA4335" />
                                <path d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z" fill="#4285F4" />
                                <path d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z" fill="#FBBC05" />
                                <path d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z" fill="#34A853" />
                            </svg>
                            <span class="text-sm/6 font-semibold">Google</span>
                        </a>
                        <a v-if="checkIfUserHasSocial('google')" :href="route('socialite.redirect.deassociate','google')" class="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent">
                            <span class="text-sm/6 font-semibold">{{ $t('disconnect')}}</span>
                            <svg class="h-5 w-5" aria-hidden="true" viewBox="0 0 24 24">
                                <path d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z" fill="#EA4335" />
                                <path d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z" fill="#4285F4" />
                                <path d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z" fill="#FBBC05" />
                                <path d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z" fill="#34A853" />
                            </svg>
                            <span class="text-xs/6 font-semibold">{{getSocial('google').email}}</span>
                        </a>
                        <a v-if="!checkIfUserHasSocial('facebook')" :href="route('socialite.redirect.associate','facebook')" class="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent">
                            <svg class="h-5 w-5" aria-hidden="true" viewBox="0 0 48 48">
                                <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Color-" transform="translate(-200.000000, -160.000000)" fill="#4460A0">
                                        <path d="M225.638355,208 L202.649232,208 C201.185673,208 200,206.813592 200,205.350603 L200,162.649211 C200,161.18585 201.185859,160 202.649232,160 L245.350955,160 C246.813955,160 248,161.18585 248,162.649211 L248,205.350603 C248,206.813778 246.813769,208 245.350955,208 L233.119305,208 L233.119305,189.411755 L239.358521,189.411755 L240.292755,182.167586 L233.119305,182.167586 L233.119305,177.542641 C233.119305,175.445287 233.701712,174.01601 236.70929,174.01601 L240.545311,174.014333 L240.545311,167.535091 C239.881886,167.446808 237.604784,167.24957 234.955552,167.24957 C229.424834,167.24957 225.638355,170.625526 225.638355,176.825209 L225.638355,182.167586 L219.383122,182.167586 L219.383122,189.411755 L225.638355,189.411755 L225.638355,208 L225.638355,208 Z" id="Facebook">

                                        </path>
                                    </g>
                                </g>
                            </svg>
                            <span class="text-sm/6 font-semibold">Facebook</span>
                        </a>
                        <a v-if="checkIfUserHasSocial('facebook')" :href="route('socialite.redirect.deassociate','facebook')" class="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent">
                            <span class="text-sm/6 font-semibold">{{ $t('disconnect')}}</span>
                            <svg class="h-5 w-5" aria-hidden="true" viewBox="0 0 48 48">
                                <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Color-" transform="translate(-200.000000, -160.000000)" fill="#4460A0">
                                        <path d="M225.638355,208 L202.649232,208 C201.185673,208 200,206.813592 200,205.350603 L200,162.649211 C200,161.18585 201.185859,160 202.649232,160 L245.350955,160 C246.813955,160 248,161.18585 248,162.649211 L248,205.350603 C248,206.813778 246.813769,208 245.350955,208 L233.119305,208 L233.119305,189.411755 L239.358521,189.411755 L240.292755,182.167586 L233.119305,182.167586 L233.119305,177.542641 C233.119305,175.445287 233.701712,174.01601 236.70929,174.01601 L240.545311,174.014333 L240.545311,167.535091 C239.881886,167.446808 237.604784,167.24957 234.955552,167.24957 C229.424834,167.24957 225.638355,170.625526 225.638355,176.825209 L225.638355,182.167586 L219.383122,182.167586 L219.383122,189.411755 L225.638355,189.411755 L225.638355,208 L225.638355,208 Z" id="Facebook">

                                        </path>
                                    </g>
                                </g>
                            </svg>
                            <span class="text-xs/6 font-semibold">{{getSocial('facebook').email}}</span>
                        </a>
                    </div>
                </div>

            </div>
                <div class="mt-6 flex items-center justify-end gap-x-6">
                    <button type="submit"
                            class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                        {{ $t('save') }}
                    </button>
                </div>
        </form>
    </Layout>
</template>

<script setup>
import {Head, useForm} from "@inertiajs/vue3";
import Breadcrumbs from "../../../Components/Tenant/Breadcrumbs.vue";
import Layout from "../../../Layouts/Tenant/Layout.vue";
import { inject } from 'vue';
import Input from "../../../Components/Form/Input.vue";

const route = inject('route');

const props = defineProps({
    profile: {
        type: Object,
        required: true,
    },
    sessionInfo: {
        type: String,
        required: false,
    }
})

const form = useForm({
    name: props.profile.name,
    email: props.profile.email,
    password: '',
    password_confirmation: '',
})

const pageTree = [
    {name: 'profile', href: route('tenant.profile'), current: true}
]

function submit() {
    form.patch(route('tenant.profile.patch'), {
        preserveScroll: true,
    })
}

function checkIfUserHasSocial(provider) {
    return props.profile.socials.filter(social => social.provider === provider).length > 0;
}

function getSocial(provider) {
    return props.profile.socials.filter(social => social.provider === provider)[0];
}
</script>
