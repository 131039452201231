import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import "@fontsource/inter";
import "../css/app.css";
import { ZiggyVue } from '../../vendor/tightenco/ziggy';
import {BackendFetch, DevTools, FormatSimple, Tolgee, VueTolgee} from '@tolgee/vue';
import { FormatIcu } from '@tolgee/format-icu';
import * as Sentry from "@sentry/vue";
import { useLocalStorage } from '@vueuse/core';
import 'vuefinder/dist/style.css';
import VueFinder from "vuefinder/dist/vuefinder";

const selectedLanguage = useLocalStorage('language', 'ro', {
    mergeDefaults: true
})

const tolgee = Tolgee()
    .use(DevTools())
    .use(FormatSimple())
    .use(FormatIcu())
    .use(BackendFetch())
    .init({
        language: selectedLanguage.value,

        // for development
        apiUrl: 'https://tolgee.k8s.ro',
        apiKey: 'tgpak_gfpwg5dgmfvtg4bvnnywq3tooyzggmbwnjqwcyrqozwwq',

    });

createInertiaApp({
    resolve: name => {
        const pages = import.meta.glob('./Pages/**/*.vue', { eager: true })
        return pages[`./Pages/${name}.vue`]
    },
    title: (title) => `EasyManage :: ${title}`,
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue)
            .use(VueTolgee, { tolgee })
            .use(VueFinder);
        Sentry.init({
            app,
            dsn: "https://deb4ce8399db04d2210b7aeaaedabe42@o4508964635475968.ingest.de.sentry.io/4508964636852304",
            release: import.meta.env.APP_VERSION,
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration({

                }),
            ],
            replaysOnErrorSampleRate: 1.0,
        });
        app.mount(el);

    },
})
