<script setup>
import Layout from "../../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../../Components/Main/Breadcrumbs.vue";
import {Link} from "@inertiajs/vue3";
import {PlusCircleIcon} from "@heroicons/vue/24/outline/index.js";
import {Head} from '@inertiajs/vue3'
import {ChevronRightIcon} from "@heroicons/vue/20/solid/index.js";
import { inject } from 'vue';

const route = inject('route');

const pageTree = [
    {name: 'pricing', href: route('main.prices'), current: false},
    {name: 'coupons', href: route('main.coupons'), current: true},
]

const props = defineProps({
    coupons: {
        type: Array,
        required: true,
    },
    currency: {
        type: String,
        required: true,
    }
})

</script>

<template>
    <Head :title="$t('coupons')"/>
    <Layout>
        <div class="flex flex-row align-middle justify-between">
            <Breadcrumbs :pageTree="pageTree"/>
            <Link :href="route('main.coupons.add')" class="" as="button">
                <PlusCircleIcon class="size-8 text-green-600 my-2 mb-6" aria-hidden="true" aria-label="Add coupon"/>
            </Link>
        </div>

        <ul role="list" class="divide-y divide-gray-700">
            <li v-for="coupon in props.coupons" :key="coupon.id" class="relative flex justify-between gap-x-6 py-5">
                <div class="flex min-w-0 gap-x-4">
                    <div class="min-w-0 flex-auto">
                        <p class="text-sm/6 font-semibold text-white">
                            <Link :href="route('main.coupons.edit',coupon.id)">
                                {{ coupon.name }}
                            </Link>
                        </p>
                        <span class="mt-1 text-xs/5 text-gray-200" v-if="coupon.code">
                            {{ $t('code')}}: {{ coupon.code }} &bullet;
                        </span>
                        <span class="mt-1 text-xs/5 text-gray-500" v-if="coupon.description">
                            {{$t('description') }}: {{ coupon.description }} &bullet;
                        </span>
                        <span class="mt-1 text-xs/5 text-gray-500" v-if="coupon.discount_type=='percentage'">
                            {{  $t('discount') }}: {{ coupon.discount }}% &bullet;
                        </span>
                        <span class="mt-1 text-xs/5 text-gray-500" v-if="coupon.discount_type=='fixed'">
                            {{  $t('discount') }}: {{ coupon.discount }} {{currency}} &bullet;
                        </span>
                        <span class="mt-1 text-xs/5 text-gray-500">
                            {{  $t('valid.from') }}: {{ coupon.start_date }} &bullet;
                        </span>
                        <span class="mt-1 text-xs/5 text-gray-500" v-if="coupon.end_date">
                            {{  $t('valid.until') }}: {{ coupon.end_date }}
                        </span>
                        <span class="mt-1 text-xs/5 text-gray-500" v-if="!coupon.end_date">
                            {{  $t('valid.indefinetely') }}
                        </span>
                    </div>
                </div>

                <div class="flex shrink-0 items-center gap-x-4">
                    <div class="hidden sm:flex sm:flex-col sm:items-end">
                    </div>
                    <Link :href="route('main.coupons.edit',coupon.id)" class="size-5 flex-none text-gray-400"
                          aria-hidden="true">
                        <ChevronRightIcon class="size-5 flex-none text-gray-400" aria-hidden="true"/>
                    </Link>
                </div>
            </li>
        </ul>
    </Layout>
</template>
