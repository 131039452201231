<template>
    <div class="space-y-12">
        <div class="border-b border-white/10 pb-12">
            <h2 class="text-base/7 font-semibold text-white">{{ $t('room.information')}}</h2>
            <p class="mt-1 text-sm/6 text-gray-400">{{ $t('information.public')}}</p>

            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <Input name="name" type="text" :label="$t('room.name')" size="30" v-model="props.form.name" :placeholder="$t('room.name')" :error="props.form.errors.name"/>
                <TextArea name="description" :label="$t('description')" :rows=5 v-model="props.form.description" :placeholder="$t('description')" :error="props.form.errors.description" :instructions="$t('instructions.describe.room')"/>
            </div>
        </div>

        <div class="border-b border-white/10 pb-12">
            <h2 class="text-base/7 font-semibold text-white">{{ $t('room.details')}}</h2>
            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <Input name="capacity" type="number" :label="$t('capacity')" size="30" v-model="props.form.capacity" :placeholder="$t('capacity')" :error="props.form.errors.capacity"/>
            </div>
        </div>
    </div>
</template>

<script setup>
import TextArea from "../../../Components/Form/TextArea.vue";
import Input from "../../../Components/Form/Input.vue";

const props = defineProps({
    form: {
        type: Object,
        required: true
    }
})
</script>
