<script setup>
import Layout from "../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../Components/Main/Breadcrumbs.vue";
import {Link} from "@inertiajs/vue3";
import {PlusCircleIcon} from "@heroicons/vue/24/outline/index.js";
import {Head} from '@inertiajs/vue3'
import {ChevronRightIcon} from "@heroicons/vue/20/solid/index.js";
import { inject } from 'vue';

const route = inject('route');

const pageTree = [
    {name: 'classes', href: route('main.activities'), current: true}
]

const props = defineProps({
    activities: {
        type: Array,
        required: true,
    }
})

</script>

<template>
    <Head :title="$t('classes')"/>
    <Layout>
        <div class="flex flex-row align-middle justify-between">
            <Breadcrumbs :pageTree="pageTree"/>
            <Link :href="route('main.activities.add')" class="" as="button">
                <PlusCircleIcon class="size-8 text-green-600 my-2 mb-6" aria-hidden="true" aria-label="Add activity"/>
            </Link>
        </div>

        <ul role="list" class="divide-y divide-gray-700">
            <li v-for="activity in props.activities" :key="activity.id" class="relative flex justify-between gap-x-6 py-5">
                <div class="flex min-w-0 gap-x-4">
                    <div class="min-w-0 flex-auto">
                        <p class="text-sm/6 font-semibold text-white">
                            <Link :href="route('main.activities.edit',activity)">
                                {{ activity.name }}
                            </Link>
                        </p>
                        <ul class="mt-1 text-xs/5 text-gray-500 flex flex-wrap items-start gap-x-2">
                            <li v-if="activity.age_min && activity.age_max">{{ activity.age_min }} -
                                {{ $t('age.years',{value: activity.age_max}) }}
                            </li>
                            <li v-if="activity.capacity">{{ $t('seats',{value: activity.capacity}) }}</li>
                            <li v-if="activity.duration">{{ $t('duration.minutes',{value: activity.duration})}}</li>
                        </ul>
                    </div>
                </div>
                <div class="flex shrink-0 items-center gap-x-4">
                    <div class="hidden sm:flex sm:flex-col sm:items-end">
                        <p v-if="activity.locations.length>0" class="mt-1 text-xs/5 text-gray-500">
                            {{$t('locations')}}: {{ activity.locations.map(location => location.name).join(', ') }}<br/>
                        </p>
                        <p v-if="activity.trainers.length>0" class="mt-1 text-xs/5 text-gray-500">
                            {{$t('trainers')}}: {{ activity.trainers.map(trainer => trainer.name).join(', ') }}
                        </p>
                    </div>
                    <Link :href="route('main.activities.edit',activity)" class="size-5 flex-none text-gray-400"
                          aria-hidden="true">
                        <ChevronRightIcon class="size-5 flex-none text-gray-400" aria-hidden="true"/>
                    </Link>
                </div>
            </li>
        </ul>
    </Layout>
</template>
