<script setup>
import Layout from "../../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../../Components/Main/Breadcrumbs.vue";
import {Head, Link, router, useForm} from '@inertiajs/vue3'
import {inject, ref} from 'vue';
import SelectModel from "../../../../Components/Form/SelectModel.vue";
import Input from "../../../../Components/Form/Input.vue";
import Alert from "../../../../Components/Alert.vue";
import IndexQuestions from "./IndexQuestions.vue";
import SendNowModal from "./SendNowModal.vue";
import axios from "axios";
const route = inject('route');

const props = defineProps({
    form: {
        type: Object,
        required: true,
    },
    activities: {
        type: Array,
        required: true,
    },
    trainers: {
        type: Array,
        required: true,
    },
    locations: {
        type: Array,
        required: true,
    },
})

const pageTree = [
    {name: 'feedback.forms', href: route('main.feedback.forms'), current: false},
    {name: 'feedback.forms.edit', href: route('main.feedback.forms.edit',props.form.id), current: false}
]

const form = useForm({
    name: props.form.name,
    active: props.form.active,
    description: props.form.description,
    activity_id: props.form.activity_id,
    trainer_id: props.form.trainer_id,
    location_id: props.form.location_id,
    sendAfterActivity: props.form.sendAfterActivity,
    sendAfterActivityNumber: props.form.sendAfterActivityNumber,
});

function submit() {
    form.patch(route('main.feedback.forms.patch',props.form.id), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.feedback.forms'))
        }
    })
}

let deleteModalOpen = ref(false)

function openRemoveModal() {
    deleteModalOpen.value = true
}

function closeRemoveModal() {
    deleteModalOpen.value = false
}

function remove() {
    closeRemoveModal()
    router.delete(route('main.feedback.forms.delete',props.form.id), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.feedback.forms'))
        }
    })
}

let sendNowModalOpen = ref(false)

function openSendNowModal() {
    getUsers()
    sendNowModalOpen.value = true
}
function closeSendNowModal() {
    sendNowModalOpen.value = false
}


let users = ref([])

function getUsers() {
    axios.get(route('main.feedback.forms.getUsers', {form: props.form.id}))
        .then(response => {
            users.value = response.data.data
        })
        .catch(error => {
            console.error(error)
        })
}

</script>



<template>
    <Head :title="$t('feedback.forms.edit')"/>
    <Layout>
        <div class="flex flex-row align-middle justify-between">
            <Breadcrumbs :pageTree="pageTree"/>
        </div>
        <form @submit.prevent="submit">
            <div class="space-y-12">
                <div class="border-b border-white/10 pb-12">
                    <h2 class="text-base/7 font-semibold text-white">{{ $t('information.feedback.forms') }}</h2>
                    <p class="mt-1 text-sm/6 text-gray-400">{{ $t('information.feedback.forms.attention') }}</p>

                    <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <SelectModel name="activity_id" :label="$t('activity')" model="activities"
                                     :options="props.activities" :error="form.errors.activity_id"
                                     v-model="form.activity_id" :allow-none="true"/>
                        <br />
                        <SelectModel name="trainer_id" :label="$t('trainer')" model="trainers" :options="props.trainers"
                                     :error="form.errors.trainer_id" v-model="form.trainer_id" :allow-none="true"/>
                        <br />
                        <SelectModel name="location_id" :label="$t('location')" model="locations"
                                     :options="props.locations" :error="form.errors.location_id"
                                     v-model="form.location_id" :allow-none="true"/>
                        <br />
                        <Input name="name" type="text" :label="$t('name')" size="30" v-model="form.name"
                               placeholder="Name" :error="form.errors.name"/>
                        <br />
                        <Input name="description" type="text" :label="$t('description')" size="30"
                               v-model="form.description" placeholder="Description" :error="form.errors.description"/>
                        <br />
                        <Input name="sendAfterActivity" type="checkbox" :label="$t('sendAfterActivity')"
                               v-model="form.sendAfterActivity"/>
                        <br />
                        <Input name="sendAfterActivityNumber" type="number" :label="$t('sendAfterActivityNumber')"
                               v-model="form.sendAfterActivityNumber" min="1" max="10"/>
                        <br />

                    </div>
                </div>

            </div>
            <IndexQuestions :form="props.form" :questions="props.form.questions"/>


            <div class="mt-6 flex items-center justify-between">
                <div class="flex justify-start gap-x-6">
                    <Link :href="route('main.feedback.forms.question.add',props.form.id)" as="button" class="text-sm/6 font-semibold text-white shadow-xs px-3 py-2 bg-green-800 hover:bg-green-700 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500 rounded-md">{{ $t('question.add')}}</Link>
                    <button type="button" @click="openSendNowModal" class="text-sm/6 font-semibold text-white shadow-xs px-3 py-2 bg-indigo-800 hover:bg-indigo-700 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500 rounded-md">{{ $t('send.manual')}}</button>
                </div>

            <div class="mt-6 flex items-center justify-end gap-x-6">
                <button type="button"
                        class="rounded-md bg-red-800 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-red-700 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
                        @click="openRemoveModal">{{ $t('delete') }}</button>
                <Link :href="route('main.feedback.forms')" as="button" class="text-sm/6 font-semibold text-white">
                    {{ $t('cancel') }}
                </Link>
                <button type="submit"
                        class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    {{ $t('save') }}
                </button>
            </div>
            </div>
            <Alert :open="deleteModalOpen" :title="$t('delete')+' '+$t('feedback.form')" :message="$t('confirm.delete')+' '+$t('feedback.form')+'? '+$t('data.permanently.removed')" :confirmLabel="$t('delete')" :cancelLabel="$t('cancel')"
                   @modal-close="closeRemoveModal" @modal-confirmed="remove"/>
            <SendNowModal :form="props.form" :open="sendNowModalOpen" :users="users" @modal-close="closeSendNowModal"/>
        </form>
    </Layout>
</template>
