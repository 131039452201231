<template>
    <Head :title="$t('tenant.edit')"/>
    <Layout>
        <Breadcrumbs :pageTree="pageTree"/>
        <form @submit.prevent="submit">
            <TenantForm :form="form" :plans="props.plans" :countries="countries"/>

            <div class="mt-6 flex items-center justify-end gap-x-6">
                <Link :href="route('superAdmin.tenants.index')" as="button" class="text-sm/6 font-semibold text-white">{{$t('cancel') }}
                </Link>
                <button type="submit"
                        class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    {{$t('save')}}
                </button>
            </div>

        </form>
    </Layout>
</template>

<script setup lang="ts">
import {Head, Link, router,useForm} from "@inertiajs/vue3";
import Breadcrumbs from "../../../../Components/Main/Breadcrumbs.vue";
import Layout from "../../../../Layouts/Main/Layout.vue";
import { inject } from 'vue';
import TenantForm from "./TenantForm.vue";

const route = inject('route');

const props = defineProps({
    tenant: {
        type: Object,
        required: true
    },
    plans: {
        type: Object,
        required: true
    }
})

const pageTree = [
    {name: 'tenants', href: route('superAdmin.tenants.index'), current: false},
    {name: 'tenant.edit', href: route('superAdmin.tenants.edit',props.tenant.id), current: true}
]

function submit() {
    form.patch(route('superAdmin.tenants.update',props.tenant.id), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('superAdmin.tenants.index'))
        }
    })
}
const countries = [
    {value: 'ro', label: 'Romania'}
]

const form = useForm({
    name: props.tenant.name,
    plan_id: props.tenant.plan_id,
    subdomain: props.tenant.subdomain,
    domain: props.tenant.domain,
    company_name: props.tenant.info.name,
    legal_representative: props.tenant.info.legal_representative,
    email: props.tenant.info.email,
    phone: props.tenant.info.phone,
    address: props.tenant.info.address,
    city: props.tenant.info.city,
    state: props.tenant.info.state,
    zip: props.tenant.info.zip,
    country: props.tenant.info.country,
    website: props.tenant.info.website,
    fiscal_code: props.tenant.info.fiscal_code,
    vat_code: props.tenant.info.vat_code,
    registration_number: props.tenant.info.registration_number,
})
</script>
