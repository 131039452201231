<template>
    <Head :title="$t('plan.add')"/>
    <Layout>
        <Breadcrumbs :pageTree="pageTree"/>
        <form @submit.prevent="submit">
            <PlanForm :form="form"/>

            <div class="mt-6 flex items-center justify-end gap-x-6">
                <Link :href="route('superAdmin.plans.index')" as="button" class="text-sm/6 font-semibold text-white">{{$t('cancel') }}
                </Link>
                <button type="submit"
                        class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    {{$t('save')}}
                </button>
            </div>
        </form>
    </Layout>
</template>

<script setup lang="ts">
import {Head, Link, router,useForm} from "@inertiajs/vue3";
import Breadcrumbs from "../../../../Components/Main/Breadcrumbs.vue";
import Layout from "../../../../Layouts/Main/Layout.vue";
import { inject } from 'vue';
import PlanForm from "./PlanForm.vue";

const route = inject('route');

const pageTree = [
    {name: 'plans', href: route('superAdmin.plans.index'), current: false},
    {name: 'plan.add', href: route('superAdmin.plans.add'), current: true}
]

function submit() {
    form.post(route('superAdmin.plans.store'), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('superAdmin.plans.index'))
        }
    })
}

const form = useForm({
    name: '',
    trainersLimit: 0,
    locationsLimit: 0,
    ownDomainAccess: false,
    feedbackModuleAccess: false,
    homeworkModuleAccess: false,
    homeworkModuleType: 0,
})
</script>
