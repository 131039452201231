<script setup>
import Layout from "../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../Components/Main/Breadcrumbs.vue";
import {Link, router, useForm} from "@inertiajs/vue3";
import Alert from "../../../Components/Alert.vue";
import {ref} from "vue";
import { Head } from '@inertiajs/vue3'
import ActivityForm from "./ActivityForm.vue";
import { inject } from 'vue';

const route = inject('route');

const props = defineProps({
    activity: {
        type: Object,
        required: true,
    },
    trainers: {
        type: Array,
        required: true,
    },
    locations: {
        type: Array,
        required: true,
    }
})

const form = useForm({
    name: props.activity.name,
    description: props.activity.description,
    curriculum: props.activity.curriculum,
    age_min: props.activity.age_min,
    age_max: props.activity.age_max,
    capacity: props.activity.capacity,
    duration: props.activity.duration,
    trainers: props.activity.trainers.map(trainer => trainer.id),
    locations: props.activity.locations.map(location => location.id),
})

const pageTree = [
    {name: 'classes', href: route('main.activities'), current: false},
    {name: 'class.edit', href: route('main.activities.edit',props.activity.slug), current: true},
]

function submit() {
    form.patch(route('main.activities.patch',props.activity.slug), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.activities'))
        }
    })
}


let deleteModalOpen = ref(false)

function openRemoveModal() {
    deleteModalOpen.value = true
}

function closeRemoveModal() {
    deleteModalOpen.value = false
}

function remove() {
    closeRemoveModal()
    router.delete(route('main.activities.delete',props.activity.slug), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.activities'))
        }
    })
}

</script>

<template>
    <Head :title="$t('class.edit')" />
    <Layout>
        <Breadcrumbs :pageTree=pageTree />
        <form @submit.prevent="submit">
            <ActivityForm :form="form" :trainers="props.trainers" :locations="props.locations"/>
            <div class="mt-6 flex items-center justify-end gap-x-6">
                <button type="button"
                        class="rounded-md bg-red-800 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-red-700 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
                        @click="openRemoveModal">{{ $t('delete') }}</button>
                <Link :href="route('main.activities')" as="button" class="text-sm/6 font-semibold text-white">{{$t('cancel')}}
                </Link>
                <button type="submit"
                        class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    {{$t('save')}}
                </button>
            </div>

            <Alert :open="deleteModalOpen" :title="$t('delete')+' '+$t('class')" :message="$t('confirm.delete')+' '+$t('class')+'? '+$t('data.permanently.removed')" :confirmLabel="$t('delete')" :cancelLabel="$t('cancel')"
                   @modal-close="closeRemoveModal" @modal-confirmed="remove"/>
        </form>
    </Layout>
</template>
