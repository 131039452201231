<template>
    <div class="space-y-12">
        <div class="border-b border-white/10 pb-12">
            <h2 class="text-base/7 font-semibold text-white">{{ $t('coupon.information')}}</h2>
            <p class="mt-1 text-sm/6 text-gray-400">{{ $t('information.public')}}</p>
            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <Input name="code" type="text" :label="$t('coupon.code')" size="30" v-model="props.form.code" :placeholder="$t('coupon.code')" :error="props.form.errors.code"/>
                <Input name="name" type="text" :label="$t('name')" size="30" v-model="props.form.name" :placeholder="$t('name')" :error="props.form.errors.name"/>
                <TextArea name="description" :label="$t('description')" size="30" v-model="props.form.description" :placeholder="$t('description')" :error="props.form.errors.description"/>
                <Input name="discount" type="number" :label="$t('discount')" size="30" v-model="props.form.discount" :placeholder="$t('discount')" :error="props.form.errors.discount"/>
                <Select name="discount_type" :label="$t('discount.type')" :options="discountTypes" size="30" v-model="props.form.discount_type" :error="props.form.errors.discount_type"></Select>
                <Input name="start_date" type="date" :label="$t('valid.from')" size="30" v-model="props.form.start_date" :placeholder="$t('valid.from')" :error="props.form.errors.start_date"/>
                <Input name="end_date" type="date" :label="$t('valid.until')" size="30" v-model="props.form.end_date" :placeholder="$t('valid.until')" :error="props.form.errors.end_date"/>
            </div>
        </div>
    </div>
</template>

<script setup>
import Select from "../../../../Components/Form/Select.vue";
import TextArea from "../../../../Components/Form/TextArea.vue";
import Input from "../../../../Components/Form/Input.vue";

const props = defineProps({
    form: {
        type: Object,
        required: true
    }
})

const discountTypes = [
    {value: 'percentage', label: 'percentage'},
    {value: 'fixed', label: 'fixed'},
]
</script>
