<script setup>
import Layout from "../../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../../Components/Main/Breadcrumbs.vue";
import {Link, router, useForm} from "@inertiajs/vue3";
import { Head } from '@inertiajs/vue3'
import CouponForm from "./CouponForm.vue";
import { inject } from 'vue';

const route = inject('route');

const pageTree = [
    {name: 'Prices', href: route('main.prices'), current: false},
    {name: 'coupons', href: route('main.coupons'), current: false},
    {name: 'coupons.add', href: route('main.coupons.add'), current: true},
]

var date = new Date();
var today = date.toISOString().substring(0,10);

const form = useForm({
    code: '',
    name: '',
    description: '',
    discount: 0,
    discount_type: today,
    start_date: today,
    end_date: ''
})

function submit() {
    form.post(route('main.coupons.store'), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.coupons'))
        }
    })
}

</script>

<template>
    <Head :title="$t('coupons.add')" />
    <Layout>
        <Breadcrumbs :pageTree=pageTree />
        <form @submit.prevent="submit">
            <CouponForm :form="form" />
            <div class="mt-6 flex items-center justify-end gap-x-6">
                <Link :href="route('main.coupons')" as="button" class="text-sm/6 font-semibold text-white">{{ $t('cancel') }}
                </Link>
                <button type="submit"
                        class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    {{ $t('save') }}
                </button>
            </div>
        </form>
    </Layout>
</template>
