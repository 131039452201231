<script setup>
import Layout from "../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../Components/Main/Breadcrumbs.vue";
import {Link, router, useForm} from "@inertiajs/vue3";
import { Head } from '@inertiajs/vue3'
import PriceForm from "./PriceForm.vue";
import { inject } from 'vue';

const route = inject('route');

const pageTree = [
    {name: 'pricing', href: route('main.prices'), current: false},
    {name: 'pricing.add', href: route('main.prices.add'), current: true},
]

const date = new Date();
const today = date.toISOString().substring(0, 10);

const form = useForm({
    activity_id: '',
    trainer_id: '',
    location_id: '',
    price: 0,
    start_date: today,
    end_date: '',
    expiry_days: 30,
})

function submit() {
    form.post(route('main.prices.store'), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.prices'))
        }
    })
}
const props = defineProps({
    activities: {
        type: Array,
        required: true,
    },
    trainers: {
        type: Array,
        required: true,
    },
    locations: {
        type: Array,
        required: true,
    },
    currency: {
        type: String,
        required: true,
    }
})

</script>

<template>
    <Head :title="$t('pricing.add')" />
    <Layout>
        <Breadcrumbs :pageTree=pageTree />
        <form @submit.prevent="submit">
            <PriceForm :form="form" :activities="props.activities" :trainers="props.trainers" :currency="props.currency"/>

            <div class="mt-6 flex items-center justify-end gap-x-6">
                <Link :href="route('main.prices')" as="button" class="text-sm/6 font-semibold text-white">{{$t('cancel') }}
                </Link>
                <button type="submit"
                        class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    {{$t('save')}}
                </button>
            </div>
        </form>
    </Layout>
</template>
