<template>
    <TransitionRoot as="template" :show="open">
        <Dialog class="relative z-10" @close="emit('modal-close')">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                             leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500/75 transition-opacity"/>
            </TransitionChild>

            <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <TransitionChild as="template" enter="ease-out duration-300"
                                     enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                     enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                                     leave-from="opacity-100 translate-y-0 sm:scale-100"
                                     leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel
                            class="relative transform overflow-hidden rounded-lg bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                            <div class="sm:flex sm:items-start">
                                <div
                                    class="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:size-10">
                                    <QuestionMarkCircleIcon class="size-6 text-green-500" aria-hidden="true"/>
                                </div>
                                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                    <DialogTitle as="h3" class="text-base font-semibold text-gray-100">
                                        {{ $t('feedback.form.send.now') }}
                                    </DialogTitle>
                                    <div class="mt-2">
                                        <SelectModel :label="$t('feedback.form.send.to')" name="sendTo"
                                                v-model="sendForm.userId" class="w-full mt-2"
                                                :options="props.users" :error="sendForm.errors.userId"/>
                                    </div>
                                </div>
                            </div>
                            <div v-if="sendError!==''" class="text-red-700">{{ sendError }}</div>
                            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <button type="button"
                                        class="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-green-500 sm:ml-3 sm:w-auto"
                                        @click="send">{{ $t('send') }}
                                </button>
                                <button type="button"
                                        class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-xs ring-1 ring-inset ring-gray-300 hover:bg-gray-200 sm:mt-0 sm:w-auto"
                                        @click="emit('modal-close')" ref="cancelButtonRef">{{ $t('cancel') }}
                                </button>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue'
import {onClickOutside} from '@vueuse/core'
import {inject, ref} from 'vue'
import {QuestionMarkCircleIcon} from "@heroicons/vue/24/outline/index.js";
import SelectModel from "../../../../Components/Form/SelectModel.vue";
import {useForm} from "@inertiajs/vue3";
import axios from "axios";
const route = inject('route');

const props = defineProps({
    form: {
        type: Object,
        required: true,
    },
    users: {
        type: Array,
        required: true,
    },
    open: {
        type: Boolean,
        required: true,
        default: false,
    }
})

const sendForm = useForm({
    userId: 0,
})
const emit = defineEmits(["modal-close", "modal-confirmed"]);

let sendError = ref('')

const target = ref(null)
onClickOutside(target, () => emit('modal-close'))


function send() {
    if (sendForm.userId===0) {
        return
    }
    axios.post(route('main.feedback.forms.send', props.form.id), {
        userId: sendForm.userId,
    }).then(() => {
        emit('modal-close')
    }).catch((error) => {
        if (error.response.status === 422) {
            sendError.value = error.response.data.message
            // sendForm.errors = error.response.data.errors
        }
    })
}
</script>
