<script setup>
import Layout from "../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../Components/Main/Breadcrumbs.vue";
import {Link, router, useForm} from "@inertiajs/vue3";
import Alert from "../../../Components/Alert.vue";
import {ref} from "vue";
import {Head} from '@inertiajs/vue3'
import TrainerForm from "./TrainerForm.vue";
import { inject } from 'vue';

const route = inject('route');

const props = defineProps({
    trainer: {
        type: Object,
        required: true,
    }
})

const form = useForm({
    name: props.trainer.name,
    description: props.trainer.description,
    phone: props.trainer.phone,
    email: props.trainer.email,
    website: props.trainer.website,
    facebook: props.trainer.facebook,
    instagram: props.trainer.instagram,
    twitter: props.trainer.twitter,
    youtube: props.trainer.youtube,
    linkedin: props.trainer.linkedin,
    pinterest: props.trainer.pinterest,
    tiktok: props.trainer.tiktok,
    snapchat: props.trainer.snapchat,
    locations: props.trainer.locations.map(location => location.id),
    activities: props.trainer.activities.map(activity => activity.id),
    photo: props.trainer.photo,
    _method: 'patch',
})

const pageTree = [
    {name: 'trainers', href: route('main.trainers'), current: false},
    {name: 'trainer.edit', href: route('main.trainers.edit', props.trainer.slug), current: true},
]

function submit() {
    form.post(route('main.trainers.patch', props.trainer.slug), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.trainers'))
        }
    })
}


let deleteModalOpen = ref(false)

function openRemoveModal() {
    deleteModalOpen.value = true
}

function closeRemoveModal() {
    deleteModalOpen.value = false
}

function remove() {
    closeRemoveModal()
    router.delete(route('main.trainers.delete', props.trainer.slug), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.trainers'))
        }
    })
}

let imageUrl = ref('')
imageUrl.value=props.trainer.photo ? props.trainer.photo : ''

</script>

<template>
    <Head :title="$t('trainer.edit')"/>
    <Layout>
        <Breadcrumbs :pageTree="pageTree"/>
        <form @submit.prevent="submit">
            <TrainerForm v-model:form="form" v-model:imageUrl="imageUrl"/>

            <div class="mt-6 flex items-center justify-between">
                <div class="flex justify-start gap-x-6">
                    <Link :href="route('main.trainers.schedule',props.trainer)" as="button" class="text-sm/6 font-semibold text-white shadow-xs px-3 py-2 bg-green-800 hover:bg-green-700 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500 rounded-md">
                        {{  $t('schedule.work') }}</Link>

                </div>
                <div class="flex justify-end gap-x-6">
                <button type="button"
                        class="rounded-md bg-red-800 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-red-700 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
                        @click="openRemoveModal">{{$t('delete') }}
                </button>
                <Link :href="route('main.trainers')" as="button" class="text-sm/6 font-semibold text-white">{{$t('cancel')}}
                </Link>
                <button type="submit"
                        class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    {{$t('save')}}
                </button>
                </div>
            </div>

            <Alert :open="deleteModalOpen" :title="$t('delete')+' '+$t('trainer')" :message="$t('confirm.delete')+' '+$t('trainer')+'? '+$t('data.permanently.removed')" :confirmLabel="$t('delete')" :cancelLabel="$t('cancel')"
                   @modal-close="closeRemoveModal" @modal-confirmed="remove"/>
        </form>
    </Layout>
</template>
