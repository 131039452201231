<template>
    <div :class="props.class">
        <Listbox as="div" v-model="modelValue" @update:model-value="value => $emit('change', value)">
            <ListboxLabel v-if="props.label" class="block text-sm/6 font-medium text-white">{{ props.label }}
            </ListboxLabel>
            <div class="relative mt-2">
                <ListboxButton
                    class="grid w-full cursor-default grid-cols-1 rounded-md bg-white/5 py-1.5 pl-3 pr-2 text-left text-white outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6">
                    <span class="col-start-1 row-start-1 truncate pr-6">
                        <img v-if="modelIcon" :src="modelIcon" alt="" class="size-5 shrink-0 rounded-full"/>
<!--                        <FlagIcon v-if="props.flag" :code="modelValue" size="20" class="mt-1"/>-->
                        {{ $t(modelLabel) }}
                    </span>
                    <ChevronUpDownIcon
                        class="col-start-1 row-start-1 size-5 self-center justify-self-end text-gray-500 sm:size-4"
                        aria-hidden="true"/>
                </ListboxButton>

                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
                            leave-to-class="opacity-0">
                    <ListboxOptions
                        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-900 py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-hidden sm:text-sm">
                        <ListboxOption as="template" v-for="option in props.options" :key="option.value"
                                       :value="option.value" v-slot="{ active, selected }">
                            <li :class="[active ? 'bg-indigo-600 text-white outline-hidden' : 'text-white', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                                    <img :src="option.icon" v-if="option.icon" alt=""
                                         class="size-5 shrink-0 rounded-full"/>
<!--                                    <FlagIcon v-if="props.flag" :code="option.value" size="20"/>-->
                                    {{ $t(option.label) }}
                                </span>
                                <span v-if="selected"
                                      :class="[active ? 'text-white' : 'text-indigo-500', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                <CheckIcon class="size-5" aria-hidden="true"/>
              </span>
                            </li>
                        </ListboxOption>
                    </ListboxOptions>
                </transition>
            </div>
        </Listbox>
        <div v-if="props.error" class="text-red-700">{{ props.error }}</div>
    </div>
</template>

<script setup>
import {Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions} from '@headlessui/vue'
import {ChevronUpDownIcon} from '@heroicons/vue/16/solid'
import {CheckIcon} from '@heroicons/vue/20/solid'
import {computed} from 'vue';

const props = defineProps({
    name: {type: String, required: true},
    options: {type: Array, default: () => []},
    label: {type: String, default: ''},
    error: {type: String, default: ''},
    class: {
        type: String,
        default: 'sm:col-span-3',
    },
    flag: {type: Boolean, default: false}
});

const modelValue = defineModel()

const modelLabel = computed(() => {
    return props.options.find(option => option.value === modelValue.value)?.label
})

const modelIcon = computed(() => {
    return props.options.find(option => option.value === modelValue.value)?.icon
})

</script>
