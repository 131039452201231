<template>
    <div>
            <div class="relative overflow-hidden rounded-lg bg-gray-600 px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
                <dt>
                    <div class="absolute rounded-md bg-indigo-500 p-3">
                        <component :is="props.icon" class="size-6 text-white" aria-hidden="true"/>
                    </div>
                    <p class="ml-16 truncate text-sm font-medium text-gray-200">{{ props.name }}</p>
                </dt>
                <dd class="ml-16 flex items-baseline pb-6 sm:pb-7">
                    <p class="text-2xl font-semibold text-gray-100">{{ props.stat }}</p>
                    <p :class="[props.changeType === 'increase' ? 'text-green-600' : (props.change===0 || props.change==='0%') ? 'text-blue-500' : 'text-red-600', 'ml-2 flex items-baseline text-sm font-semibold']" v-if="props.changeType!=='hidden'">
                        <ArrowUpIcon v-if="props.changeType === 'increase'"
                                     class="size-5 shrink-0 self-center text-green-500" aria-hidden="true"/>
                        <ArrowPathRoundedSquareIcon v-else-if="props.change===0 || props.change==='0%'" class="size-5 shrink-0 self-center text-blue-500" aria-hidden="true"/>
                        <ArrowDownIcon v-else class="size-5 shrink-0 self-center text-red-500" aria-hidden="true"/>
                        <span class="sr-only"> {{
                                props.changeType === 'increase' ? 'Increased' : 'Decreased'
                            }} by </span>
                        {{ props.change }}
                    </p>
                    <div class="absolute inset-x-0 bottom-0 bg-gray-700 px-4 py-4 sm:px-6">
                        <div class="text-sm">
                            <Link :href="props.viewAllAction" class="font-medium text-indigo-400 hover:text-indigo-500"
                            >{{  $t('view.all') }}
                            </Link>
                        </div>
                    </div>
                </dd>
            </div>
    </div>
</template>

<script setup>
import { ArrowDownIcon, ArrowPathRoundedSquareIcon, ArrowUpIcon} from '@heroicons/vue/20/solid'
import {Link} from '@inertiajs/vue3'

const props = defineProps({
    icon: {
        type: Function,
        required: true
    },
    name: {
        type: String,
        required: true
    },
    stat: {
        type: [String,Number],
        required: true
    },
    changeType: {
        type: String,
        required: false,
        default: 'hidden'
    },
    change: {
        type: [String,Number],
        required: false,
    },
    viewAllAction: {
        type: String,
        required: true
    }
})

</script>
