<template>
    <div class="space-y-12" v-if="props.visible">
        <div class="border-b border-white/10 pb-12 mt-10">
            <form @submit.prevent="submit">
                <Input name="label" type="text" :label="$t('label')" size="30" v-model="form.label"
                       :placeholder="$t('label')"/>
                <Select name="type" :label="$t('billing.account.type')" v-model="form.type"
                        :options="billingAccountTypes" @change="updateVisibility"/>
                <Input name="username" type="text" :label="$t('username')" size="30"
                       v-model="form.username" :placeholder="$t('username')" v-if="usernameVisible"/>
                <Input name="password" type="password" :label="$t('password')" size="30"
                       v-model="form.password" :placeholder="$t('password')" v-if="passwordVisible"/>
                <Input name="token" type="text" :label="$t('token')" size="30" v-model="form.token"
                       :placeholder="$t('token')" v-if="tokenVisible"/>
                <div class="mt-6 flex items-center justify-end gap-x-6">
                    <button type="submit"
                            class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                        {{  $t('save') }}
                    </button>
                </div>
            </form>

        </div>
    </div>
</template>

<script setup>

import Input from "../../../../Components/Form/Input.vue";
import Select from "../../../../Components/Form/Select.vue";
import {inject, ref} from "vue";
import { router, useForm} from "@inertiajs/vue3";
const route = inject('route');

const props = defineProps({
    visible: {
        type: Boolean,
        required: true,
        default: false,
    }
})

const form = useForm({
    label: '',
    type: 'direct',
    username: '',
    password: '',
    token: '',
})

const usernameVisible = ref(false);
const passwordVisible = ref(false);
const tokenVisible = ref(false);

const billingAccountTypes = [
    {value: 'direct', label: 'billing.account.type.direct'},
    {value: 'oblio', label: 'billing.account.type.oblio'},
    {value: 'smartbill', label: 'billing.account.type.smartbill'},
]

function updateVisibility(integration) {
    switch (integration) {
        case 'direct':
            usernameVisible.value = false;
            passwordVisible.value = false;
            tokenVisible.value = false;
            break;
        case 'oblio':
            usernameVisible.value = true;
            passwordVisible.value = false;
            tokenVisible.value = true;
            break;
        case 'smartbill':
            usernameVisible.value = true;
            passwordVisible.value = false;
            tokenVisible.value = true;
            break;
        default:
            usernameVisible.value = false;
            passwordVisible.value = false;
            tokenVisible.value = false;
    }
}

function submit() {
    form.post(route('main.billing.settings.accounts.store'), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.billing.settings'))
        }
    })
}
</script>
