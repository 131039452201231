<template>
    <Head :title="$t('tenant.add')"/>
    <Layout>
        <Breadcrumbs :pageTree="pageTree"/>
        <form @submit.prevent="submit">
            <TenantForm :form="form" :plans="props.plans" :countries="countries"/>

            <div class="mt-6 flex items-center justify-end gap-x-6">
                <Link :href="route('superAdmin.tenants.index')" as="button" class="text-sm/6 font-semibold text-white">{{$t('cancel') }}
                </Link>
                <button type="submit"
                        class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    {{$t('save')}}
                </button>
            </div>
        </form>
    </Layout>
</template>

<script setup lang="ts">
import {Head, Link, router,useForm} from "@inertiajs/vue3";
import Breadcrumbs from "../../../../Components/Main/Breadcrumbs.vue";
import Layout from "../../../../Layouts/Main/Layout.vue";
import { inject } from 'vue';
import TenantForm from "./TenantForm.vue";

const route = inject('route');

const props = defineProps({
    plans: {
        type: Object,
        required: true
    }
})
const pageTree = [
    {name: 'tenants', href: route('superAdmin.tenants.index'), current: false},
    {name: 'tenant.add', href: route('superAdmin.tenants.add'), current: true}
]

function submit() {
    form.post(route('superAdmin.tenants.store'), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('superAdmin.tenants.index'))
        }
    })
}
const countries = [
    {value: 'ro', label: 'Romania'}
]
const form = useForm({
    name: '',
    plan_id: null,
    subdomain: '',
    domain: '',
    company_name: '',
    legal_representative: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    website: '',
    fiscal_code: '',
    vat_code: '',
    registration_number: '',
})
</script>
