<script setup>
import {ScheduleXCalendar} from '@schedule-x/vue'
import {
    createCalendar,
    createViewDay,
    createViewMonthAgenda,
    createViewMonthGrid,
    createViewWeek,
} from '@schedule-x/calendar'
import {createEventsServicePlugin} from '@schedule-x/events-service'
import '@schedule-x/theme-default/dist/index.css'
import {createCurrentTimePlugin} from '@schedule-x/current-time'
import { createEventModalPlugin } from '@schedule-x/event-modal'
import {getLongLocaleName} from "../../../../helpers.js";
import {useTolgee} from "@tolgee/vue";
import {usePage} from "@inertiajs/vue3";

const tolgee = useTolgee(['language']);
const eventsServicePlugin = createEventsServicePlugin();
const eventModal = createEventModalPlugin()
const props = defineProps({
    scheduledClasses: {
        type: Array,
        required: true,
    },
    publicHolidays: {
        type: Array,
        required: true,
    },
    limited: {
        type: Boolean,
        default: false,
    }
})


let localPublicHolidays = []

props.publicHolidays.forEach((holiday) => {
    localPublicHolidays.push({
        start: holiday.date,
        end: holiday.date,
        title: holiday.name,
        style: {
            backgroundImage: 'repeating-linear-gradient(45deg, #ccc, #ccc 5px, transparent 5px, transparent 10px)',
            opacity: 0.5,
        },
    })
})

let events = []

let startTime = "14:00"
let endTime = "18:00"

let calendars = []
let calendarColors = [
    {
        lightColors: {
            main: '#f9d71c',
            container: '#fff5aa',
            onContainer: '#594800',
        },
        darkColors: {
            main: '#fff5c0',
            onContainer: '#fff5de',
            container: '#a29742',
        },
    },
    {
        lightColors: {
            main: '#f91c45',
            container: '#ffd2dc',
            onContainer: '#59000d',
        },
        darkColors: {
            main: '#ffc0cc',
            onContainer: '#ffdee6',
            container: '#a24258',
        },
    },
    {
        lightColors: {
            main: '#1cf9b0',
            container: '#dafff0',
            onContainer: '#004d3d',
        },
        darkColors: {
            main: '#c0fff5',
            onContainer: '#e6fff5',
            container: '#42a297',
        },
    },
    {
        lightColors: {
            main: '#1c7df9',
            container: '#d2e7ff',
            onContainer: '#002859',
        },
        darkColors: {
            main: '#c0dfff',
            onContainer: '#dee6ff',
            container: '#426aa2',
        },
    }
];

const page = usePage();
const currentUserId = page.props.user.id;

let idx = 0;
props.scheduledClasses.forEach((scheduledClass) => {
    if (scheduledClass.start_date.split(' ')[1] < startTime) {
        startTime = scheduledClass.start_date.split(' ')[1].split(':')[0] + ":" + scheduledClass.start_date.split(':')[1]
    }
    if (scheduledClass.end_date.split(' ')[1] > endTime) {
        endTime = scheduledClass.end_date.split(' ')[1].split(':')[0] + ":" + scheduledClass.end_date.split(':')[1]
    }
    if (!calendars[scheduledClass.room.name]) {
        calendars[scheduledClass.room.name] = {
            colorName: scheduledClass.room.name,
            lightColors: calendarColors[idx].lightColors,
            darkColors: calendarColors[idx].darkColors,
        }
        idx++;
        if (idx >= calendarColors.length) {
            idx = 0;
        }
    }
    if (props.limited) {
        if (scheduledClass.trainer.user_id===currentUserId) {
            // show all data for classes where the current user is the trainer
            events.push({
                id: scheduledClass.id,
                title: scheduledClass.activity.name,
                people: [scheduledClass.student.name],
                start: scheduledClass.start_date.split(':')[0] + ":" + scheduledClass.start_date.split(':')[1],
                end: scheduledClass.end_date.split(':')[0] + ":" + scheduledClass.end_date.split(':')[1],
                location: scheduledClass.room.name,
                calendarId: scheduledClass.room.name
            })
        } else {
            // show only the activity name for classes where the current user is not the trainer
            events.push({
                id: scheduledClass.id,
                title: scheduledClass.activity.name,
                start: scheduledClass.start_date.split(':')[0] + ":" + scheduledClass.start_date.split(':')[1],
                end: scheduledClass.end_date.split(':')[0] + ":" + scheduledClass.end_date.split(':')[1],
                location: scheduledClass.room.name,
                calendarId: scheduledClass.room.name
            })
        }
    } else {
        // show all data for admin users
        events.push({
            id: scheduledClass.id,
            title: scheduledClass.activity.name,
            people: [scheduledClass.student.name,scheduledClass.trainer.name],
            start: scheduledClass.start_date.split(':')[0] + ":" + scheduledClass.start_date.split(':')[1],
            end: scheduledClass.end_date.split(':')[0] + ":" + scheduledClass.end_date.split(':')[1],
            location: scheduledClass.room.name,
            calendarId: scheduledClass.room.name
        })
    }
})

const calendarApp = createCalendar({
    locale: getLongLocaleName(tolgee.value.getLanguage()),
    views: [
        createViewDay(),
        createViewWeek(),
        createViewMonthGrid(),
        createViewMonthAgenda(),
    ],
    events: events,
    backgroundEvents: localPublicHolidays,
    isDark: true,
    dayBoundaries: {
        start: startTime,
        end: endTime,
    },
    weekOptions: {
        gridHeight: 1000,
    },
    calendars: calendars,
}, [eventsServicePlugin, createCurrentTimePlugin(), eventModal])

function loadEvents() {

}

loadEvents()
</script>

<template>
    <div>
        <ScheduleXCalendar :calendar-app="calendarApp"/>
    </div>
</template>
