<template>
    <Menu as="div" class="relative ml-3">
        <div>
            <MenuButton
                class="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                <span class="absolute -inset-1.5"/>
                <span class="sr-only">Change language menu</span>
                <img :src="'/img/flags/' + selectedLanguage + '.png'" alt="" class="size-6 shrink-0 rounded-full"/>
            </MenuButton>
        </div>
        <transition enter-active-class="transition ease-out duration-100"
                    enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <MenuItems
                class="absolute right-0 z-10 mt-2 w-28 origin-top-right rounded-xl divide-y divide-gray-100 bg-gray-900 py-1 shadow-lg ring-1 ring-black/5 focus:outline-none">
                <MenuItem v-slot="{ active }" v-for="language in languages" :key="language.value" class="w-full">
                    <button href="#"
                            :class="[active ? 'bg-gray-500 text-gray-900 outline-none' : 'text-gray-100', 'group flex items-center px-3 py-2 text-sm '] "
                            @click="changeLanguage(language.value)">
                        <img :src="'/img/flags/' + language.value + '.png'" alt=""
                             class="size-5 shrink-0 mr-2 rounded-xl"/>
                        {{ language.name }}
                    </button>
                </MenuItem>
            </MenuItems>
        </transition>
    </Menu>
</template>

<script setup>
import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/vue'
import {useTolgee} from '@tolgee/vue';
import {useLocalStorage} from '@vueuse/core'
import {ref} from "vue";

const selectedLanguage = useLocalStorage('language', 'en', {
    mergeDefaults: true
})

let languages = ref([
    {name: 'English', value: 'en'},
    {name: 'Română', value: 'ro'},
])

const tolgee = useTolgee(['language']);

const changeLanguage = (e) => {
    tolgee.value.changeLanguage(e);
    selectedLanguage.value = e;
};

</script>
