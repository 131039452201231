<template>
    <div class="bg-gray-800 py-12 sm:py-8 rounded-xl">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <div class="mx-auto max-w-2xl lg:mx-0">
                <h2 class="text-base/7 font-semibold text-indigo-400">{{ $t('everything.you.need')}}</h2>
                <p class="mt-6 text-lg/8 text-gray-300">{{
                         $t('everything.you.need.longer')
                    }}</p>
            </div>
            <dl class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 text-base/7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-16">
                <div v-for="feature in features" :key="feature.name" class="relative pl-9">
                    <dt class="inline font-semibold text-white">
                        <component :is="feature.icon" class="absolute left-1 top-1 size-5 text-indigo-500" aria-hidden="true" />
                        {{ $t(feature.name) }}
                    </dt>
                    {{ ' ' }}
                    <dd class="inline">{{ $t(feature.description) }}</dd>
                </div>
            </dl>
        </div>
    </div>
</template>

<script setup>


import {CalendarDaysIcon} from "@heroicons/vue/24/outline/index.js";
import {CurrencyEuroIcon} from "@heroicons/vue/24/solid/index.js";
import {
    BanknotesIcon,
    DocumentDuplicateIcon,
    DocumentTextIcon,
    PhotoIcon,
    QuestionMarkCircleIcon
} from "@heroicons/vue/24/outline";

const features = [
    {
        name: 'handle.scheduling',
        description: 'handle.scheduling.description',
        icon: CalendarDaysIcon,
    },
    {
        name: 'feature.pricing',
        description: 'feature.pricing.description',
        icon: CurrencyEuroIcon,
    },
    {
        name: 'feature.media',
        description: 'feature.media.description',
        icon: PhotoIcon,
    },
    {
        name: 'feature.contracts',
        description: 'feature.contracts.description',
        icon: DocumentDuplicateIcon,
    },
    {
        name: 'feature.billing',
        description: 'feature.billing.description',
        icon: BanknotesIcon,
    },
    {
        name: 'feature.feedback',
        description: 'feature.feedback.description',
        icon: QuestionMarkCircleIcon,
    },
    {
        name: 'feature.reports',
        description: 'feature.reports.description',
        icon: DocumentTextIcon,
    }
]
</script>
