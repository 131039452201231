<template>
    <div class="space-y-12">
        <div class="border-b border-white/10 pb-12">
            <h2 class="text-base/7 font-semibold text-white">{{  $t('bundle.information') }}</h2>
            <p class="mt-1 text-sm/6 text-gray-400">{{
                     $t('information.public')
                }}</p>

            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <Input name="quantity" type="number" :label="$t('quantity')" size="30" v-model="props.form.quantity" :placeholder="$t('quantity')" :error="props.form.errors.quantity"/>
                <Input name="price" type="number" :label="$t('price') + ' (' + currency + ')'" size="30" v-model="props.form.price" :placeholder="$t('price')" :error="props.form.errors.price"/>
                <Input name="start_date" type="date" :label="$t('valid.from')" size="30" v-model="props.form.start_date" :placeholder="$t('valid.from')" :error="props.form.errors.start_date"/>
                <Input name="end_date" type="date" :label="$t('valid.until')" size="30" v-model="props.form.end_date" :placeholder="$t('valid.until')" :error="props.form.errors.end_date"/>
                <Input name="expiry_days" type="number" :label="$t('validity')+' (' + $t('days') + ')'" size="30" v-model="props.form.expiry_days" :placeholder="$t('validity')" :error="props.form.errors.expiry_days"/>
                <Input name="cancelNumber" type="number" :label="$t('cancellation.number')" size="30" v-model="props.form.cancelNumber" :placeholder="$t('cancellation.number')" :error="props.form.errors.cancelNumber"/>
                <Input name="cancelBeforeInterval" type="number" :label="$t('cancellation.before')" size="30" v-model="props.form.cancelBeforeInterval" :placeholder="$t('cancellation.before')" :error="props.form.errors.cancelBeforeInterval"/>
            </div>
        </div>

    </div>
</template>

<script setup>
import Input from "../../../../Components/Form/Input.vue";
const props = defineProps({
    form: {
        type: Object,
        required: true,
    },
    currency: {
        type: String,
        required: true,
    }
})
</script>
