<script setup>
import Layout from "../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../Components/Main/Breadcrumbs.vue";
import {Link} from "@inertiajs/vue3";
import {PlusCircleIcon} from "@heroicons/vue/24/outline/index.js";
import {Head} from '@inertiajs/vue3'
import {ChevronRightIcon} from "@heroicons/vue/20/solid/index.js";
import { inject } from 'vue';

const route = inject('route');

const pageTree = [
    {name: 'students', href: route('main.students'), current: true}
]

const props = defineProps({
    students: {
        type: Array,
        required: true,
    }
})

</script>

<template>
    <Head :title="$t('students')"/>
    <Layout>
        <div class="flex flex-row align-middle justify-between">
            <Breadcrumbs :pageTree="pageTree"/>
            <Link :href="route('main.students.add')" class="" as="button">
                <PlusCircleIcon class="size-8 text-green-600 my-2 mb-6" aria-hidden="true" aria-label="Add student"/>
            </Link>
        </div>

        <ul role="list" class="divide-y divide-gray-700">
            <li v-for="student in props.students" :key="student.id" class="relative flex justify-between gap-x-6 py-5">
                <div class="flex min-w-0 gap-x-4">
                    <div class="min-w-0 flex-auto">
                        <p class="text-sm/6 font-semibold text-white">
                            <Link :href="route('main.students.edit',student.id)">
                                {{ student.first_name }} {{ student.last_name }}
                            </Link>
                        </p>
                        <p class="mt-1 flex text-xs/5 text-gray-500">
                            {{ $t('phone')}}: {{ student.phone }}
                        </p>
                        <p class="mt-1 flex text-xs/5 text-gray-500" v-if="student.child_accounts.length>0">
                            {{  $t('child.accounts') }}: {{ student.child_accounts.length }}
                        </p>
                        <p class="mt-1 flex text-xs/5 text-gray-500" v-if="student.parent_account_id">
                            {{$t('child.account.of')}}&nbsp;<span class="text-gray-200"><Link :href="route('main.students.edit',student.parent_account_id)"> {{ student.parent_account.first_name }} {{ student.parent_account.last_name }}</Link></span>
                        </p>

                    </div>
                </div>
                <div class="flex shrink-0 items-center gap-x-4">
                    <div class="hidden sm:flex sm:flex-col sm:items-end">
                    </div>
                    <Link :href="route('main.students.edit',student.id)" class="size-5 flex-none text-gray-400"
                          aria-hidden="true">
                        <ChevronRightIcon class="size-5 flex-none text-gray-400" aria-hidden="true"/>
                    </Link>
                </div>
            </li>
        </ul>
    </Layout>
</template>
