<script setup>
import Layout from "../../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../../Components/Main/Breadcrumbs.vue";
import {Head, Link, router, useForm} from '@inertiajs/vue3'
import {inject} from 'vue';
import SelectModel from "../../../../Components/Form/SelectModel.vue";
import Input from "../../../../Components/Form/Input.vue";
const route = inject('route');

const pageTree = [
    {name: 'feedback.forms', href: route('main.feedback.forms'), current: false},
    {name: 'feedback.forms.add', href: route('main.feedback.forms.add'), current: false}
]

const props = defineProps({
    activities: {
        type: Array,
        required: true,
    },
    trainers: {
        type: Array,
        required: true,
    },
    locations: {
        type: Array,
        required: true,
    },
})

const form = useForm({
    name: '',
    active: true,
    description: '',
    activity_id: null,
    trainer_id: null,
    location_id: null,
    sendAfterActivity: true,
    sendAfterActivityNumber: 1,
});

function submit() {
    form.post(route('main.feedback.forms.store'), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.feedback.forms'))
        }
    })
}

</script>

<template>
    <Head :title="$t('feedback.forms.add')"/>
    <Layout>
        <div class="flex flex-row align-middle justify-between">
            <Breadcrumbs :pageTree="pageTree"/>
        </div>
        <form @submit.prevent="submit">
            <div class="space-y-12">
                <div class="border-b border-white/10 pb-12">
                    <h2 class="text-base/7 font-semibold text-white">{{ $t('information.feedback.forms') }}</h2>
                    <p class="mt-1 text-sm/6 text-gray-400">{{ $t('information.feedback.forms.attention') }}</p>

                    <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <SelectModel name="activity_id" :label="$t('activity')" model="activities"
                                     :options="props.activities" :error="form.errors.activity_id"
                                     v-model="form.activity_id" :allow-none="true"/>
                        <br />
                        <SelectModel name="trainer_id" :label="$t('trainer')" model="trainers" :options="props.trainers"
                                     :error="form.errors.trainer_id" v-model="form.trainer_id" :allow-none="true"/>
                        <br />
                        <SelectModel name="location_id" :label="$t('location')" model="locations"
                                     :options="props.locations" :error="form.errors.location_id"
                                     v-model="form.location_id" :allow-none="true"/>
                        <br />
                        <Input name="name" type="text" :label="$t('name')" size="30" v-model="form.name"
                               placeholder="Name" :error="form.errors.name"/>
                        <br />
                        <Input name="description" type="text" :label="$t('description')" size="30"
                               v-model="form.description" placeholder="Description" :error="form.errors.description"/>
                        <br />
                        <Input name="sendAfterActivity" type="checkbox" :label="$t('sendAfterActivity')"
                               v-model="form.sendAfterActivity"/>
                        <br />
                        <Input name="sendAfterActivityNumber" type="number" :label="$t('sendAfterActivityNumber')"
                               v-model="form.sendAfterActivityNumber" min="1" max="10"/>
                        <br />
                    </div>
                </div>

            </div>

            <div class="mt-6 flex items-center justify-end gap-x-6">
                <Link :href="route('main.feedback.forms')" as="button" class="text-sm/6 font-semibold text-white">
                    {{ $t('cancel') }}
                </Link>
                <button type="submit"
                        class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    {{ $t('save') }}
                </button>
            </div>
        </form>
    </Layout>
</template>
