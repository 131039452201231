<template>
    <div class="flex items-center justify-between border-t border-gray-200 bg-gray-800 py-2" v-if="props.invoices.last_page>1">
        <div class="flex flex-1 justify-between sm:hidden">
            <a href="#"
               class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-100 hover:bg-gray-50">Previous</a>
            <a href="#"
               class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-100 hover:bg-gray-50">Next</a>
        </div>
        <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
                <p class="text-sm text-gray-300">
                    {{ $t('pagination.showing')}}
                    {{ ' ' }}
                    <span class="font-medium">{{ props.invoices.from}}</span>
                    {{ ' ' }}
                    {{ $t('pagination.to')}}
                    {{ ' ' }}
                    <span class="font-medium">{{  props.invoices.to }}</span>
                    {{ ' ' }}
                    {{ $t('pagination.of')}}
                    {{ ' ' }}
                    <span class="font-medium">{{ props.invoices.total}}</span>
                    {{ ' ' }}
                    {{ $t('pagination.results')}}
                </p>
            </div>
            <div>
                <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                    <Link :href="props.invoices.prev_page_url" v-if="props.invoices.prev_page_url"
                          class="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-300 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                        <span class="sr-only">Previous</span>
                        <ChevronLeftIcon class="size-5" aria-hidden="true"/>
                    </Link>
                    <Link :href="props.invoices.path + '?page=' + idx" v-for="idx in props.invoices.last_page"
                          :key="idx"
                          :class="'relative hidden items-center px-4 py-2 text-sm font-semibold text-gray-300 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex ' + (props.invoices.current_page===idx ? 'bg-gray-700' : '')">
                        {{ idx }}
                    </Link>
                    <Link :href="props.invoices.next_page_url" v-if="props.invoices.next_page_url"
                          class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-300 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                        <span class="sr-only">Next</span>
                        <ChevronRightIcon class="size-5" aria-hidden="true"/>
                    </Link>
                </nav>

            </div>
        </div>
    </div>
</template>

<script setup>
import {Link} from "@inertiajs/vue3";

import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/vue/24/outline/index.js";

const props = defineProps({
    invoices: {
        type: Object,
        required: true
    }
})
</script>
