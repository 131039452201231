<script setup>
import Layout from "../../../Layouts/Tenant/Layout.vue";
import Breadcrumbs from "../../../Components/Tenant/Breadcrumbs.vue";
import {Head} from '@inertiajs/vue3'
import Calendar from "./Calendar.vue";
import { inject } from 'vue';

const route = inject('route');

const props = defineProps({
    scheduledClasses: {
        type: Array,
        required: true,
    },
    publicHolidays: {
        type: Array,
        required: true,
    }
})
const pageTree = [
    {name: 'schedule.student', href: route('tenant.schedule.calendar'), current: true},
]
</script>

<template>
    <Head :title="$t('schedule.student')"/>
    <Layout>
        <div class="flex flex-row align-middle justify-between">
            <Breadcrumbs :pageTree="pageTree"/>
        </div>
        <div class="w-full">
            <Calendar :scheduledClasses="props.scheduledClasses" :publicHolidays="props.publicHolidays"/>
        </div>
    </Layout>
</template>
