<template>
    <Head title="Login" />
    <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-sm">
            <img class="mx-auto h-10 w-auto" :src="$page.props.tenant.icon" :alt="$page.props.tenant.name" />
            <h2 class="mt-10 text-center text-2xl/9 font-bold tracking-tight text-white">Forgot password</h2>
        </div>

        <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                E-mail confirmation was sent to your email address. Please check your inbox.
        </div>
    </div>
</template>

<script setup>
</script>
