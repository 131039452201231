<script setup>
import Layout from "../../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../../Components/Main/Breadcrumbs.vue";
import {Link, router, useForm} from "@inertiajs/vue3";
import { Head } from '@inertiajs/vue3'
import {ref} from "vue";
import Alert from "../../../../Components/Alert.vue";
import CouponForm from "./CouponForm.vue";
import { inject } from 'vue';

const route = inject('route');

const props = defineProps({
    coupon: {
        type: Object,
        required: true,
    },
})

const pageTree = [
    {name: 'pricing', href: route('main.prices'), current: false},
    {name: 'coupons', href: route('main.coupons'), current: false},
    {name: 'coupons.edit', href: route('main.coupons.edit',props.coupon), current: true},
]

const form = useForm({
    code: props.coupon.code,
    name: props.coupon.name,
    description: props.coupon.description,
    discount: props.coupon.discount,
    discount_type: props.coupon.discount_type,
    start_date: props.coupon.start_date,
    end_date: props.coupon.end_date,
})

function submit() {
    form.patch(route('main.coupons.patch',props.coupon.id), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.coupons'))
        }
    })
}


let deleteModalOpen = ref(false)

function openRemoveModal() {
    deleteModalOpen.value = true
}

function closeRemoveModal() {
    deleteModalOpen.value = false
}

function remove() {
    closeRemoveModal()
    router.delete(route('main.coupons.delete',props.coupon.id), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.coupons'))
        }
    })
}
</script>

<template>
    <Head :title="$t('coupon.edit')" />
    <Layout>
        <Breadcrumbs :pageTree=pageTree />
        <form @submit.prevent="submit">
            <CouponForm :form="form" />

            <div class="mt-6 flex items-center justify-end gap-x-6">
                <button type="button"
                        class="rounded-md bg-red-800 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-red-700 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
                        @click="openRemoveModal">{{  $t('delete') }}</button>
                <Link :href="route('main.coupons')" as="button" class="text-sm/6 font-semibold text-white">{{ $t('cancel') }}
                </Link>
                <button type="submit"
                        class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    {{ $t('save') }}
                </button>
            </div>

            <Alert :open="deleteModalOpen" :title="$t('delete')+' '+$t('coupon').toLowerCase()" :message="$t('confirm.delete')+' '+$t('coupon').toLowerCase()+'? '+$t('data.permanently.removed')" :confirmLabel="$t('delete')" :cancelLabel="$t('cancel')"
                   @modal-close="closeRemoveModal" @modal-confirmed="remove"/>
        </form>
    </Layout>
</template>
