<template>
    <div class="space-y-12">
        <div class="border-b border-white/10 pb-12">
            <h2 class="text-base/7 font-semibold text-white">{{ $t('student.billing.details')}}</h2>
            <p class="mt-1 text-sm/6 text-gray-400">{{  $t('information.necessary.invoicing') }}</p>
            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <SelectModel name="billing_account_id" :label="$t('billing.account')" v-model="props.form.billing_account_id" :options="props.billingAccounts" :error="props.form.errors.billing_account_id"/>
                <Input name="use_details_above" type="checkbox" :label="$t('use.address.above')" v-model="use_details_above" @change="useDetailsAbove"/>
                <Input name="billing_name" type="text" :label="$t('name.billing')" size="30" v-model="props.form.billing_name" :placeholder="$t('name.billing')" :error="props.form.errors.billing_name"/>
                <Input name="billing_fiscal_code" type="text" :label="$t('fiscal.code')" size="30" v-model="props.form.billing_fiscal_code" :placeholder="$t('fiscal.code')" :error="props.form.errors.billing_fiscal_code"/>
                <Input name="billing_registration_number" type="text" :label="$t('registration.number')" size="30" v-model="props.form.billing_registration_number" :placeholder="$t('registration.number')" :error="props.form.errors.billing_registration_number"/>
                <Input name="billing_address" :label="$t('address')" size="30" v-model="props.form.billing_address" :placeholder="$t('address')" :error="props.form.errors.billing_address"/>
                <Input name="billing_city" type="text" :label="$t('city')" size="30" v-model="props.form.billing_city" :placeholder="$t('city')" :error="props.form.errors.billing_city"/>
                <Input name="billing_state" type="text" :label="$t('state')" size="30" v-model="props.form.billing_state" :placeholder="$t('state')" :error="props.form.errors.billing_state"/>
                <Input name="billing_zip" type="text" :label="$t('zip.code')" size="30" v-model="props.form.billing_zip" :placeholder="$t('zip.code')" :error="props.form.errors.billing_zip"/>
                <Select name="billing_country" :label="$t('country')" size="30" v-model="props.form.billing_country" :options="props.countries" :error="props.form.errors.billing_country"/>
                <Input name="billing_phone" type="tel" :label="$t('phone.number')" size="30" v-model="props.form.billing_phone" :placeholder="$t('phone.number')" :error="props.form.errors.billing_phone"/>
                <Input name="billing_email" type="email" :label="$t('email.address')" size="30" v-model="props.form.billing_email" :placeholder="$t('email.address')" :error="props.form.errors.billing_email"/>
                <Input name="bank_name" type="text" :label="$t('bank.name')" size="30" v-model="props.form.bank_name" :placeholder="$t('bank.name')" :error="props.form.errors.bank_name"/>
                <Input name="bank_account" type="text" :label="$t('bank.account')" size="30" v-model="props.form.bank_account" :placeholder="$t('bank.account')" :error="props.form.errors.bank_account"/>
                <Input name="billing_contact_person" type="text" :label="$t('contact.person')" size="30" v-model="props.form.billing_contact_person" :placeholder="$t('contact.person')" :error="props.form.errors.billing_contact_person"/>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import Select from "../../../Components/Form/Select.vue";
import Input from "../../../Components/Form/Input.vue";
import {ref} from "vue";
import SelectModel from "../../../Components/Form/SelectModel.vue";

const props = defineProps({
    form: {
        type: Object,
        required: true,
    },
    countries: {
        type: Array,
        required: true,
    },
    billingAccounts: {
        type: Array,
        required: true,
    }
})

let use_details_above = ref(false)

function useDetailsAbove(target) {
    if (target.target._modelValue) {
        props.form.billing_name = props.form.first_name + ' ' + props.form.last_name
        props.form.billing_contact_person = props.form.first_name + ' ' + props.form.last_name
        props.form.billing_address = props.form.address
        props.form.billing_city = props.form.city
        props.form.billing_state = props.form.state
        props.form.billing_zip = props.form.zip
        props.form.billing_country = props.form.country
        props.form.billing_email = props.form.email
        props.form.billing_phone = props.form.phone
    } else {
        props.form.billing_name = ''
        props.form.billing_contact_person = ''
        props.form.billing_address = ''
        props.form.billing_city = ''
        props.form.billing_state = ''
        props.form.billing_zip = ''
        props.form.billing_country = ''
        props.form.billing_email = ''
        props.form.billing_phone = ''
    }
}
</script>
