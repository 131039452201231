<script setup>
import Layout from "../../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../../Components/Main/Breadcrumbs.vue";
import {Head} from '@inertiajs/vue3'
import {inject} from 'vue';
import ResultCards from "./ResultCards.vue";

const route = inject('route');

const props = defineProps({
    form: {
        type: Object,
        required: true,
    }
})

const pageTree = [
    {name: 'feedback.results', href: route('main.feedback.results'), current: true},
    {name: 'feedback.result.form', href: route('main.feedback.results.form', props.form.id), current: true}
]

function getAnswers(log) {
    let answers = [];
    props.form.answers.forEach((answer) => {
        if (answer.feedback_log_id === log.id) {
            let answerValue = answer.answer;
            let question = props.form.questions.find(q => q.id === answer.feedback_question_id);
            let answerObj = {
                id: answer.id,
                question: question ? question.question : '',
                answer: answerValue
            }
            answers.push(answerObj);
        }
    });
    return answers
}
</script>

<template>
    <Head :title="$t('feedback.result.form')"/>
    <Layout>
        <div class="flex flex-row align-middle justify-between">
            <Breadcrumbs :pageTree="pageTree"/>
        </div>
        <ResultCards :form="props.form"/>
        <br/>
        <hr/>
        <ul role="list" class="divide-y divide-gray-700">
            <li v-for="log in props.form.log" :key="log.id" class="relative flex justify-between gap-x-6 py-5">
                <div class="flex min-w-0 gap-x-4">
                    <!--                <img class="size-12 flex-none rounded-full bg-gray-50" :src="item.imageUrl" alt="" />-->
                    <div class="min-w-0 flex-auto">
                        <p class="text-sm/6 font-semibold text-white">
                            {{ log.user.name }}
                        </p>
                        <p class="mt-1 flex text-xs/5 text-gray-500">
                            {{ $t('sent.at') }}: {{ log.sent_at }} &bull;
                            {{ $t('status') }}: {{ $t(log.status) }}
                        </p>
                        <p class="mt-1 flex text-xs/5 text-gray-400">
                            <span v-for="(answer,index) in getAnswers(log)" :key="answer.id"
                                  class="text-s/ text-gray-100 mr-1">
                                {{ answer.question }}: {{ answer.answer }}
                                {{ index < getAnswers(log).length - 1 ? ' &bull; ' : '' }}
                            </span>
                        </p>
                    </div>
                </div>
            </li>
        </ul>
    </Layout>
</template>
