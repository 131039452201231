<script setup>
import Layout from "../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../Components/Main/Breadcrumbs.vue";
import {Link, router, useForm} from "@inertiajs/vue3";
import { Head } from '@inertiajs/vue3'
import Alert from "../../../Components/Alert.vue";
import {ref} from "vue";
import StudentForm from "./StudentForm.vue";
import { inject } from 'vue';
import StudentBillingDetailsForm from "./StudentBillingDetailsForm.vue";

const route = inject('route');

const props = defineProps({
    student: {
        type: Object,
        required: true,
    },
    billingDetails: {
        type: Object,
        required: true,
    },
    billingAccounts: {
        type: Array,
        required: true,
    }
})

const pageTree = [
    {name: 'students', href: route('main.students'), current: false},
    {name: 'student.edit', href: route('main.students.edit',props.student.id), current: true},
]

const form = useForm({
    first_name: props.student.first_name,
    last_name: props.student.last_name,
    email: props.student.email,
    phone: props.student.phone,
    dob: props.student.dob,
    address: props.student.address,
    city: props.student.city,
    state: props.student.state,
    country: props.student.country,
    zip: props.student.zip,
    parent_account_email: props.student.parent_account_email,
    billing_account_id: props.student.billing_account_id,
    billing_name: props.billingDetails.name,
    billing_fiscal_code: props.billingDetails.fiscal_code,
    billing_registration_number: props.billingDetails.registration_number,
    billing_address: props.billingDetails.address,
    billing_city: props.billingDetails.city,
    billing_state: props.billingDetails.state,
    billing_zip: props.billingDetails.zip,
    billing_country: props.billingDetails.country,
    billing_phone: props.billingDetails.phone,
    billing_email: props.billingDetails.email,
    billing_bank_name: props.billingDetails.bank_name,
    billing_bank_account: props.billingDetails.bank_account,
    billing_contact_person: props.billingDetails.contact_person,
})

function submit() {
    form.patch(route('main.students.patch',props.student.id), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.students'))
        }
    })
}

const countries = [
    {value: 'ro', label: 'Romania'},
]


let deleteModalOpen = ref(false)

function openRemoveModal() {
    deleteModalOpen.value = true
}

function closeRemoveModal() {
    deleteModalOpen.value = false
}

function remove() {
    closeRemoveModal()
    router.delete(route('main.students.delete',props.student.id), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.students'))
        }
    })
}
</script>

<template>
    <Head :title="$t('student.edit')" />
    <Layout>
        <Breadcrumbs :pageTree=pageTree />
        <form @submit.prevent="submit">
            <StudentForm :form="form" :countries="countries"/>
            <StudentBillingDetailsForm :countries="countries" :form="form" :billingAccounts="billingAccounts"/>

            <div class="mt-6 flex items-center justify-end gap-x-6">
                <button type="button"
                        class="rounded-md bg-red-800 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-red-700 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
                        @click="openRemoveModal">{{$t('delete')}}</button>
                <Link :href="route('main.students')" as="button" class="text-sm/6 font-semibold text-white">{{$t('cancel')}}
                </Link>
                <button type="submit"
                        class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    {{$t('save')}}
                </button>
            </div>

            <Alert :open="deleteModalOpen" :title="$t('delete')+' '+$t('student')" :message="$t('confirm.delete')+' '+$t('student')+'? '+$t('data.permanently.removed')" :confirmLabel="$t('delete')" :cancelLabel="$t('cancel')"
                   @modal-close="closeRemoveModal" @modal-confirmed="remove"/>
        </form>
    </Layout>
</template>
