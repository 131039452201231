<script setup>
import Layout from "../../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../../Components/Main/Breadcrumbs.vue";
import {Head} from '@inertiajs/vue3'
import Calendar from "./Calendar.vue";
import { inject } from 'vue';

const route = inject('route');

const props = defineProps({
    trainer: {
        type: Object,
        required: true,
    },
    scheduledClasses: {
        type: Array,
        required: true,
    },
    publicHolidays: {
        type: Array,
        required: true,
    }
})
const pageTree = [
    {name: 'schedule.trainer', href: route('main.schedule.trainer'), current: false},
    {name: props.trainer.name, href: route('main.schedule.trainer.detail',props.trainer), current: true}
]
</script>

<template>
    <Head :title="props.trainer.name"/>
    <Layout>
        <div class="flex flex-row align-middle justify-between">
            <Breadcrumbs :pageTree="pageTree"/>
        </div>
        <div class="w-full">
        <Calendar :scheduledClasses="scheduledClasses" :publicHolidays="publicHolidays"/>
        </div>
    </Layout>
</template>
