<template>
    <div class="">
        <div class="mt-8 flow-root">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table class="min-w-full divide-y divide-gray-300">
                        <thead>
                        <tr>
                            <TableHeaderItem :sort-direction="props.sortDirection" :sort-by="sortBy" sort-field="type"
                                             :label="$t('invoice.type')" @sort="updateSort"/>
                            <TableHeaderItem :sort-direction="props.sortDirection" :sortBy="sortBy" sort-field="number"
                                             :label="$t('number')" @sort="updateSort"/>
                            <TableHeaderItem :label="$t('customer')" :sort-enabled="false"/>
                            <TableHeaderItem :sort-direction="props.sortDirection" :sortBy="sortBy"
                                             sort-field="due_date" :label="$t('invoice.due_date')" @sort="updateSort"/>
                            <TableHeaderItem :sort-direction="props.sortDirection" :sortBy="sortBy"
                                             sort-field="issue_date" :label="$t('invoice.date')" @sort="updateSort"/>
                            <TableHeaderItem :sort-direction="props.sortDirection" :sortBy="sortBy" sort-field="total"
                                             :label="$t('invoice.amount')" @sort="updateSort"/>
                            <TableHeaderItem :sort-direction="props.sortDirection" :sortBy="sortBy" sort-field="status"
                                             :label="$t('status')" @sort="updateSort"/>
                        </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200 bg-gray-800">
                        <tr v-for="invoice in props.invoices" :key="invoice.id" class="even:bg-gray-700">
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-100 sm:pl-0">
                                {{ $t('invoice.type.' + invoice.type) }}
                            </td>
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-100 sm:pl-0">
                                {{ invoice.series }}-{{ invoice.number }}
                            </td>
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-100 sm:pl-0">
                                {{ invoice.billing_detail.name }}
                                <ul class="text-xs text-gray-400">
                                    <li v-for="item in invoice.items" :key="item.id">
                                        {{ item.name }}<span
                                        v-if="item.description!==''">, {{ item.description }}</span>
                                    </li>
                                </ul>
                            </td>
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-100 sm:pl-0">
                                {{ invoice.issue_date }}
                            </td>
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-100 sm:pl-0">
                                {{ invoice.due_date }}
                            </td>
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-100 sm:pl-0">
                                {{ invoice.total }}
                            </td>
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-100 sm:pl-0">
                                {{ $t('invoice.status.' + invoice.status) }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import TableHeaderItem from "./TableHeaderItem.vue";
import {router} from '@inertiajs/vue3'

const props = defineProps({
    invoices: {
        type: Array,
        required: true,
    },
    sortBy: {
        type: String,
        required: false,
        default: 'issue_date',
    },
    sortDirection: {
        type: String,
        required: false,
        default: 'desc',
    },
})

function updateSort({field, direction}) {
    router.reload({
        data: {
            sortBy: field,
            sortDirection: direction,
        }
    })
}

</script>
