<script setup>
import Layout from "../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../Components/Main/Breadcrumbs.vue";
import {Link} from "@inertiajs/vue3";
import {PlusCircleIcon} from "@heroicons/vue/24/outline/index.js";
import {Head} from '@inertiajs/vue3'
import {ChevronRightIcon} from "@heroicons/vue/20/solid/index.js";
import { inject } from 'vue';

const route = inject('route');

const pageTree = [
    {name: 'pricing', href: route('main.prices'), current: true}
]

const props = defineProps({
    prices: {
        type: Array,
        required: true,
    },
    currency: {
        type: String,
        required: true,
    }
})

</script>

<template>
    <Head :title="$t('pricing')"/>
    <Layout>
        <div class="flex flex-row align-middle justify-between">
            <Breadcrumbs :pageTree="pageTree"/>
            <Link :href="route('main.prices.add')" class="" as="button">
                <PlusCircleIcon class="size-8 text-green-600 my-2 mb-6" aria-hidden="true" aria-label="Add price"/>
            </Link>
        </div>

        <ul role="list" class="divide-y divide-gray-700">
            <li v-for="price in props.prices" :key="price.id" class="relative flex justify-between gap-x-6 py-5">
                <div class="flex min-w-0 gap-x-4">
                    <!--                <img class="size-12 flex-none rounded-full bg-gray-50" :src="item.imageUrl" alt="" />-->
                    <div class="min-w-0 flex-auto">
                        <p class="text-sm/6 font-semibold text-white">
                            <Link :href="route('main.prices.edit',price.id)">
                                {{ price.activity.name }}
                            </Link>
                        </p>
                        <ul class="mt-1 text-xs/5 text-gray-500 flex flex-wrap items-start gap-x-2">
                            <li v-if="price.trainer_id">{{$t('trainer')}}: {{ price.trainer.name }}</li>
                            <li v-if="!price.trainer_id">{{ $t('trainer') }}: {{ $t('all') }}</li>
                            <li v-if="price.location_id">{{ $t('location') }}: {{ price.location.name }}</li>
                            <li v-if="!price.location_id">{{ $t('location')}}: {{$t('all')}}</li>
                        </ul>
                        <span class="mt-1 text-xs/5 text-gray-500">
                            {{ $t('price') }}: {{ price.price }} {{ currency}} &bullet;
                        </span>
                        <span class="mt-1 text-xs/5 text-gray-500">
                            {{ $t('valid.from') }}: {{ price.start_date }} &bullet;
                        </span>
                        <span class="mt-1 text-xs/5 text-gray-500" v-if="price.end_date">
                            {{  $t('valid.until') }}: {{ price.end_date }} &bullet;
                        </span>
                        <span class="mt-1 text-xs/5 text-gray-500">
                            {{  $t('validity') }}: {{ price.expiry_days }} {{  $t('days') }}
                        </span>
                        <ul class="mt-1 text-xs/5 text-gray-500 flex flex-wrap items-start gap-x-1" v-if="price.bundles.length>0">
                            <li v-for="(bundle,idx) in price.bundles" :key="bundle.id">
                                    <span>{{ $t('classes.plural',{value: bundle.quantity}) }} {{  $t('priced.at') }} {{ bundle.price }}</span> <span v-if="idx<price.bundles.length-1">&bullet;</span>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="flex shrink-0 items-center gap-x-4">
                    <div class="hidden sm:flex sm:flex-col sm:items-end">
                    </div>
                    <Link :href="route('main.prices.edit',price.id)" class="size-5 flex-none text-gray-400"
                          aria-hidden="true">
                        <ChevronRightIcon class="size-5 flex-none text-gray-400" aria-hidden="true"/>
                    </Link>
                </div>
            </li>
        </ul>
    </Layout>
</template>
