<script setup>
import Layout from "../../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../../Components/Main/Breadcrumbs.vue";
import {Link, router, useForm} from "@inertiajs/vue3";
import {Head} from '@inertiajs/vue3'
import { inject } from 'vue';
import Input from "../../../../Components/Form/Input.vue";
import TextArea from "../../../../Components/Form/TextArea.vue";
import Select from "../../../../Components/Form/Select.vue";
const route = inject('route');
const props = defineProps({
    trainer: {
        type: Object,
        required: true
    },
})
const pageTree = [
    {name: 'vacation', href: route('main.trainers.vacation.index',props.trainer.id), current: false},
    {name: 'vacation.add', href: route('main.trainers.vacation.add',props.trainer.id), current: true},
]

const form = useForm({
    start_date: '',
    end_date: '',
    type: 'vacation',
    notes: '',
})

function submit() {
    form.post(route('main.trainers.vacation.store',props.trainer.id), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.trainers.vacation.index',props.trainer.id))
        }
    })
}

</script>

<template>
    <Head :title="$t('trainer.vacation.add')"/>
    <Layout>
        <Breadcrumbs :pageTree="pageTree"/>
        <form @submit.prevent="submit">
            <div class="space-y-12">
                <div class="border-b border-white/10 pb-12">
                    <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <Input name="start_date" type="date" :label="$t('date.start')" size="30" v-model="form.start_date" :error="form.errors.start_date"/>
                        <Input name="end_date" type="date" :label="$t('date.end')" size="30" v-model="form.end_date" :error="form.errors.end_date"/>
                        <Select name="type" :label="$t('vacation.type')" :options="[
                        {value: 'vacation', label: $t('vacation.vacation')},
                        {value: 'sick', label: $t('vacation.sick')},
                        {value: 'other', label: $t('vacation.other')}
                    ]" v-model="form.type" :error="form.errors.type"/>
                        <TextArea name="notes" :label="$t('vacation.notes')" size="30" v-model="form.notes" :error="form.errors.notes"/>
                    </div>
                </div>
            </div>
            <div class="mt-6 flex items-center justify-end gap-x-6">
                <Link :href="route('main.trainers.vacation.index',props.trainer.id)" as="button" class="text-sm/6 font-semibold text-white">
                    {{ $t('cancel')}}
                </Link>
                <button type="submit"
                        class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    {{ $t('save')}}
                </button>
            </div>
        </form>
    </Layout>
</template>
