<script setup>
import Layout from "../../../Layouts/Main/Layout.vue";
import { Head } from '@inertiajs/vue3'
import StatsCard from "../../../Components/Main/StatsCard.vue";
import {
    AcademicCapIcon,
    BuildingLibraryIcon,
    CalendarIcon,
    QuestionMarkCircleIcon,
    UserGroupIcon,
    UsersIcon
} from "@heroicons/vue/24/outline";
const props = defineProps({
    stats: {
        type: Object,
        required: true
    }
})
</script>

<template>
    <Layout>
        <Head title="Dashboard" />
        <h3 class="text-base font-semibold text-gray-100">{{  $t('general.stats') }}</h3>
        <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-6">
            <StatsCard :view-all-action="route('main.students')" :stat="props.stats['total_students']" :name="$t('total.students')" :icon="UserGroupIcon"/>
            <StatsCard :view-all-action="route('main.trainers')" :stat="props.stats['total_trainers']" :name="$t('total.trainers')" :icon="UsersIcon"/>
            <StatsCard :view-all-action="route('main.locations')" :stat="props.stats['total_locations']" :name="$t('total.locations')" :icon="BuildingLibraryIcon"/>
            <StatsCard :view-all-action="route('main.activities')" :stat="props.stats['total_activities']" :name="$t('total.activities')" :icon="AcademicCapIcon"/>
        </dl>
        <h3 class="text-base font-semibold text-gray-100 mt-5">{{  $t('scheduled.classes.stats') }}</h3>
        <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-6">
            <StatsCard :view-all-action="route('main.schedule.student')" :stat="props.stats['scheduled_classes']['today']['value']" :changeType="props.stats['scheduled_classes']['today']['changeType']" :change="props.stats['scheduled_classes']['today']['changePercentage'] + '%'" :name="$t('today')" :icon="CalendarIcon"/>
            <StatsCard :view-all-action="route('main.schedule.student')" :stat="props.stats['scheduled_classes']['this_week']['value']" :changeType="props.stats['scheduled_classes']['this_week']['changeType']" :change="props.stats['scheduled_classes']['this_week']['changePercentage'] + '%'" :name="$t('this.week')" :icon="CalendarIcon"/>
            <StatsCard :view-all-action="route('main.schedule.student')" :stat="props.stats['scheduled_classes']['this_month']['value']" :changeType="props.stats['scheduled_classes']['this_month']['changeType']" :change="props.stats['scheduled_classes']['this_month']['changePercentage'] + '%'" :name="$t('this.month')" :icon="CalendarIcon"/>
        </dl>
        <h3 class="text-base font-semibold text-gray-100 mt-5">{{  $t('feedback.stats') }}</h3>
        <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-6">
            <StatsCard :view-all-action="route('main.feedback.results')" :stat="props.stats['feedback']['today']['sent']" :changeType="props.stats['feedback']['today']['sentChangeType']" :change="props.stats['feedback']['today']['sentChangePercentage']" :name="$t('total.feedback.sent.today')" :icon="QuestionMarkCircleIcon" />
            <StatsCard :view-all-action="route('main.feedback.results')" :stat="props.stats['feedback']['today']['responded']" :changeType="props.stats['feedback']['today']['respondedChangeType']" :change="props.stats['feedback']['today']['respondedChangePercentage']" :name="$t('total.feedback.responded.today')" :icon="QuestionMarkCircleIcon" />
            <StatsCard :view-all-action="route('main.feedback.results')" :stat="props.stats['feedback']['this_week']['sent']" :changeType="props.stats['feedback']['this_week']['sentChangeType']" :change="props.stats['feedback']['this_week']['sentChangePercentage']" :name="$t('total.feedback.sent.thisWeek')" :icon="QuestionMarkCircleIcon" />
            <StatsCard :view-all-action="route('main.feedback.results')" :stat="props.stats['feedback']['this_week']['responded']" :changeType="props.stats['feedback']['this_week']['respondedChangeType']" :change="props.stats['feedback']['this_week']['respondedChangePercentage']" :name="$t('total.feedback.responded.thisWeek')" :icon="QuestionMarkCircleIcon" />
            <StatsCard :view-all-action="route('main.feedback.results')" :stat="props.stats['feedback']['this_month']['sent']" :changeType="props.stats['feedback']['this_month']['sentChangeType']" :change="props.stats['feedback']['this_month']['sentChangePercentage']" :name="$t('total.feedback.sent.thisMonth')" :icon="QuestionMarkCircleIcon" />
            <StatsCard :view-all-action="route('main.feedback.results')" :stat="props.stats['feedback']['this_month']['responded']" :changeType="props.stats['feedback']['this_month']['respondedChangeType']" :change="props.stats['feedback']['this_month']['respondedChangePercentage']" :name="$t('total.feedback.responded.thisMonth')" :icon="QuestionMarkCircleIcon" />

        </dl>
    </Layout>
</template>
