<template>
    <div class="space-y-12">
        <div class="border-b border-white/10 pb-12">
            <h2 class="text-base/7 font-semibold text-white">{{ $t('plans.information')}}</h2>

            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <Input name="name" type="text" :label="$t('name')" size="30" v-model="props.form.name" :placeholder="$t('name')" :error="props.form.errors.name"/>
                <Input name="trainersLimit" type="number" :label="$t('trainers.limit')" size="30" v-model="props.form.trainersLimit" :placeholder="$t('trainers.limit')" :error="props.form.errors.trainersLimit"/>
                <Input name="locationsLimit" type="number" :label="$t('locations.limit')" size="30" v-model="props.form.locationsLimit" :placeholder="$t('locations.limit')" :error="props.form.errors.locationsLimit"/>
                <Input name="ownDomainAccess" type="checkbox" :label="$t('own.domain.access')" size="30" v-model="props.form.ownDomainAccess" :error="props.form.errors.ownDomainAccess"/>
                <Input name="feedbackModuleAccess" type="checkbox" :label="$t('feedback.module.access')" size="1" v-model="props.form.feedbackModuleAccess" :error="props.form.errors.feedbackModuleAccess"/>
                <Input name="homeworkModuleAccess" type="checkbox" :label="$t('homework.module.access')" size="1" v-model="props.form.homeworkModuleAccess" :error="props.form.errors.homeworkModuleAccess"/>
                <Select name="homeworkModuleType" :label="$t('homework.module.type')" size="30" v-model="props.form.homeworkModuleType" :options="homeworkModuleTypeOptions" :error="props.form.errors.homeworkModuleType"/>
            </div>
        </div>
    </div>
</template>

<script setup>

import Input from "../../../../Components/Form/Input.vue";
import Select from "../../../../Components/Form/Select.vue";

const props = defineProps({
    plan: {
        type: Object,
        required: true
    },
    form: {
        type: Object,
        required: true
    }
})

const homeworkModuleTypeOptions = [
    {
        value: 0,
        label: 'Text'
    },
    {
        value: 1,
        label: 'Text/Audio/Video'
    }
]

</script>
