<script setup>
import Layout from "../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../Components/Main/Breadcrumbs.vue";
import {Head} from '@inertiajs/vue3'
import {inject} from 'vue';
const route = inject('route');

const pageTree = [
    {name: 'media', href: route('main.media.page'), current: true}
]

const request = {
    baseUrl: route('main.media.api'),
}

</script>

<template>
    <Head :title="$t('media')"/>
    <Layout>
        <div class="flex flex-row align-middle justify-between">
            <Breadcrumbs :pageTree="pageTree"/>
        </div>
        <div class="h-full w-full min-h-96">
            <vue-finder id="vf" :request="request" theme="dark"></vue-finder>
        </div>
    </Layout>
</template>
