<script setup>
import Layout from "../../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../../Components/Main/Breadcrumbs.vue";
import {Link} from "@inertiajs/vue3";
import {Head} from '@inertiajs/vue3'
import {ChevronRightIcon} from "@heroicons/vue/20/solid/index.js";
import { inject } from 'vue';

const route = inject('route');

const pageTree = [
    {name: 'schedule.student', href: route('main.schedule.student'), current: true}
]

const props = defineProps({
    students: {
        type: Array,
        required: true,
    }
})

</script>

<template>
    <Head :title="$t('schedule.student')"/>
    <Layout>
        <div class="flex flex-row align-middle justify-between">
            <Breadcrumbs :pageTree="pageTree"/>
        </div>

            <div v-for="student in props.students" :key="student.id">
                <ul role="list" class="divide-y divide-gray-700">
                <li v-for="contract in student.contracts" :key="contract.id" class="relative flex justify-between gap-x-6 py-5">

                    <div class="flex min-w-0 gap-x-4">
                        <div class="min-w-0 flex-auto">
                            <Link :href="route('main.schedule.student.detail',[student,contract])" class="text-sm/6 font-semibold text-white">
                                {{ student.name }} - {{ contract.price_item.activity.name}}
                            </Link>
                        </div>
                    </div>
                    <div class="flex shrink-0 items-center gap-x-4">
                        <Link :href="route('main.schedule.student.detail',[student,contract])" class="text-sm/6 font-semibold text-white">

                            <ChevronRightIcon class="size-5 flex-none text-gray-400" aria-hidden="true"/>
                        </Link>
                    </div>
                </li>
                </ul>

            </div>
    </Layout>
</template>
