<template>
    <Head :title="$t('billing.settings.integration.direct')"/>
    <Layout>
        <div class="flex flex-row align-middle justify-between">
            <Breadcrumbs :pageTree="pageTree"/>
        </div>
        <form @submit.prevent="submit">
            <div class="flex flex-row">
                <div class="space-y-12">
                    <div class="border-b border-white/10 pb-12 mt-10">
                        <Input name="invoice_series" type="text" v-model="form.invoice_series" :label="$t('billing.settings.integration.invoice.series')"/>
                        <Input name="next_invoice_number" type="numeric" v-model="form.next_invoice_number" :label="$t('billing.settings.integration.invoice.next_number')"/>
                        <Input name="proforma_series" type="text" v-model="form.proforma_series" :label="$t('billing.settings.integration.proforma.series')"/>
                        <Input name="next_proforma_number" type="numeric" v-model="form.next_proforma_number" :label="$t('billing.settings.integration.proforma.next_number')"/>
                        <Input name="vat_rate" type="numeric" v-model="form.vat_rate" :label="$t('billing.settings.integration.vat_rate')"/>
                    </div>
                </div>
            </div>
            <div class="mt-6 flex items-center justify-end gap-x-6">
                <button type="submit"
                        class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    {{ $t('save') }}
                </button>
            </div>
        </form>
    </Layout>
</template>

<script setup>
import {Head, router, useForm} from "@inertiajs/vue3";
import Breadcrumbs from "../../../../../Components/Main/Breadcrumbs.vue";
import Layout from "../../../../../Layouts/Main/Layout.vue";
import Input from "../../../../../Components/Form/Input.vue";
import {inject} from "vue";

const route = inject('route');

const props = defineProps({
    directSettings: {
        type: Object,
        required: true
    },
    billingAccount: {
        type: Object,
        required: true
    },
})

const form = useForm({
    'invoice_series': props.directSettings.invoice_series,
    'next_invoice_number': props.directSettings.next_invoice_number,
    'proforma_series': props.directSettings.proforma_series,
    'next_proforma_number': props.directSettings.next_proforma_number,
    'vat_rate': props.directSettings.vat_rate,
})
const pageTree = [
    {name: 'billing.settings.long', href: route('main.billing.settings'), current: false},
    {name: props.billingAccount.label, href: '#', current: false},
    {
        name: 'billing.settings.integration.direct',
        href: route('main.billing.settings.integrations.direct', props.billingAccount.id),
        current: true
    }
]


function submit() {
    form.patch(route('main.billing.settings.integrations.direct.patch', props.billingAccount.id), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.billing.settings'))
        }
    })
}

</script>
<script setup>

</script>
