<template>
    <Head title="Login"/>
    <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-sm">
            <img class="mx-auto h-10 w-auto" src="/img/logo.png"
                 alt="Your Company"/>
            <h2 class="mt-10 text-center text-2xl/9 font-bold tracking-tight text-white">Reset your password</h2>
        </div>

        <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form @submit.prevent="submit" class="space-y-6" :action="route('password.reset.post')" method="POST">
                <div>
                    <label for="email" class="block text-sm/6 font-medium text-white">Email address</label>
                    <div class="mt-2">
                        <input disabled="true" type="email" name="email" id="email" v-model="form.email"
                               autocomplete="email" required=""
                               class="block w-full rounded-md bg-white/5 px-3 py-1.5 text-base text-white outline outline-1 -outline-offset-1 outline-white/10 placeholder:text-gray-500 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-500 sm:text-sm/6"/>
                    </div>
                    <div v-if="form.errors.email" class="text-red-600">{{ form.errors.email }}</div>
                </div>

                <div>
                    <div class="flex items-center justify-between">
                        <label for="password" class="block text-sm/6 font-medium text-white">Password</label>
                    </div>
                    <div class="mt-2">
                        <input type="password" name="password" id="password" v-model="form.password"
                               autocomplete="current-password" required=""
                               class="block w-full rounded-md bg-white/5 px-3 py-1.5 text-base text-white outline outline-1 -outline-offset-1 outline-white/10 placeholder:text-gray-500 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-500 sm:text-sm/6"/>
                    </div>

                </div>

                <div>
                    <div class="flex items-center justify-between">
                        <label for="password" class="block text-sm/6 font-medium text-white">Confirm Password</label>
                    </div>
                    <div class="mt-2">
                        <input type="password" name="password_confirmation" id="password_confirmation"
                               v-model="form.password_confirmation" autocomplete="confirm-password" required=""
                               class="block w-full rounded-md bg-white/5 px-3 py-1.5 text-base text-white outline outline-1 -outline-offset-1 outline-white/10 placeholder:text-gray-500 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-500 sm:text-sm/6"/>
                    </div>
                    <div v-if="form.errors.password" class="text-red-600">{{ form.errors.password }}</div>

                </div>

                <div>
                    <button type="submit"
                            class="flex w-full justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                        Reset password
                    </button>
                </div>

            </form>

        </div>
    </div>
</template>

<script setup>
import {useForm} from '@inertiajs/vue3'
import {Head} from '@inertiajs/vue3'

import {inject, ref} from 'vue';

const route = inject('route');

const props = defineProps({
    token: {
        type: String,
        required: true
    },
    email: {
        type: String,
        required: true
    }
})

let password = ref();
let password_confirmation = ref();

const form = useForm({
    email: props.email,
    token: props.token,
    password: password,
    password_confirmation: password_confirmation,
})

function submit() {
    form.post(route('password.reset.post'), {
        onError: () => {
            password.value = null
        },
        onFinish: () => {
            password.value = null
        }
    })
}
</script>
