<template>
    <Head :title="$t('plans')"/>
    <Layout>
        <div class="flex flex-row align-middle justify-between">
            <Breadcrumbs :pageTree="pageTree"/>
            <Link :href="route('superAdmin.plans.add')" class="" as="button">
                <PlusCircleIcon class="size-8 text-green-600 my-2 mb-6" aria-hidden="true" aria-label="Add plan"/>
            </Link>
        </div>
        <ul role="list" class="divide-y divide-gray-700">
            <li v-for="plan in props.plans" :key="plan.id" class="relative flex justify-between gap-x-6 py-5 border-b-1">
                <div class="flex min-w-0 gap-x-4">
                    <!--                <img class="size-12 flex-none rounded-full bg-gray-50" :src="item.imageUrl" alt="" />-->
                    <div class="min-w-0 flex-auto">
                        <p class="text-sm/6 font-semibold text-white">
                            <Link :href="route('superAdmin.plans.edit',plan.id)">
                                {{ plan.name }}
                            </Link>
                        </p>
                        <ul class="mt-1 text-xs/5 text-gray-500 flex flex-wrap items-start gap-x-1" v-if="plan.prices.length>0">
                            <li v-for="(price,idx) in plan.prices" :key="price.id">
                                <span>{{ $t('months',{value: price.duration}) }} {{  $t('priced.at') }} {{ price.price }}</span> <span v-if="idx<plan.prices.length-1">&bullet;</span>
                            </li>
                        </ul>
                        <ul class="mt-1 text-xs/5 text-gray-500 flex flex-wrap items-start gap-x-1">
                            <li v-if="plan.ownDomainAccess">&checkmark; {{ $t('own.domain.access') }} &bull;</li>
                            <li v-else>&cross; {{$t('own.domain.access')}} &bull;</li>
                            <li v-if="plan.feedbackModuleAccess">&checkmark; {{  $t('feedback.module.access') }} &bull;</li>
                            <li v-else>&cross; {{  $t('feedback.module.access') }} &bull;</li>
                            <li v-if="plan.homeworkModuleAccess">&checkmark; {{  $t('homework.module.access') }} ({{ plan.homeworkModuleType===0 ? 'Text' : 'Text/Audio/Video'}})</li>
                            <li v-else>&cross; {{  $t('homework.module.access') }}</li>
                        </ul>
                    </div>
                </div>

                <div class="flex shrink-0 items-center gap-x-4">
                    <div class="hidden sm:flex sm:flex-col sm:items-end">
                    </div>
                    <Link :href="route('superAdmin.plans.edit',plan.id)" class="size-5 flex-none text-gray-400"
                          aria-hidden="true">
                        <ChevronRightIcon class="size-5 flex-none text-gray-400" aria-hidden="true"/>
                    </Link>
                </div>
            </li>
        </ul>
    </Layout>
</template>

<script setup>
import {Head, Link} from "@inertiajs/vue3";
import Breadcrumbs from "../../../../Components/Main/Breadcrumbs.vue";
import Layout from "../../../../Layouts/Main/Layout.vue";
import {inject} from 'vue';
import {ChevronRightIcon, PlusCircleIcon} from "@heroicons/vue/24/outline/index.js";

const route = inject('route');

const pageTree = [
    {name: 'plans', href: route('superAdmin.plans.index'), current: true}
]

const props = defineProps({
    plans: {type: Array, required: true}
})
</script>
