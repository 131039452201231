<template>
    <div>
        <div v-for="question in filteredQuestions" :key="question.id" class="mt-4">
            {{ question.question }}
            <dl class="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-700 shadow md:grid-cols-10 md:divide-x md:divide-y-0">
                <div v-for="(item,index) in questionStatistics[question.id]" :key="index" class="px-2 py-2 sm:p-6">
                    <dt class="text-base font-normal text-gray-300" v-if="question.type!=='rating'">{{ item[0] }}</dt>
                    <dt class="text-base font-normal text-gray-300 flex" v-if="question.type==='rating'">
                        <div v-if="question.rating.ratingType==='stars'" class="flex">
                            <StarIcon v-for="index in parseInt(item[0])" :key="index"
                                      class="text-yellow-400 size-5 shrink-0 inline-block']" aria-hidden="true"/>
                            <StarIcon v-for="index in (parseInt(question.rating.ratingNumber) - parseInt(item[0]))"
                                      :key="index"
                                      class="text-gray-200 size-5 shrink-0 inline-block']" aria-hidden="true"/>
                        </div>
                        <div v-if="question.rating.ratingType==='hearts'" class="flex">
                            <HeartIcon v-for="index in parseInt(item[0])" :key="index"
                                       class="text-red-500 size-5 shrink-0 inline-block']" aria-hidden="true"/>
                            <HeartIcon v-for="index in (parseInt(question.rating.ratingNumber) - parseInt(item[0]))"
                                       :key="index"
                                       class="text-gray-200 size-5 shrink-0 inline-block']" aria-hidden="true"/>
                        </div>
                        <div v-if="question.rating.ratingType==='thumbsup'" class="flex">
                            <HandThumbUpIcon v-for="index in parseInt(item[0])" :key="index"
                                             class="text-blue-500 size-5 shrink-0 inline-block']" aria-hidden="true"/>
                            <HandThumbUpIcon
                                v-for="index in (parseInt(question.rating.ratingNumber) - parseInt(item[0]))"
                                :key="index"
                                class="text-gray-200 size-5 shrink-0 inline-block']" aria-hidden="true"/>
                        </div>
                    </dt>
                    <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
                        <div class="flex items-baseline text-2xl font-semibold text-indigo-500">
                            {{ item[1] }}
                            <!--                        <span class="ml-2 text-sm font-medium text-gray-400">from {{ item.previousStat }}</span>-->
                        </div>


                        <!--                    <div :class="[item.changeType === 'increase' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800', 'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0']">-->
                        <!--                        <ArrowUpIcon v-if="item.changeType === 'increase'" class="-ml-1 mr-0.5 size-5 shrink-0 self-center text-green-500" aria-hidden="true" />-->
                        <!--                        <ArrowDownIcon v-else class="-ml-1 mr-0.5 size-5 shrink-0 self-center text-red-500" aria-hidden="true" />-->
                        <!--                        <span class="sr-only"> {{ item.changeType === 'increase' ? 'Increased' : 'Decreased' }} by </span>-->
                        <!--                        {{ item.change }}-->
                        <!--                    </div>-->
                    </dd>
                </div>
            </dl>
        </div>
    </div>
</template>

<script setup>
import {computed} from "vue";
import {HandThumbUpIcon, HeartIcon, StarIcon} from "@heroicons/vue/24/solid/index.js";

const props = defineProps({
    form: {
        type: Object,
        required: true,
    },
})

const filteredQuestions = computed(() => {
    let filtered = props.form.questions.filter(question => question.type === 'rating' || question.type === 'multipleChoice' || question.type === 'dropdown');
    filtered.forEach((question) => {
        if (question.type === 'rating') {
            question.rating = JSON.parse(question.extra).rating
        }
    })
    return filtered
})

const questionStatistics = computeStats()

function computeStats() {
    let stats = [];
    filteredQuestions.value.forEach((question) => {
        let answersMap = new Map();
        props.form.answers.forEach((answer) => {
            if (question.id !== answer.feedback_question_id) {
                return;
            }
            answersMap.set(answer.answer, (answersMap.get(answer.answer) || 0) + 1);
        })
        stats[question.id] = new Map([...answersMap.entries()].sort((a, b) => b[1] - a[1]));
    })
    return stats;
}
</script>
