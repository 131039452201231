<script setup>
import Layout from "../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../Components/Main/Breadcrumbs.vue";
import {Link} from "@inertiajs/vue3";
import {PlusCircleIcon} from "@heroicons/vue/24/outline/index.js";
import {Head} from '@inertiajs/vue3'
import {CalendarDateRangeIcon,ChevronRightIcon,GlobeAltIcon} from "@heroicons/vue/24/solid/index.js";
import { inject } from 'vue';

const route = inject('route');

const pageTree = [
    {name: 'trainers', href: route('main.trainers'), current: true}
]

const props = defineProps({
    trainers: {
        type: Array,
        required: true,
    }
})
</script>

<template>
    <Head :title="$t('trainers')"/>
    <Layout>
        <div class="flex flex-row align-middle justify-between">
            <Breadcrumbs :pageTree="pageTree"/>
            <Link :href="route('main.trainers.add')" class="" as="button">
                <PlusCircleIcon class="size-8 text-green-600 my-2 mb-6" aria-hidden="true" aria-label="Add location"/>
            </Link>
        </div>
        <ul role="list" class="divide-y divide-gray-700">
            <li v-for="trainer in props.trainers" :key="trainer.id" class="relative flex justify-between gap-x-6 py-5">
                <div class="flex min-w-0 gap-x-4">
                    <!--                <img class="size-12 flex-none rounded-full bg-gray-50" :src="item.imageUrl" alt="" />-->
                    <div class="min-w-0 flex-auto">
                        <p class="text-sm/6 font-semibold text-white">
                            <Link :href="route('main.trainers.edit',trainer)">
                                {{ trainer.name }}
                            </Link>
                        </p>
                    </div>
                </div>
                <div class="flex shrink-0 items-center gap-x-4">
                    <div class="hidden sm:flex sm:flex-col sm:items-end">
                        <p v-if="trainer.locations.length>0" class="mt-1 text-xs/5 text-gray-500">
                            {{$t('locations.plural.novalue',{value: trainer.locations.map(location => location.name).length})}}: {{ trainer.locations.map(location => location.name).join(', ') }}
                        </p>
                        <p v-if="trainer.activities.length>0" class="mt-1 text-xs/5 text-gray-500">
                            {{$t('classes.plural.novalue',{value: trainer.activities.map(activity => activity.name).length})}}: {{ trainer.activities.map(activity => activity.name).join(', ') }}
                        </p>
                    </div>
                    <Link :href="route('main.trainers.schedule',trainer)"
                          class="size-5 flex-none text-gray-400"
                          aria-hidden="true">
                        <CalendarDateRangeIcon class="size-5 flex-none text-green-600" aria-hidden="true"/>
                    </Link>
                    <Link :href="route('main.trainers.vacation.index',trainer.id)"
                            class="size-5 flex-none text-gray-400"
                            aria-hidden="true">
                            <GlobeAltIcon class="size-5 flex-none text-indigo-500" aria-hidden="true"/>
                    </Link>
                    <Link :href="route('main.trainers.edit',trainer)" class="size-5 flex-none text-gray-400"
                          aria-hidden="true">
                        <ChevronRightIcon class="size-5 flex-none text-gray-400" aria-hidden="true"/>
                    </Link>
                </div>
            </li>
        </ul>
    </Layout>
</template>
