<template>
    <th scope="col"
        class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-100 sm:pl-0">
        <a href="#" class="group inline-flex" v-if="sortEnabled">
            <button @click="$emit('sort',{field: sortField, direction: 'asc'})">
                {{ $t(props.label) }}
            </button>
            <span v-if="props.sortBy!==props.sortField"
                  class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                <button @click="$emit('sort',{field: sortField, direction: 'asc'})" v-if="props.sortDirection==='desc'">
                    <ChevronDownIcon class="size-5" aria-hidden="true"/>
                </button>
                <button @click="$emit('sort',{field: sortField, direction: 'desc'})" v-if="props.sortDirection==='asc'">
                    <ChevronUpIcon class="size-5" aria-hidden="true"/>
                </button>
            </span>
            <span v-if="props.sortBy===props.sortField"
                  class="ml-2 flex-none rounded bg-gray-700 text-gray-100 group-hover:bg-gray-500">
                <button @click="$emit('sort',{field: sortField, direction: 'asc'})" v-if="props.sortDirection==='desc'">
                    <ChevronDownIcon class="size-5" aria-hidden="true"/>
                </button>
                <button @click="$emit('sort',{field: sortField, direction: 'desc'})" v-if="props.sortDirection==='asc'">
                    <ChevronUpIcon class="size-5" aria-hidden="true"/>
                </button>
            </span>
        </a>
        <span v-else>
            {{ $t(props.label) }}
        </span>
    </th>
</template>
<script setup>
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/vue/20/solid";

defineEmits('sort')
const props = defineProps({
    label: {
        type: String,
        required: true,
    },
    sortBy: {
        type: String,
        required: false,
    },
    sortField: {
        type: String,
        required: false,
    },
    sortDirection: {
        type: String,
        required: false,
    },
    sortEnabled: {
        type: Boolean,
        required: false,
        default: true,
    }
})
</script>
