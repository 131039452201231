<template>
    <Head :title="$t('coupons')"/>
    <Layout>
        <div class="flex flex-row align-middle justify-between">
            <Breadcrumbs :pageTree="pageTree"/>
            <Link :href="route('superAdmin.plans.coupons.add')" class="" as="button">
                <PlusCircleIcon class="size-8 text-green-600 my-2 mb-6" aria-hidden="true" aria-label="Add plan"/>
            </Link>
        </div>
        <ul role="list" class="divide-y divide-gray-700">
            <li v-for="coupon in props.coupons" :key="coupon.id" class="relative flex justify-between gap-x-6 py-5 border-b-1">
                <div class="flex min-w-0 gap-x-4">
                    <!--                <img class="size-12 flex-none rounded-full bg-gray-50" :src="item.imageUrl" alt="" />-->
                    <div class="min-w-0 flex-auto">
                        <p class="text-sm/6 font-semibold text-white">
                            <Link :href="route('superAdmin.plans.coupons.edit',coupon.id)">
                                {{ coupon.name }}
                            </Link>
                        </p>

                        <span class="mt-1 text-xs/5 text-gray-200" v-if="coupon.code">
                            {{ $t('code')}}: {{ coupon.code }} &bullet;
                        </span>
                        <span class="mt-1 text-xs/5 text-gray-500" v-if="coupon.discount_type=='percentage'">
                            {{  $t('discount') }}: {{ coupon.discount_value }}% &bullet;
                        </span>
                        <span class="mt-1 text-xs/5 text-gray-500" v-if="coupon.discount_type=='fixed'">
                            {{  $t('discount') }}: {{ coupon.discount_value }} &bullet;
                        </span>
                        <span class="mt-1 text-xs/5 text-gray-500">
                            {{  $t('valid.from') }}: {{ coupon.start_date }} &bullet;
                        </span>
                        <span class="mt-1 text-xs/5 text-gray-500" v-if="coupon.end_date">
                            {{  $t('valid.until') }}: {{ coupon.end_date }} &bull;
                        </span>
                        <span class="mt-1 text-xs/5 text-gray-500" v-if="!coupon.end_date">
                            {{  $t('valid.indefinetely') }} &bull;
                        </span>
                        <span class="mt-1 text-xs/5 text-gray-500" v-if="coupon.uses>0">
                            {{  $t('uses') }}: {{ coupon.uses }}
                        </span>

                    </div>
                </div>

                <div class="flex shrink-0 items-center gap-x-4">
                    <div class="hidden sm:flex sm:flex-col sm:items-end">
                    </div>
                    <Link :href="route('superAdmin.plans.coupons.edit',coupon.id)" class="size-5 flex-none text-gray-400"
                          aria-hidden="true">
                        <ChevronRightIcon class="size-5 flex-none text-gray-400" aria-hidden="true"/>
                    </Link>
                </div>
            </li>
        </ul>
    </Layout>
</template>

<script setup>
import {Head, Link} from "@inertiajs/vue3";
import Breadcrumbs from "../../../../../Components/Main/Breadcrumbs.vue";
import Layout from "../../../../../Layouts/Main/Layout.vue";
import {inject} from 'vue';
import {ChevronRightIcon, PlusCircleIcon} from "@heroicons/vue/24/outline/index.js";

const route = inject('route');

const pageTree = [
    {name: 'coupons', href: route('superAdmin.plans.coupons.show'), current: true}
]

const props = defineProps({
    coupons: {type: Array, required: true}
})
</script>
