<script setup>
import Layout from "../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../Components/Main/Breadcrumbs.vue";
import {Link} from "@inertiajs/vue3";
import {PlusCircleIcon} from "@heroicons/vue/24/outline/index.js";
import {Head} from '@inertiajs/vue3'
import {ChevronRightIcon} from "@heroicons/vue/20/solid/index.js";
import {inject} from 'vue';

const route = inject('route');

const props = defineProps({
    trainer: {
        type: Object,
        required: true,
    },
    schedules: {
        type: Array,
        required: true,
    },
    limited: {
        type: Boolean,
        default: false,
    }
})

const pageTree = [
    {name: 'trainers', href: route('main.trainers'), current: false},
    {name: 'trainer.edit', href: route('main.trainers.edit', props.trainer), current: false},
    {name: 'schedule.work', href: route('main.trainers.schedule', props.trainer), current: true},
]

if (props.limited) {
    pageTree.shift()
    pageTree.shift()

}

const prettyDays = {
    0: 'sunday',
    1: 'monday',
    2: 'tuesday',
    3: 'wednesday',
    4: 'thursday',
    5: 'friday',
    6: 'saturday',
}
</script>

<template>
    <Head :title="$t('schedule.work')"/>
    <Layout>
        <div class="flex flex-row align-middle justify-between">
            <Breadcrumbs :pageTree="pageTree"/>
            <Link :href="route('main.trainers.schedule.add',trainer)" class="" as="button">
                <PlusCircleIcon class="size-8 text-green-600 my-2 mb-6" aria-hidden="true" aria-label="Add schedule"/>
            </Link>
        </div>

        <ul role="list" class="divide-y divide-gray-700">
            <li v-for="schedule in schedules" :key="schedule.id" class="relative flex justify-between gap-x-6 py-5">
                <div class="flex min-w-0 gap-x-4">
                    <!--                <img class="size-12 flex-none rounded-full bg-gray-50" :src="item.imageUrl" alt="" />-->
                    <div class="min-w-0 flex-auto">
                        <p class="text-sm/6 font-semibold text-white">
                            <Link :href="route('main.trainers.schedule.edit',[trainer,schedule])">
                                {{ $t(prettyDays[schedule.day]) }} {{ schedule.start_time }} - {{ schedule.end_time }}
                            </Link>
                        </p>
                        <p class="mt-1 flex text-xs/5 text-gray-500">
                            {{ schedule.location.name }}
                        </p>
                    </div>
                </div>
                <div class="flex shrink-0 items-center gap-x-1">
                    <div class="hidden sm:flex sm:flex-col sm:items-end">
                        <!--                        <p v-if="location.activities" class="mt-1 text-xs/5 text-gray-500">-->
                        <!--                            {{ location.activities.length }} activities-->
                        <!--                        </p>-->
                        <!--                        <p v-if="location.trainers" class="mt-1 text-xs/5 text-gray-500">-->
                        <!--                            {{ location.trainers.length }} trainers-->
                        <!--                        </p>-->
                    </div>
                    <Link :href="route('main.trainers.schedule.edit',[trainer,schedule])"
                          aria-hidden="true">
                        <ChevronRightIcon class="size-5 flex-none text-gray-400" aria-hidden="true"/>
                    </Link>
                </div>
            </li>
        </ul>
    </Layout>
</template>
