<template>
    <div class="space-y-12">
        <div class="border-b border-white/10 pb-12">
            <p class="mt-1 text-sm/6 text-gray-400">{{ $t('information.public')}}</p>
            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <SelectModel name="day" :label="$t('day')" v-model="form.day" :error="form.errors.day" :options="days"/>
                <Input name="start_time" type="time" :label="$t('time.start')" size="30" v-model="form.start_time" :error="form.errors.start_time"/>
                <Input name="end_time" type="time" :label="$t('time.end')" size="30" v-model="form.end_time" :error="form.errors.end_time"/>
                <SelectModel name="location_id" :label="$t('location')" v-model="form.location_id" :error="form.errors.location_id" :options="props.trainer.locations"/>
            </div>
        </div>
    </div>

</template>

<script setup>
import Input from "../../../Components/Form/Input.vue";
import SelectModel from "../../../Components/Form/SelectModel.vue";

const props = defineProps({
    form: {
        type: Object,
        required: true
    },
    trainer: {
        type: Object,
        required: true
    }
})


const days = [
    {
        id: 0,
        name: 'Sunday'
    },
    {
        id: 1,
        name: 'Monday'
    },
    {
        id: 2,
        name: 'Tuesday'
    },
    {
        id: 3,
        name: 'Wednesday'
    },
    {
        id: 4,
        name: 'Thursday'
    },
    {
        id: 5,
        name: 'Friday'
    },
    {
        id: 6,
        name: 'Saturday'
    }
]
</script>
