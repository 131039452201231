<script setup>
import {ScheduleXCalendar} from '@schedule-x/vue'
import {
    createCalendar,
    createViewDay,
    createViewMonthAgenda,
    createViewMonthGrid,
    createViewWeek,
} from '@schedule-x/calendar'
import {createEventsServicePlugin} from '@schedule-x/events-service'
import '@schedule-x/theme-default/dist/index.css'
import {createCurrentTimePlugin} from '@schedule-x/current-time'
import {useTolgee} from "@tolgee/vue";
import {getLongLocaleName} from "../../../helpers.js";
import {inject, ref} from "vue";
import EventModal from "./EventModal.vue";
import axios from "axios";

const route = inject('route');

const eventsServicePlugin = createEventsServicePlugin();
const tolgee = useTolgee(['language']);

const props = defineProps({
    scheduledClasses: {
        type: Array,
        required: true,
    },
    publicHolidays: {
        type: Array,
        required: true,
    }
})

let localPublicHolidays = []

props.publicHolidays.forEach((holiday) => {
    localPublicHolidays.push({
        start: holiday.date,
        end: holiday.date,
        title: holiday.name,
        style: {
            backgroundImage: 'repeating-linear-gradient(45deg, #ccc, #ccc 5px, transparent 5px, transparent 10px)',
            opacity: 0.5,
        },
    })
})

let events = []
let calendars = []
let calendarColors = [
    {
        lightColors: {
            main: '#f9d71c',
            container: '#fff5aa',
            onContainer: '#594800',
        },
        darkColors: {
            main: '#fff5c0',
            onContainer: '#fff5de',
            container: '#a29742',
        },
    },
    {
        lightColors: {
            main: '#f91c45',
            container: '#ffd2dc',
            onContainer: '#59000d',
        },
        darkColors: {
            main: '#ffc0cc',
            onContainer: '#ffdee6',
            container: '#a24258',
        },
    },
    {
        lightColors: {
            main: '#1cf9b0',
            container: '#dafff0',
            onContainer: '#004d3d',
        },
        darkColors: {
            main: '#c0fff5',
            onContainer: '#e6fff5',
            container: '#42a297',
        },
    },
    {
        lightColors: {
            main: '#1c7df9',
            container: '#d2e7ff',
            onContainer: '#002859',
        },
        darkColors: {
            main: '#c0dfff',
            onContainer: '#dee6ff',
            container: '#426aa2',
        },
    }
];

let startTime = "14:00"
let endTime = "20:00"

let idx = 0;
props.scheduledClasses.forEach((scheduledClass) => {
    if (scheduledClass.start_date.split(' ')[1] < startTime) {
        startTime = scheduledClass.start_date.split(' ')[1].split(':')[0] + ":" + scheduledClass.start_date.split(':')[1]
    }
    if (scheduledClass.end_date.split(' ')[1] > endTime) {
        endTime = scheduledClass.end_date.split(' ')[1].split(':')[0] + ":" + scheduledClass.end_date.split(':')[1]
    }
    if (!calendars[scheduledClass.subscription.contract.student.id]) {
        calendars[scheduledClass.subscription.contract.student.id] = {
            colorName: scheduledClass.subscription.contract.student.id,
            lightColors: calendarColors[idx].lightColors,
            darkColors: calendarColors[idx].darkColors,
        }
        idx++;
        if (idx >= calendarColors.length) {
            idx = 0;
        }
    }
    let extraTitle = ''
    switch (scheduledClass.status) {
        case 'cancelled':
            extraTitle = ' (' + tolgee.value.t('cancelled') + ')'
            break;
        case 'missed':
            extraTitle = ' (' + tolgee.value.t('missed') + ')'
            break;
    }
    events.push({
        id: scheduledClass.id,
        title: scheduledClass.activity.name + extraTitle,
        people: [scheduledClass.subscription.contract.student.name, scheduledClass.trainer.name],
        start: scheduledClass.start_date.split(':')[0] + ":" + scheduledClass.start_date.split(':')[1],
        end: scheduledClass.end_date.split(':')[0] + ":" + scheduledClass.end_date.split(':')[1],
        location: scheduledClass.location ? scheduledClass.location.name : '',
        calendarId: scheduledClass.subscription.contract.student.id,
        status: scheduledClass.status,
        activity_name: scheduledClass.activity.name,
        start_date: scheduledClass.start_date,
        trainer_name: scheduledClass.trainer.name,
        subscription_id: scheduledClass.subscription.id,
    })
})

const calendarApp = createCalendar({
    locale: getLongLocaleName(tolgee.value.getLanguage()),
    defaultView: createViewMonthGrid().name,
    views: [
        createViewDay(),
        createViewWeek(),
        createViewMonthGrid(),
        createViewMonthAgenda(),
    ],
    events: events,
    backgroundEvents: localPublicHolidays,
    isDark: true,
    dayBoundaries: {
        start: startTime,
        end: endTime,
    },
    weekOptions: {
        gridHeight: 1000,

    },
    calendars: calendars,
    callbacks: {
        onEventClick(event, e) {
            showEventModal(event, e)
        },
    }
}, [eventsServicePlugin, createCurrentTimePlugin()])

let eventDetailsModalOpen = ref(false)
let eventDetailsForModal = ref({})
let eventDetailsModalError = ref('')

function showEventModal(event) {
    eventDetailsModalError.value = ''
    eventDetailsForModal.value = event
    eventDetailsModalOpen.value = true
}

function closeEventDetailsRemoveModal() {
    eventDetailsModalOpen.value = false
}

function cancelAppointment() {
    const data = {
        subscription_id: eventDetailsForModal.value.subscription_id,
        id: eventDetailsForModal.value.id,
    };
    axios.post(route('tenant.schedule.cancel.appointment'), data, {
        headers: {
            'Accept-Language': tolgee.value.getLanguage(),
        }
    })
        .then(function (response) {
            if (response.data.status === 'success') {
                eventDetailsForModal.value.title = eventDetailsForModal.value.title + ' (' + tolgee.value.t('cancelled') + ')'
                calendarApp.eventsService.update(eventDetailsForModal.value)
                eventDetailsModalOpen.value = false
            } else {
                eventDetailsModalError.value = response.data.message
            }
        })
        .catch(function (error) {
            eventDetailsModalError.value = 'Error: ' + error
        })

}

</script>

<template>
    <div>
        <ScheduleXCalendar :calendar-app="calendarApp"/>
        <EventModal :open="eventDetailsModalOpen" :event="eventDetailsForModal" @close="closeEventDetailsRemoveModal"
                    @cancelAppointment="cancelAppointment" :error="eventDetailsModalError"/>
    </div>
</template>
