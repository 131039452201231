<template>
    <Head :title="$t('coupon.add')"/>
    <Layout>
        <Breadcrumbs :pageTree="pageTree"/>
        <form @submit.prevent="submit">
            <CouponForm :form="form"/>

            <div class="mt-6 flex items-center justify-end gap-x-6">
                <Link :href="route('superAdmin.plans.index')" as="button" class="text-sm/6 font-semibold text-white">{{$t('cancel') }}
                </Link>
                <button type="submit"
                        class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    {{$t('save')}}
                </button>
            </div>
        </form>
    </Layout>
</template>

<script setup lang="ts">
import {Head, Link, router,useForm} from "@inertiajs/vue3";
import Breadcrumbs from "../../../../../Components/Main/Breadcrumbs.vue";
import Layout from "../../../../../Layouts/Main/Layout.vue";
import { inject } from 'vue';
import CouponForm from "./CouponForm.vue";

const route = inject('route');

const pageTree = [
    {name: 'coupons', href: route('superAdmin.plans.coupons.show'), current: false},
    {name: 'coupon.add', href: route('superAdmin.plans.coupons.add'), current: true}
]

function submit() {
    form.post(route('superAdmin.plans.coupons.store'), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('superAdmin.plans.coupons.show'))
        }
    })
}

const form = useForm({
    name: '',
    code: '',
    start_date: '',
    end_date: '',
    discount_type: 'percentage',
    discount_value: 0,
    uses: 0,
    recurrent: false,
})
</script>
