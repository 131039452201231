<script setup>
import Layout from "../../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../../Components/Main/Breadcrumbs.vue";
import {Link, router, useForm} from "@inertiajs/vue3";
import { Head } from '@inertiajs/vue3'
import {ref} from "vue";
import Alert from "../../../../Components/Alert.vue";
import BundleForm from "./BundleForm.vue";
import { inject } from 'vue';

const route = inject('route');

const props = defineProps({
    price: {
        type: Object,
        required: true,
    },
    bundle: {
        type: Object,
        required: true,
    },
    currency: {
        type: String,
        required: true,
    }
})

const pageTree = [
    {name: 'pricing', href: route('main.prices'), current: false},
    {name: 'pricing.edit', href: route('main.prices.edit',props.price.id), current: false},
    {name: 'bundle.edit', href: route('main.prices.bundle.edit',{price: props.price.id, bundle: props.bundle.id}), current: true}
]

const form = useForm({
    quantity: props.bundle.quantity,
    price: props.bundle.price,
    start_date: props.bundle.start_date,
    end_date: props.bundle.end_date,
    expiry_days: props.bundle.expiry_days,
    cancelNumber: props.bundle.cancelNumber,
    cancelBeforeInterval: props.bundle.cancelBeforeInterval,
})

function submit() {
    form.patch(route('main.prices.bundle.patch',{price: props.price.id, bundle: props.bundle.id}), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.prices.edit',props.price.id))
        }
    })
}

let deleteModalOpen = ref(false)

function openRemoveModal() {
    deleteModalOpen.value = true
}

function closeRemoveModal() {
    deleteModalOpen.value = false
}

function remove() {
    closeRemoveModal()
    router.delete(route('main.prices.bundle.delete',{price: props.price.id, bundle: props.bundle.id}), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.prices.edit',props.price.id))
        }
    })
}
</script>

<template>
    <Head :title="$t('bundle.edit')" />
    <Layout>
        <Breadcrumbs :pageTree=pageTree />
        <form @submit.prevent="submit">
            <BundleForm :form="form" :currency="currency"/>
            <div class="mt-6 flex items-center justify-end gap-x-6">
                <button type="button"
                        class="rounded-md bg-red-800 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-red-700 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
                        @click="openRemoveModal">{{ $t('delete') }}</button>
                <Link :href="route('main.prices.edit',props.price.id)" as="button" class="text-sm/6 font-semibold text-white">{{$t('cancel') }}
                </Link>
                <button type="submit"
                        class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    {{$t('save')}}
                </button>
            </div>
        </form>


        <Alert :open="deleteModalOpen" :title="$t('delete')+' '+$t('bundle').toLowerCase()" :message="$t('confirm.delete')+' '+$t('bundle').toLowerCase()+'? '+$t('data.permanently.removed')" :confirmLabel="$t('delete')" :cancelLabel="$t('cancel')"
               @modal-close="closeRemoveModal" @modal-confirmed="remove"/>
    </Layout>

</template>
