<template>
    <header class="bg-gray-900">
        <nav class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
            <div class="flex items-center gap-x-12">
                <Link href="#" class="-m-1.5 p-1.5">
                    <span class="sr-only">{{  $page.props.tenant.name }}</span>
                    <img class="mx-auto h-10 w-auto" :src="$page.props.tenant.icon" :alt="$page.props.tenant.name" />
                </Link>
                <div class="hidden lg:flex lg:gap-x-12">

                </div>
            </div>
            <div class="flex lg:hidden">
                <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-300" @click="mobileMenuOpen = true">
                    <span class="sr-only">Open main menu</span>
                    <Bars3Icon class="size-6" aria-hidden="true" />
                </button>
            </div>
            <div class="hidden lg:flex" v-if="!$page.props.user">
                <Link :href="route('tenant.login')" class="text-sm/6 font-semibold text-gray-100">Log in <span aria-hidden="true">&rarr;</span></Link>
            </div>
            <div class="hidden lg:flex" v-if="$page.props.user">
                <Link :href="route('tenant.logout')" class="text-sm/6 font-semibold text-gray-100">Log out <span aria-hidden="true">&rarr;</span></Link>
            </div>
        </nav>
        <Dialog class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
            <div class="fixed inset-0 z-10" />
            <DialogPanel class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-gray-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                <div class="flex items-center justify-between">
                    <Link href="#" class="-m-1.5 p-1.5">
                        <span class="sr-only">Your Company</span>
                        <img class="h-8 w-auto" src="https://tailwindui.com/plus/img/logos/mark.svg?color=indigo&shade=600" alt="" />
                    </Link>
                    <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-300" @click="mobileMenuOpen = false">
                        <span class="sr-only">Close menu</span>
                        <XMarkIcon class="size-6" aria-hidden="true" />
                    </button>
                </div>

            </DialogPanel>
        </Dialog>
    </header>
</template>

<script setup>
import { ref } from 'vue'
import { Dialog, DialogPanel } from '@headlessui/vue'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import {Link} from "@inertiajs/vue3";


const mobileMenuOpen = ref(false)
</script>
