<script setup>
import Layout from "../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../Components/Main/Breadcrumbs.vue";
import {Link, router, useForm} from "@inertiajs/vue3";
import { Head } from '@inertiajs/vue3'
import {ref} from "vue";
import Alert from "../../../Components/Alert.vue";
import ScheduleForm from "./ScheduleForm.vue";
import { inject } from 'vue';

const route = inject('route');

const props = defineProps({
    trainer: {
        type: Object,
        required: true,
    },
    schedule: {
        type: Object,
        required: true,
    }
})

const pageTree = [
    {name: 'trainers', href: route('main.trainers'), current: false},
    {name: 'trainer.edit', href: route('main.trainers.edit',props.trainer), current: false},
    {name: 'schedule.work', href: route('main.trainers.schedule',props.trainer), current: false},
    {name: 'edit', href: route('main.trainers.schedule.edit',[props.trainer,props.schedule]), current: true}
]

const form = useForm({
    day: props.schedule.day,
    start_time: props.schedule.start_time,
    end_time: props.schedule.end_time,
    location_id: props.schedule.location_id,
})

function submit() {
    form.patch(route('main.trainers.schedule.patch',[props.trainer,props.schedule]), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.trainers.schedule',props.trainer))
        }
    })
}

let deleteModalOpen = ref(false)

function openRemoveModal() {
    deleteModalOpen.value = true
}

function closeRemoveModal() {
    deleteModalOpen.value = false
}

function remove() {
    closeRemoveModal()
    router.delete(route('main.trainers.schedule.delete',[props.trainer,props.schedule]), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.trainers.schedule',props.trainer))
        }
    })
}

</script>

<template>
    <Head :title="$t('edit')" />
    <Layout>
        <Breadcrumbs :pageTree=pageTree />
        <form @submit.prevent="submit">
            <ScheduleForm :form="form" :trainer="trainer"/>

            <div class="mt-6 flex items-center justify-end gap-x-6">
                <button type="button"
                        class="rounded-md bg-red-800 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-red-700 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
                        @click="openRemoveModal">Delete</button>
                <Link :href="route('main.trainers.schedule',props.trainer)" as="button" class="text-sm/6 font-semibold text-white">{{ $t('cancel') }}
                </Link>
                <button type="submit"
                        class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    {{ $t('save') }}
                </button>
            </div>
        </form>


        <Alert :open="deleteModalOpen" :title="$t('delete')+' '+$t('schedule.item')" :message="$t('confirm.delete')+' '+$t('schedule.item')+'? '+$t('data.permanently.removed')" :confirmLabel="$t('delete')" :cancelLabel="$t('cancel')"
               @modal-close="closeRemoveModal" @modal-confirmed="remove"/>
    </Layout>
</template>
