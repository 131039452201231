<script setup>
import Layout from "../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../Components/Main/Breadcrumbs.vue";
import {Link, router, useForm} from "@inertiajs/vue3";
import {Head} from '@inertiajs/vue3'
import {ref} from "vue";
import TrainerForm from "./TrainerForm.vue";
import { inject } from 'vue';

const route = inject('route');

const pageTree = [
    {name: 'trainers', href: route('main.trainers'), current: false},
    {name: 'trainer.add', href: route('main.trainers.add'), current: true},
]

const form = useForm({
    name: '',
    description: '',
    phone: '',
    email: '',
    website: '',
    facebook: '',
    instagram: '',
    twitter: '',
    youtube: '',
    linkedin: '',
    pinterest: '',
    tiktok: '',
    snapchat: '',
    locations: [],
    activities: [],
    photo: '',
})

function submit() {
    form.post(route('main.trainers.store'), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.trainers'))
        }
    })
}

let imageUrl = ref('')
</script>

<template>
    <Head :title="$t('trainer.add')"/>
    <Layout>
        <Breadcrumbs :pageTree="pageTree"/>
        <form @submit.prevent="submit">
            <TrainerForm v-model:form="form" v-model:imageUrl="imageUrl"/>
            <div class="mt-6 flex items-center justify-end gap-x-6">
                <Link :href="route('main.trainers')" as="button" class="text-sm/6 font-semibold text-white">
                    {{ $t('cancel')}}
                </Link>
                <button type="submit"
                        class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    {{ $t('save')}}
                </button>
            </div>
        </form>
    </Layout>
</template>
