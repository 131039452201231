<script setup>
import {ScheduleXCalendar} from '@schedule-x/vue'
import {
    createCalendar,
    createViewDay,
    createViewMonthAgenda,
    createViewMonthGrid,
    createViewWeek,
} from '@schedule-x/calendar'
import {createEventsServicePlugin} from '@schedule-x/events-service'
import '@schedule-x/theme-default/dist/index.css'
import { createCurrentTimePlugin } from '@schedule-x/current-time'
import { createEventModalPlugin } from '@schedule-x/event-modal'
import {useTolgee} from "@tolgee/vue";
import {getLongLocaleName} from "../../../../helpers.js";

const eventsServicePlugin = createEventsServicePlugin();
const eventModal = createEventModalPlugin()
const tolgee = useTolgee(['language']);

const props = defineProps({
    scheduledClasses: {
        type: Array,
        required: true,
    },
    publicHolidays: {
        type: Array,
        required: true,
    }
})

let localPublicHolidays = []

props.publicHolidays.forEach((holiday) => {
    localPublicHolidays.push({
        start: holiday.date,
        end: holiday.date,
        title: holiday.name,
        style: {
            backgroundImage: 'repeating-linear-gradient(45deg, #ccc, #ccc 5px, transparent 5px, transparent 10px)',
            opacity: 0.5,
        },
    })
})

let events = []

let startTime = "14:00"
let endTime = "20:00"

props.scheduledClasses.forEach((scheduledClass) => {
    if (scheduledClass.start_date.split(' ')[1] < startTime) {
        startTime =scheduledClass.start_date.split(' ')[1].split(':')[0] + ":" + scheduledClass.start_date.split(':')[1]
    }
    if (scheduledClass.end_date.split(' ')[1] > endTime) {
        endTime =scheduledClass.end_date.split(' ')[1].split(':')[0] + ":" + scheduledClass.end_date.split(':')[1]
    }
    events.push({
        id: scheduledClass.id,
        title: scheduledClass.activity.name,
        people: [scheduledClass.student.name],
        start: scheduledClass.start_date.split(':')[0] + ":" + scheduledClass.start_date.split(':')[1],
        end: scheduledClass.end_date.split(':')[0] + ":" + scheduledClass.end_date.split(':')[1],
        location: scheduledClass.location ? scheduledClass.location.name : '',
    })
})

const calendarApp = createCalendar({
    locale: getLongLocaleName(tolgee.value.getLanguage()),
    views: [
        createViewDay(),
        createViewWeek(),
        createViewMonthGrid(),
        createViewMonthAgenda(),
    ],
    events: events,
    backgroundEvents: localPublicHolidays,
    isDark: true,
    dayBoundaries: {
        start: startTime,
        end: endTime,
    },
    weekOptions: {
        gridHeight: 1000,

    }
},[eventsServicePlugin,createCurrentTimePlugin(),eventModal])

</script>

<template>
    <div>
        <ScheduleXCalendar :calendar-app="calendarApp"/>
    </div>
</template>
