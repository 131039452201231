<template>
    <header class="bg-gray-900">
        <nav class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
            <div class="flex items-center gap-x-12">
                <Link href="#" class="-m-1.5 p-1.5">
                    <span class="sr-only">EasyManage</span>
                    <img class="h-8 w-auto" src="/img/logo.png" alt=""/>
                </Link>
                <div class="hidden lg:flex lg:gap-x-12">

                </div>
            </div>
            <div class="flex lg:hidden">
                <button type="button"
                        class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-300"
                        @click="mobileMenuOpen = true">
                    <span class="sr-only">Open main menu</span>
                    <Bars3Icon class="size-6" aria-hidden="true"/>
                </button>
            </div>
            <div class="hidden lg:flex items-center">
                <div class="hidden lg:flex" v-if="!$page.props.user">
                    <Link :href="route('main.login')" class="text-sm/6 font-semibold text-gray-100">{{ $t('login') }}
                    </Link>
                </div>
                <div class="hidden lg:flex" v-if="$page.props.user">
                    <Link :href="route('main.dashboard')" class="text-sm/6 font-semibold text-gray-100">
                        {{ $t('dashboard') }}
                    </Link>
                    <span aria-hidden="true" class="mx-2"> &bull;</span>
                    <Link :href="route('main.logout')" class="text-sm/6 font-semibold text-gray-100">{{
                            $t('logout')
                        }}
                    </Link>
                </div>
                <LanguagePicker/>
            </div>

        </nav>
        <Dialog class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
            <div class="fixed inset-0 z-10"/>
            <DialogPanel
                class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-gray-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                <div class="flex items-center justify-between">
                    <Link href="/" class="-m-1.5 p-1.5">
                        <span class="sr-only">EasyManage</span>
                        <img class="h-8 w-auto" src="/img/logo.png" alt=""/>
                    </Link>
                    <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-300" @click="mobileMenuOpen = false">
                        <span class="sr-only">Close menu</span>
                        <XMarkIcon class="size-6" aria-hidden="true"/>
                    </button>
                </div>
                <div class="flex h-full flex-col gap-y-2 justify-items-end">
                    <nav class="space-y-2 py-6 flex h-full flex-col justify-items-end items-end">
                        <Link v-if="!$page.props.user" :href="route('main.login')"
                              class="-mx-3 block rounded-lg py-1.5 text-gray-100 hover:bg-gray-800">{{ $t('login') }}
                        </Link>
                        <Link v-if="$page.props.user" :href="route('main.dashboard')"
                              class="-mx-3 block rounded-lg py-1.5 text-gray-100 hover:bg-gray-800">{{
                                $t('dashboard')
                            }}
                        </Link>
                        <Link v-if="$page.props.user" :href="route('main.logout')"
                              class="-mx-3 block rounded-lg py-1.5 text-gray-100 hover:bg-gray-800">{{ $t('logout') }}
                        </Link>
                        <LanguagePicker/>
                    </nav>
                </div>
            </DialogPanel>
        </Dialog>
    </header>
</template>

<script setup>
import {ref} from 'vue'
import {Dialog, DialogPanel} from '@headlessui/vue'
import {Bars3Icon, XMarkIcon} from '@heroicons/vue/24/outline'
import {Link} from "@inertiajs/vue3";
import LanguagePicker from "./LanguagePicker.vue";


const mobileMenuOpen = ref(false)
</script>
