<template>
    <nav class="flex mb-4" aria-label="Breadcrumb">
        <ol role="list" class="flex space-x-4 px-2 sm:px-4 lg:px-6 border border-gray-700 bg-gray-750 shadow-xl rounded-lg">
            <li class="flex">
                <div class="flex items-center">
                    <Link :href="route('tenant.dashboard')" class="text-gray-100 hover:text-white">
                        <HomeIcon class="size-5 shrink-0" aria-hidden="true" />
                        <span class="sr-only">Home</span>
                    </Link>
                </div>
            </li>
            <li v-for="page in props.pageTree" :key="page.name" class="flex">
                <div class="flex items-center">
                    <svg class="h-full w-6 shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" aria-hidden="true">
                        <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                    </svg>
                    <Link :href="page.href" class="ml-4 text-sm font-medium text-gray-200 hover:text-white" :aria-current="page.current ? 'page' : undefined">{{$t(page.name) }}</Link>
                </div>
            </li>
        </ol>
    </nav>
</template>

<script setup>
import { HomeIcon } from '@heroicons/vue/20/solid'
import {Link} from "@inertiajs/vue3";

const props = defineProps({
    pageTree: {
        type: Array,
        required: true
    }
})
</script>
