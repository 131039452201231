<template>
    <Menu as="div" class="relative text-left h-full -mr-4">
        <div class="h-full">
            <MenuButton
                class="inline-flex justify-center gap-x-1.5 px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset  hover:bg-slate-600">
                <img :src="'/img/flags/' + selectedLanguage + '.png'" alt="" class="size-6 shrink-0 rounded-full"/>
                <!--                <ChevronDownIcon class="-mr-1 size-5 text-gray-400" aria-hidden="true" />-->
            </MenuButton>
        </div>

        <transition enter-active-class="transition ease-out duration-100"
                    enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <MenuItems
                class="absolute left-0 z-10 mt-2 divide-y divide-gray-100 rounded-md bg-gray-900 shadow-lg ring-1  ring-black/5 focus:outline-none lg:-translate-x-16 -translate-x-18">
                <div class="py-1 w-27">
                    <MenuItem v-slot="{ active }" v-for="language in languages" :key="language.value" class="w-full">
                        <button href="#"
                                :class="[active ? 'bg-gray-500 text-gray-900 outline-none' : 'text-gray-100', 'group flex items-center px-3 py-2 text-sm '] "
                                @click="changeLanguage(language.value)">
                            <img :src="'/img/flags/' + language.value + '.png'" alt=""
                                 class="size-5 shrink-0 mr-2 rounded-xl"/>
                            {{ language.name }}
                        </button>
                    </MenuItem>
                </div>
            </MenuItems>
        </transition>
    </Menu>
</template>

<script setup>
import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/vue'
import {useTolgee} from '@tolgee/vue';
import {useLocalStorage} from '@vueuse/core'
import {ref} from "vue";
import {usePage} from "@inertiajs/vue3";
import {inject} from 'vue';

const route = inject('route');

const selectedLanguage = useLocalStorage('language', 'en', {
    mergeDefaults: true
})
const page = usePage()

let languages = ref([
    {name: 'English', value: 'en'},
    {name: 'Română', value: 'ro'},
])

if (page.props.version.includes('develop')) {
    languages.value.push({name: 'None', value: 'gr'})
}

const tolgee = useTolgee(['language']);

const changeLanguage = (e) => {
    tolgee.value.changeLanguage(e);
    selectedLanguage.value = e;
    updateLannguage(e);
};

const updateLannguage = (e) => {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({locale: e}),
    };
    fetch(route('tenant.profile.language.change'), requestOptions)
        .then(response => response.json())
        .then(data => {
            if (data.status === 'success') {
                window.location.reload();
            }
        });
}


</script>
