<template>
    <div class="space-y-12">
        <div class="border-b border-white/10 pb-12">
            <h2 class="text-base/7 font-semibold text-white">{{ $t('coupon.information')}}</h2>

            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <Input name="name" type="text" :label="$t('name')" size="30" v-model="props.form.name" :placeholder="$t('name')" :error="props.form.errors.name"/>
                <Input name="code" type="text" :label="$t('code')" size="30" v-model="props.form.code" :placeholder="$t('code')" :error="props.form.errors.code"/>
                <Input name="startDate" type="date" :label="$t('valid.from')" size="30" v-model="props.form.start_date" :error="props.form.errors.start_date"/>
                <Input name="endDate" type="date" :label="$t('valid.until')" size="30" v-model="props.form.end_date" :error="props.form.errors.end_date"/>
                <Input name="recurrent" type="checkbox" :label="$t('recurrent')" size="30" v-model="props.form.recurrent" :error="props.form.errors.recurrent"/>
                <Select name="discountType" :label="$t('discount.type')" size="30" v-model="props.form.discount_type" :options="couponDiscountTypeOptions" :error="props.form.errors.discount_type"/>
                <Input name="discountValue" type="number" :label="$t('discount')" size="30" v-model="props.form.discount_value" :error="props.form.errors.discount_value"/>
                <Input name="uses" type="number" :label="$t('uses')" size="30" v-model="props.form.uses" :error="props.form.errors.uses"/>
            </div>
        </div>
    </div>
</template>

<script setup>

import Input from "../../../../../Components/Form/Input.vue";
import Select from "../../../../../Components/Form/Select.vue";

const props = defineProps({
    form: {
        type: Object,
        required: true
    }
})

const couponDiscountTypeOptions = [
    {
        value: 'percentage',
        label: 'Percentage'
    },
    {
        value: 'fixed',
        label: 'Fixed'
    }
]

</script>
