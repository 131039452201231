<template>
    <div class="">
        <div class="mt-8 flow-root">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table class="min-w-full divide-y divide-gray-300">
                        <thead>
                        <tr>
                            <TableHeaderItem :sort-direction="props.sortDirection" :sort-by="sortBy" sort-field="type"
                                             :label="$t('invoice.type')" @sort="updateSort"/>
                            <TableHeaderItem :sort-direction="props.sortDirection" :sortBy="sortBy" sort-field="number"
                                             :label="$t('number')" @sort="updateSort"/>
                            <TableHeaderItem :label="$t('customer')" :sort-enabled="false"/>
                            <TableHeaderItem :sort-direction="props.sortDirection" :sortBy="sortBy"
                                             sort-field="due_date" :label="$t('invoice.due_date')" @sort="updateSort"/>
                            <TableHeaderItem :sort-direction="props.sortDirection" :sortBy="sortBy"
                                             sort-field="issue_date" :label="$t('invoice.date')" @sort="updateSort"/>
                            <TableHeaderItem :sort-direction="props.sortDirection" :sortBy="sortBy" sort-field="total"
                                             :label="$t('invoice.amount')" @sort="updateSort"/>
                            <TableHeaderItem :sort-direction="props.sortDirection" :sortBy="sortBy" sort-field="status"
                                             :label="$t('status')" @sort="updateSort"/>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-100">
                                <span></span>
                            </th>
                        </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200 bg-gray-800">
                        <tr v-for="invoice in props.invoices" :key="invoice.id" class="even:bg-gray-700">
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-100 sm:pl-0">
                                {{ $t('invoice.type.' + invoice.type) }}
                            </td>
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-100 sm:pl-0">
                                {{ invoice.series }}-{{ invoice.number }}
                                <p class="text-xs text-gray-500">
                                    {{ invoice.billing_account.label}}
                                </p>
                            </td>
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-100 sm:pl-0">
                                {{ invoice.billing_detail.name }}
                                <ul class="text-xs text-gray-400">
                                    <li v-for="item in invoice.items" :key="item.id">
                                        {{ item.name }}<span
                                        v-if="item.description!==''">, {{ item.description }}</span>
                                    </li>
                                </ul>
                            </td>
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-100 sm:pl-0">
                                {{ invoice.issue_date }}
                            </td>
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-100 sm:pl-0">
                                {{ invoice.due_date }}
                            </td>
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-100 sm:pl-0">
                                {{ invoice.total }}
                            </td>
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-100 sm:pl-0">
                                {{ $t('invoice.status.' + invoice.status) }}
                            </td>
                            <td class="whitespace-nowrap py-4 text-sm font-medium text-gray-100 sm:pl-0">
                                <DocumentCurrencyDollarIcon class="size-6 text-gray-100" aria-hidden="true"
                                                             @click="openAddPaymentModal(invoice)" v-if="invoice.status!=='closed' && invoice.status!=='paid'"/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <AddPaymentModal :open="addPaymentModalOpen" :invoice="addPaymentInvoice" :addPaymentForm="addPaymentForm"
                         @modal-close="closeAddPaymentModal"
                         @modal-confirmed="addPayment"/>
    </div>
</template>

<script setup>
import TableHeaderItem from "./TableHeaderItem.vue";
import {router, useForm} from '@inertiajs/vue3'
import {ref} from "vue";
import AddPaymentModal from "./AddPaymentModal.vue";
import {DocumentCurrencyDollarIcon} from "@heroicons/vue/24/outline/index.js";
import { inject } from 'vue';

const route = inject('route');
const props = defineProps({
    invoices: {
        type: Array,
        required: true,
    },
    sortBy: {
        type: String,
        required: false,
        default: 'issue_date',
    },
    sortDirection: {
        type: String,
        required: false,
        default: 'desc',
    },
})

function updateSort({field, direction}) {
    router.reload({
        data: {
            sortBy: field,
            sortDirection: direction,
        }
    })
}

const addPaymentForm = useForm({
    amount: 0,
    method: '',
    reference: '',
})

let addPaymentInvoice = ref(null)
let addPaymentModalOpen = ref(false)

function openAddPaymentModal(invoice) {
    addPaymentInvoice.value=invoice
    addPaymentForm.amount=invoice.total
    addPaymentModalOpen.value = true
}

function closeAddPaymentModal() {
    addPaymentInvoice.value = null
    addPaymentForm.amount=0
    addPaymentForm.method=''
    addPaymentForm.reference=''
    addPaymentModalOpen.value = false
}

function addPayment() {
    addPaymentForm.post(route('main.billing.invoices.addPayment', addPaymentInvoice.value.id), {
        preserveScroll: true,
        onSuccess: () => {
            router.reload()
        }
    })
    closeAddPaymentModal()
}
</script>
