<script setup>
import Layout from "../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../Components/Main/Breadcrumbs.vue";
import {Link, router, useForm} from "@inertiajs/vue3";
import { Head } from '@inertiajs/vue3'
import StudentForm from "./StudentForm.vue";
import { inject } from 'vue';
import StudentBillingDetailsForm from "./StudentBillingDetailsForm.vue";

const route = inject('route');

const pageTree = [
    {name: 'students', href: route('main.students'), current: false},
    {name: 'student.add', href: route('main.students.add'), current: true},
]

const form = useForm({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    dob: '',
    address: '',
    city: '',
    state: '',
    country: '',
    zip: '',
    parent_account_email: '',
    billing_account_id: null,
    billing_name: '',
    billing_fiscal_code: '',
    billing_registration_number: '',
    billing_address: '',
    billing_city: '',
    billing_state: '',
    billing_zip: '',
    billing_country: '',
    billing_phone: '',
    billing_email: '',
    billing_bank_name: '',
    billing_bank_account: '',
    billing_contact_person: '',
})

function submit() {
    form.post(route('main.students.store'), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.students'))
        }
    })
}

const countries = [
    {value: 'ro', label: 'Romania'},
]

const props = defineProps({
    billingAccounts: {
        type: Array,
        required: true,
    }
})
</script>

<template>
    <Head :title="$t('student.add')" />
    <Layout>
        <Breadcrumbs :pageTree=pageTree />
        <form @submit.prevent="submit">
            <StudentForm :countries="countries" :form="form" />
            <StudentBillingDetailsForm :countries="countries" :form="form" :billingAccounts="props.billingAccounts"/>

            <div class="mt-6 flex items-center justify-end gap-x-6">
                <Link :href="route('main.students')" as="button" class="text-sm/6 font-semibold text-white">{{$t('cancel')}}
                </Link>
                <button type="submit"
                        class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    {{ $t('save') }}
                </button>
            </div>
        </form>
    </Layout>
</template>
