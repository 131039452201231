<template>
    <div class="border-b border-white/10 " v-if="props.questions">
        <h2 class="text-base/7 font-semibold text-white">{{$t('questions')}}</h2>

        <ul role="list" class="divide-y divide-gray-700" v-draggable="[
            questions,{
            animation: 150,
            onUpdate,
            onStart
            }
        ]">
            <li v-for="item in questions" :key="item.id" class="relative flex justify-between gap-x-6 py-5">
                <div class="flex ">
                <ChevronUpDownIcon class="h-8 w-8 handle"/>
                <Link :href="route('main.feedback.forms.question.edit',{form: props.form.id, question: item.id})" as="button">
                    <div class="flex min-w-0 gap-x-1">
                        {{ item.question }} &bull; {{ $t('question.' + item.type) }}
<!--                        <span>{{ $t('classes.plural',{value: item.quantity}) }} &bullet; </span>-->
<!--                        <span>{{  $t('priced.at') }} {{ item.price }} {{ currency }} &bullet;</span>-->
<!--                        <span> {{ $t('valid.from') }}: {{ price.start_date }} &bullet;</span>-->
<!--                        <span v-if="item.end_date">{{  $t('valid.until') }}: {{ price.end_date }} &bullet;</span>-->
<!--                        <span>{{  $t('validity') }}: {{ item.expiry_days }} {{  $t('days') }}</span>-->
                    </div>
                </Link>
                </div>
                <div class="flex shrink-0 items-center gap-x-4">
                    <div class="hidden sm:flex sm:flex-col sm:items-end">
                    </div>
                    <Link :href="route('main.feedback.forms.question.edit',{form: props.form.id, question: item.id})" class="size-5 flex-none text-gray-400"
                          aria-hidden="true">
                        <ChevronRightIcon class="size-5 flex-none text-gray-400" aria-hidden="true"/>
                    </Link>
                </div>
            </li>
        </ul>
    </div>
</template>

<script setup>
import {ChevronRightIcon} from "@heroicons/vue/20/solid/index.js";
import {Link} from "@inertiajs/vue3";
import { vDraggable } from 'vue-draggable-plus'
import {ChevronUpDownIcon} from "@heroicons/vue/24/solid/index.js";
import {inject, ref} from "vue";
import axios from "axios";
const route = inject('route');

const props = defineProps({
    form: {
        type: Object,
        required: true
    },
    questions: {
        type: Array,
        required: true
    },
})

let questions = ref(props.questions)

function onUpdate(e) {
    const questionId = e.data.id
    const orderArrayOfQuestions = questions.value.map((num) => {
        return num.id
    })
    const requestObject = {
        order: orderArrayOfQuestions,
    }
    axios.post(route('main.feedback.forms.question.update.order',{form: props.form.id,question: questionId}), requestObject);
}
function onStart() {

}
</script>
