<template>
    <div class="space-y-12" v-if="props.visible">
        <div class="border-b border-white/10 pb-12 mt-10">
            <form @submit.prevent="submit">
                <Input name="label" type="text" :label="$t('label')" size="30" v-model="form.label"
                       :placeholder="$t('label')"/>
                <Select name="type" :label="$t('billing.account.type')" v-model="form.type"
                        :options="billingAccountTypes" @change="updateVisibility"/>
                <Input name="username" type="text" :label="$t('username')" size="30"
                       v-model="form.username" :placeholder="$t('username')" v-if="usernameVisible"/>
                <Input name="password" type="password" :label="$t('password')" size="30"
                       v-model="form.password" :placeholder="$t('password')" v-if="passwordVisible"/>
                <Input name="token" type="text" :label="$t('token')" size="30" v-model="form.token"
                       :placeholder="$t('token')" v-if="tokenVisible"/>
                <Input name="cif" type="text" :label="$t('smartbill.cif')" size="30" v-model="form.cif"
                       :placeholder="$t('cif')" v-if="cifVisible"/>
                <div class="mt-6 flex items-center justify-between gap-x-6">
                    <Link :href="route('main.billing.settings.integrations.' + billingAccount.type,billingAccount.id)" class="text-sm text-gray-500 hover:text-gray-600">
                        <button
                            class="rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-green-600 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500">
                            {{ $t('billing.integration.setup') }}
                        </button>
                    </Link>

                    <button type="submit"
                            class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                        {{ $t('save') }}
                    </button>
                </div>
            </form>

        </div>
    </div>
</template>

<script setup>
import {Link} from "@inertiajs/vue3";
import Input from "../../../../Components/Form/Input.vue";
import Select from "../../../../Components/Form/Select.vue";
import {inject, ref} from "vue";
import {router, useForm} from "@inertiajs/vue3";

const route = inject('route');

const props = defineProps({
    billingAccount: {
        type: Object,
        required: true,
    },
    visible: {
        type: Boolean,
        required: true,
        default: false,
    }
})

const form = useForm({
    label: props.billingAccount.label,
    type: props.billingAccount.type,
    username: props.billingAccount.username,
    password: props.billingAccount.password,
    token: props.billingAccount.token,
    cif: props.billingAccount.cif,
})

const usernameVisible = ref(false);
const passwordVisible = ref(false);
const tokenVisible = ref(false);
const cifVisible = ref(false);

const billingAccountTypes = [
    {value: 'direct', label: 'billing.account.type.direct'},
    {value: 'oblio', label: 'billing.account.type.oblio'},
    {value: 'smartbill', label: 'billing.account.type.smartbill'},
]

function updateVisibility(integration) {
    switch (integration) {
        case 'direct':
            usernameVisible.value = false;
            passwordVisible.value = false;
            tokenVisible.value = false;
            cifVisible.value = false;
            break;
        case 'oblio':
            usernameVisible.value = true;
            passwordVisible.value = false;
            tokenVisible.value = true;
            cifVisible.value = false;
            break;
        case 'smartbill':
            usernameVisible.value = true;
            passwordVisible.value = false;
            tokenVisible.value = true;
            cifVisible.value = true;
            break;
        default:
            usernameVisible.value = false;
            passwordVisible.value = false;
            tokenVisible.value = false;
            cifVisible.value = false;
    }
}

function submit() {
    form.patch(route('main.billing.settings.accounts.patch', props.billingAccount.id), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.billing.settings'))
        }
    })
}

updateVisibility(props.billingAccount.type)
</script>
