<script setup>
import Layout from "../../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../../Components/Main/Breadcrumbs.vue";
import {Link} from "@inertiajs/vue3";
import {PlusCircleIcon} from "@heroicons/vue/24/outline/index.js";
import {Head} from '@inertiajs/vue3'
import {ChevronRightIcon} from "@heroicons/vue/20/solid/index.js";
import {inject} from 'vue';

const route = inject('route');

const pageTree = [
    {name: 'feedback.forms', href: route('main.feedback.forms'), current: true}
]

const props = defineProps({
    forms: {
        type: Array,
        required: true,
    }
})

</script>

<template>
    <Head :title="$t('feedback.forms')"/>
    <Layout>
        <div class="flex flex-row align-middle justify-between">
            <Breadcrumbs :pageTree="pageTree"/>
            <Link :href="route('main.feedback.forms.add')" class="" as="button">
                <PlusCircleIcon class="size-8 text-green-600 my-2 mb-6" aria-hidden="true"
                                aria-label="Add feedback form"/>
            </Link>
        </div>

        <ul role="list" class="divide-y divide-gray-700">
            <li v-for="form in props.forms" :key="form.id" class="relative flex justify-between gap-x-6 py-5">
                <div class="flex min-w-0 gap-x-4">
                    <!--                <img class="size-12 flex-none rounded-full bg-gray-50" :src="item.imageUrl" alt="" />-->
                    <div class="min-w-0 flex-auto">
                        <p class="text-sm/6 font-semibold text-white">
                            <Link :href="route('main.feedback.forms.edit',form)">
                                {{ form.name }}
                            </Link>
                        </p>
                        <p class="mt-1 flex text-xs/5 text-gray-500">
                            <!--                            {{$t('validity')}}: {{ contract.start_date }} - {{ contract.end_date }} &bull; {{ $t(contract.status) }}-->
                            {{ $t('activity') }}: {{ form.activity ? form.activity.name : $t('all') }} &bull;
                            {{ $t('trainer') }}: {{ form.trainer ? form.trainer.name : $t('all') }} &bull;
                            {{ $t('location') }}: {{ form.location ? form.location.name : $t('all') }} &bull;
                            {{ $t('question', {value: form.questions.length}) }} &bull;
                            {{
                                form.sendAfterActivity ? $t('send_after_activity', {value: form.sendAfterActivityNumber}) : $t('not.sending.automatically')
                            }}
                        </p>
                    </div>
                </div>
                <div class="flex shrink-0 items-center gap-x-1">
                    <Link :href="route('main.feedback.forms.edit',form)"
                          aria-hidden="true">
                        <ChevronRightIcon class="size-5 flex-none text-gray-400" aria-hidden="true"/>
                    </Link>
                </div>
            </li>
        </ul>
    </Layout>
</template>
