import {useTolgee} from "@tolgee/vue";

function getLongLocaleName(locale: string):string {
    switch (locale) {
        case 'ro':
            return 'ro-RO';
        case 'de':
            return 'de-DE';
        case 'hu':
            return 'hu-HU';
        default:
            return 'en-US';
    }
}

function localeDate(dateString, type) {
    if (type==='long') {
        const date = new Date(dateString);
        return date.toLocaleString(useTolgee().value.getLanguage(),{weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour:'numeric', minute:'numeric'})
    } else {
        const date = new Date(dateString);
        return date.toLocaleString(useTolgee().value.getLanguage(),{hour:'numeric', minute:'numeric'})
    }
}

export { getLongLocaleName,localeDate };
