<template>
    <TolgeeProvider>
    <Head title="Feedback"/>
    <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-sm">
            <img class="mx-auto h-10 w-auto" :src="$page.props.tenant.icon" :alt="$page.props.tenant.name"/>
            <h2 class="mt-10 text-center text-2xl/9 font-bold tracking-tight text-white ">{{ capitalize($t('feedback.form'))}}</h2>
        </div>

        <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form @submit.prevent="submit" class="space-y-6">
                <Input v-if="props.email===''" name="email" type="email" :label="$t('email.address')"
                       size="30"
                       placeholder="Email" :error="internalForm.errors.email"
                       v-model="internalForm.email"/>
                <div v-for="question in props.form.questions" :key="question.id" class="space-y-4">
                    <Input v-if="question.type==='text'" name="question.id" type="text" :label="question.question"
                           size="30"
                           placeholder="Answer" :error="internalForm.errors[question.id]"
                           v-model="internalForm[question.id]"/>
                    <TextArea v-if="question.type==='paragraph'" name="question.id" :label="question.question" size="30"
                              placeholder="Answer" :error="internalForm.errors[question.id]"
                              v-model="internalForm[question.id]"/>
                    <Select v-if="question.type==='dropdown'" name="question.id" :label="question.question"
                            :error="internalForm.errors[question.id]" :options="selectOptions[question.id]"
                            v-model="internalForm[question.id]"/>
                    <SelectMultiple v-if="question.type==='multipleChoice'" name="question.id"
                                    :label="question.question"
                                    :error="internalForm.errors[question.id]" :options="selectOptions[question.id]"
                                    v-model="internalForm[question.id]"/>
                    <div class="" v-if="question.type==='rating'">
                        <p class="block text-sm/6 font-medium text-white">{{ question.question }}</p>
                        <div v-if="selectOptions[question.id].ratingType==='stars'">
                        <StarIcon v-for="index in selectOptions[question.id].numArray" :key="index"
                                  :class="[internalForm[question.id]>=index ? 'text-yellow-400' :  'text-gray-200','size-5','shrink-0','inline-block']" aria-hidden="true"
                                  @click="selectRating(question.id,index)" />
                        </div>
                        <div v-if="selectOptions[question.id].ratingType==='hearts'">
                        <HeartIcon v-for="index in selectOptions[question.id].numArray" :key="index"
                                  :class="[internalForm[question.id]>=index ? 'text-red-500' :  'text-gray-200','size-5','shrink-0','inline-block']" aria-hidden="true"
                                  @click="selectRating(question.id,index)" />
                        </div>
                        <div v-if="selectOptions[question.id].ratingType==='thumbsup'">
                        <HandThumbUpIcon v-for="index in selectOptions[question.id].numArray" :key="index"
                                  :class="[internalForm[question.id]>=index ? 'text-blue-500' :  'text-gray-200','size-5','shrink-0','inline-block']" aria-hidden="true"
                                  @click="selectRating(question.id,index)" />
                        </div>
                        <div v-if="internalForm.errors[question.id]" class="text-red-700">{{ internalForm.errors[question.id] }}</div>
                    </div>
                </div>
                <div class="mt-6">
                    <button type="submit"
                            class="block w-full rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-gray-900/10 hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
                        {{ $t('send') }}
                    </button>
                </div>
            </form>

        </div>
    </div>
    </TolgeeProvider>
</template>

<script setup>
import {Head, router, useForm} from '@inertiajs/vue3'
import {capitalize, inject} from "vue";
import Input from "../../../Components/Form/Input.vue";
import TextArea from "../../../Components/Form/TextArea.vue";
import Select from "../../../Components/Form/Select.vue";
import SelectMultiple from "../../../Components/Form/SelectMultiple.vue";
import {HandThumbUpIcon,HeartIcon, StarIcon} from "@heroicons/vue/24/solid/index.js";
import {TolgeeProvider} from "@tolgee/vue";

const route = inject('route');

const props = defineProps({
    form: {
        type: Object,
        required: true,
    },
    email: {
        type: String,
        required: true,
    },
    logId: {
        type: Number,
        required: true,
    },
})
const internalForm = useForm({
    email: props.email,
})

const selectOptions = [{}]
populateData()

function populateData() {
    props.form.questions.forEach((question) => {
        if (question.type === 'multipleChoice') {
            internalForm[question.id] = []
        } else if (question.type === 'rating') {
            internalForm[question.id] = 0
        } else {
            internalForm[question.id] = ''
        }
        if (question.type === 'dropdown' || question.type === 'multipleChoice') {
            const options = JSON.parse(question.extra)
            selectOptions[question.id] = options.options.map((option) => {
                return {value: option.value, label: option.value, name: option.value, id: option.value}
            })
        }
        if (question.type === 'rating') {
            const options = JSON.parse(question.extra)
            selectOptions[question.id] = options.rating
            selectOptions[question.id].numArray = Array.from({length: options.rating.ratingNumber}, (_, i) => i + 1)
        }
    })
}

function dynamicFields() {
    const data = {}
    props.form.questions.forEach((question) => {
        data[question.id] = internalForm[question.id]
    });
    return data
}

function selectRating(questionId, value) {
    internalForm[questionId] = value
}

function submit() {
    internalForm.transform((data) => ({
        ...data,
        ...dynamicFields(),
    })).post(route('tenant.feedback.form.post', {form: props.form.id, log: props.logId}), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('tenant.feedback.form.thankyou', {form: props.form.id}))
        }
    });
}

</script>
