<template>
    <div class="space-y-12">
        <div class="border-b border-white/10 pb-12">
            <h2 class="text-base/7 font-semibold text-white">{{ $t('student.information')}}</h2>
            <p class="mt-1 text-sm/6 text-gray-400">{{ $t('information.necessary') }}</p>

            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <Input name="first_name" type="text" :label="$t('name.first')" size="30" v-model="props.form.first_name" :placeholder="$t('name.first')" :error="props.form.errors.first_name"/>
                <Input name="last_name" type="text" :label="$t('name.last')" size="30" v-model="props.form.last_name" :placeholder="$t('name.last')" :error="props.form.errors.last_name"/>
                <Input name="email" type="email" :label="$t('email.address')" size="30" v-model="props.form.email" :placeholder="$t('email.address')" :error="props.form.errors.email"/>
                <Input name="phone" type="tel" :label="$t('phone.number')" size="30" v-model="props.form.phone" :placeholder="$t('phone.number')" :error="props.form.errors.phone"/>
                <Input name="dob" type="date" :label="$t('date.of.birth')" size="30" v-model="props.form.dob" :placeholder="$t('date.of.birth')" :error="props.form.errors.dob"/>
                <Input name="parent_account_email" type="email" :label="$t('email.address.parent')" size="30" :required=false v-model="props.form.parent_account_email" :placeholder="$t('email.address.parent')" :error="props.form.errors.parent_account_email"/>
            </div>
        </div>

        <div class="border-b border-white/10 pb-12">
            <h2 class="text-base/7 font-semibold text-white">{{ $t('student.details')}}</h2>
            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <Input name="address" :label="$t('address')" size="30" v-model="props.form.address" :placeholder="$t('address')" :error="props.form.errors.address"/>
                <Input name="city" type="text" :label="$t('city')" size="30" v-model="props.form.city" :placeholder="$t('city')" :error="props.form.errors.city"/>
                <Input name="state" type="text" :label="$t('state')" size="30" v-model="props.form.state" :placeholder="$t('state')" :error="props.form.errors.state"/>
                <Select name="country" :label="$t('country')" size="30" v-model="props.form.country" :options="countries" :error="props.form.errors.country"/>
                <Input name="zip" type="text" :label="$t('zip.code')" size="30" v-model="props.form.zip" :placeholder="$t('zip.code')" :error="props.form.errors.zip"/>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import Select from "../../../Components/Form/Select.vue";
import Input from "../../../Components/Form/Input.vue";

const props = defineProps({
    form: {
        type: Object,
        required: true,
    },
    countries: {
        type: Array,
        required: true,
    }
})
</script>
