<script setup>
import Layout from "../../../Layouts/Tenant/Layout.vue";
import Breadcrumbs from "../../../Components/Tenant/Breadcrumbs.vue";
import {Head, Link, router, useForm} from '@inertiajs/vue3'
import {inject} from 'vue';
import Select from "../../../Components/Form/Select.vue";
import SelectModel from "../../../Components/Form/SelectModel.vue";
import {localeDate} from "../../../helpers.js";

const route = inject('route');

const props = defineProps({
    availableTimes: {
        type: Array,
        required: false,
        default: () => [],
    },
    contracts: {
        type: Array,
        required: true,
    },
    futureClass: {
        type: Object,
        required: false,
        default: () => {
        },
    },
    contract_id: {
        type: [String,Number],
        required: false,
        default: null,
    },
    availableScheduledClasses: {
        type: Number,
        required: false,
        default: 0,
    }
})
const pageTree = [
    {name: 'schedule.student.view', href: route('tenant.schedule.calendar'), current: false},
    {name: 'schedule.add', href: route('tenant.schedule.add'), current: true},
]

const form = useForm({
    contract_id: props.contract_id,
    status: 'pending',
    time: '',
})

const contractOptions = props.contracts.map((contract) => {
    return {
        value: contract.id,
        label: contract.items[0].activity.name,
    }
})
const availableTimes = props.availableTimes.map(time => {
    return {
        name:  localeDate(time.start_date,'long') + " - " + localeDate(time.end_date,'short'),
        value: time.start_date,
        id: time.start_date
    }
})
function selectContract() {
    form.transform((data) => ({
        ...data['contract_id'],
        contract_id: data.contract_id
    }))
        .get(route('tenant.schedule.add'))
}

function submit() {
    form.post(route('tenant.schedule.store'),{
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('tenant.schedule.calendar'))
        }
    })
}
</script>

<template>
    <Head :title="$t('schedule.student')"/>
    <Layout>
        <div class="flex flex-row align-middle justify-between">
            <Breadcrumbs :pageTree="pageTree"/>
        </div>
        <form @submit.prevent="submit">
            <div class="space-y-12">
                <div v-if="!props.contract_id">
                    <div class="border-b border-white/10 pb-12">
                        <h2 class="text-base/7 font-semibold text-white">{{ $t('contract.select.first') }}</h2>
                        <Select name="contract_id" :label="$t('contract')" :options="contractOptions" v-model="form.contract_id"
                        :error="form.errors.contract_id" @update:modelValue="selectContract" class="w-60"/>
                    </div>
                </div>
                <div v-if="props.contract_id">
                    <div class="border-b border-white/10 pb-12">
                        <h2 class="text-base/7 font-semibold text-white">{{ $t('scheduled.class') }} -
                            {{ props.futureClass.activity.name }}</h2>
                        <p class="mt-1 text-sm/6 text-gray-400">{{ props.futureClass.location.name }},
                            {{ props.futureClass.room.name }} {{ $t('with') }} {{ props.futureClass.trainer.name }}</p>

                        <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <SelectModel name="time" :label="$t('date.and.time')" :options="availableTimes"
                                         v-model="form.time" :error="form.errors.time"/>
                        </div>
                        <div v-if="props.availableScheduledClasses==0" class="text-red-700">{{ $t('scheduled.classes.none.available') }}</div>
                    </div>
                </div>

                <div class="mt-6 flex items-center justify-end gap-x-6">
                    <Link :href="route('tenant.schedule.calendar')" as="button"
                          class="text-sm/6 font-semibold text-white">{{  $t('cancel') }}
                    </Link>
                    <button type="submit" v-if="props.availableScheduledClasses!=0"
                            class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                        {{ $t('save.appointment') }}
                    </button>
                </div>

            </div>
        </form>
    </Layout>
</template>
