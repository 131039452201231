<script setup>
import Layout from "../../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../../Components/Main/Breadcrumbs.vue";
import {Head} from '@inertiajs/vue3'
import Calendar from "./Calendar.vue";
import { inject } from 'vue';

const route = inject('route');

const props = defineProps({
    location: {
        type: Object,
        required: true,
    },
    scheduledClasses: {
        type: Array,
        required: true,
    },
    rooms: {
        type: Array,
        required: true,
    },
    publicHolidays: {
        type: Array,
        required: true,
    },
    limited: {
        type: Boolean,
        default: false,
    }
})
const pageTree = [
    {name: 'schedule.location', href: route('main.schedule.location'), current: false},
    {name: props.location.name, href: route('main.schedule.location.detail',props.location), current: true}
]

</script>

<template>
    <Head :title="props.location.name"/>
    <Layout>
        <div class="flex flex-row align-middle justify-between">
            <Breadcrumbs :pageTree="pageTree"/>
        </div>
        <div class="w-full">
            <Calendar :scheduledClasses="scheduledClasses" :publicHolidays="publicHolidays" :limited="limited"/>
        </div>
    </Layout>
</template>
