<template>
    <div class="space-y-12">
        <div class="border-b border-white/10 pb-12">
            <h2 class="text-base/7 font-semibold text-white">{{ $t('tenant.information')}}</h2>

            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <Input name="name" type="text" :label="$t('name')" size="30" v-model="props.form.name" :placeholder="$t('name')" :error="props.form.errors.name"/>
                <Input name="subdomain" type="text" :label="$t('subdomain')" size="30" v-model="props.form.subdomain" :placeholder="$t('subdomain')" :error="props.form.errors.subdomain"/>
                <SelectModel name="plan_id" :label="$t('plan')" v-model="props.form.plan_id" :options="props.plans" :error="props.form.errors.plan_id" @update:modelValue="selectPlan"/>
                <Input v-if="hasDomainAccess" name="domain" type="text" :label="$t('domain.name')" size="30" v-model="props.form.domain" :placeholder="$t('domain.name')" :error="props.form.errors.domain"/>
            </div>
        </div>
        <div class="border-b border-white/10 pb-12">
            <h2 class="text-base/7 font-semibold text-white">{{ $t('company.information')}}</h2>

            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <Input name="company_name" type="text" :label="$t('company.legal.name')" size="30" v-model="props.form.company_name" :placeholder="$t('company.legal.name')" :error="props.form.errors.company_name"/>
                <Input name="legal_representative" type="text" :label="$t('company.legal.representative')" size="30" v-model="props.form.legal_representative" :placeholder="$t('company.legal.representative')" :error="props.form.errors.legal_representative"/>
                <Input name="email" type="email" :label="$t('email.address')" size="30" v-model="props.form.email" :placeholder="$t('email.address')" :error="props.form.errors.email"/>
                <Input name="website" type="text" :label="$t('website')" size="30" v-model="props.form.website" :placeholder="$t('website')" :error="props.form.errors.website"/>
                <Input name="phone" type="text" :label="$t('phone')" size="30" v-model="props.form.phone" :placeholder="$t('phone')" :error="props.form.errors.phone"/>
                <Input name="address" type="text" :label="$t('address')" size="30" v-model="props.form.address" :placeholder="$t('address')" :error="props.form.errors.address"/>
                <Input name="city" type="text" :label="$t('city')" size="30" v-model="props.form.city" :placeholder="$t('city')" :error="props.form.errors.city"/>
                <Input name="state" type="text" :label="$t('state')" size="30" v-model="props.form.state" :placeholder="$t('state')" :error="props.form.errors.state"/>
                <Input name="zip" type="text" :label="$t('zip.code')" size="30" v-model="props.form.zip" :placeholder="$t('zip.code')" :error="props.form.errors.zip"/>
                <Select name="country" :label="$t('country')" :options="countries" :flag=true :error="props.form.errors.country" v-model="props.form.country"  />
                <Input name="fiscal_code" type="text" :label="$t('fiscal.code')" size="30" v-model="props.form.fiscal_code" :placeholder="$t('fiscal.code')" :error="props.form.errors.fiscal_code"/>
                <Input name="vat_code" type="text" :label="$t('vat.code')" size="30" v-model="props.form.vat_code" :placeholder="$t('vat.code')" :error="props.form.errors.vat_code"/>
                <Input name="registration_number" type="text" :label="$t('registration.number')" size="30" v-model="props.form.registration_number" :placeholder="$t('registration.number')" :error="props.form.errors.registration_number"/>
            </div>
        </div>
    </div>
</template>

<script setup>

import Input from "../../../../Components/Form/Input.vue";
import SelectModel from "../../../../Components/Form/SelectModel.vue";
import {ref} from "vue";
import Select from "../../../../Components/Form/Select.vue";

const props = defineProps({
    plans: {
        type: Object,
        required: true
    },
    form: {
        type: Object,
        required: true
    },
    countries: {
        type: Array,
        required: true
    }
})

let hasDomainAccess = ref(false);

function selectPlan(value) {
    const plan = props.plans.find(p => p.id === value);
    if (!plan) {
        return;
    }
    hasDomainAccess.value = plan.ownDomainAccess;
}

selectPlan(props.form.plan_id);

</script>
