<template>
    <div class="space-y-12">
        <div class="border-b border-white/10 pb-12">
            <h2 class="text-base/7 font-semibold text-white">{{ $t('plans.information')}}</h2>

            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <Input name="duration" type="number" :label="$t('duration.months')" size="30" v-model="props.form.duration" :placeholder="$t('duration')" :error="props.form.errors.duration"/>
                <Input name="price" type="number" :label="$t('price')" size="30" v-model="props.form.price" :placeholder="$t('price')" :error="props.form.errors.price"/>
            </div>
        </div>

    </div>
</template>

<script setup>

import Input from "../../../../../Components/Form/Input.vue";

const props = defineProps({
    form: {
        type: Object,
        required: true
    }
})

</script>
