<template>
    <Head :title="$t('tenants')"/>
    <Layout>
        <div class="flex flex-row align-middle justify-between">
            <Breadcrumbs :pageTree="pageTree"/>
            <Link :href="route('superAdmin.tenants.add')" class="" as="button">
                <PlusCircleIcon class="size-8 text-green-600 my-2 mb-6" aria-hidden="true" aria-label="Add tenant"/>
            </Link>
        </div>
        <ul role="list" class="divide-y divide-gray-700">
            <li v-for="tenant in props.tenants" :key="tenant.id" class="relative flex justify-between gap-x-6 py-5">
                <div class="flex min-w-0 gap-x-4">
                    <!--                <img class="size-12 flex-none rounded-full bg-gray-50" :src="item.imageUrl" alt="" />-->
                    <div class="min-w-0 flex-auto">
                        <p class="text-sm/6 font-semibold text-white">
                            <Link :href="route('superAdmin.tenants.edit',tenant.id)">
                                {{ tenant.name }}
                            </Link>
                        </p>
                        <ul class="mt-1 text-xs/5 text-gray-500 flex flex-wrap items-start gap-x-1" v-if="tenant.plan">
                            <li>{{ tenant.plan.name }} &bull;</li>
                            <li>{{ $t('extraTrainers',{value:tenant.extraTrainers}) }} &bull;</li>
                            <li>{{ $t('extraLocations',{value:tenant.extraLocations}) }} &bull;</li>
                            <li>{{ $t('subdomain')}}: {{ tenant.subdomain}}</li>
                            <li v-if="tenant.plan.ownDomainAccess===true && tenant.domain!==null">{{ $t('domain')}}: {{ tenant.domain}}</li>
                        </ul>
                    </div>
                </div>

                <div class="flex shrink-0 items-center gap-x-4">
                    <div class="hidden sm:flex sm:flex-col sm:items-end">
                    </div>
                    <Link :href="route('superAdmin.tenants.edit',tenant.id)" class="size-5 flex-none text-gray-400"
                          aria-hidden="true">
                        <ChevronRightIcon class="size-5 flex-none text-gray-400" aria-hidden="true"/>
                    </Link>
                </div>
            </li>
        </ul>
    </Layout>
</template>

<script setup lang="ts">
import {Head, Link} from "@inertiajs/vue3";
import Breadcrumbs from "../../../../Components/Main/Breadcrumbs.vue";
import Layout from "../../../../Layouts/Main/Layout.vue";
import { inject } from 'vue';
import { ChevronRightIcon,PlusCircleIcon} from "@heroicons/vue/24/outline/index.js";

const route = inject('route');

const pageTree = [
    {name: 'tenants', href: route('superAdmin.tenants.index'), current: true}
]

const props = defineProps({
    tenants: {
        type: Array,
        required: true
    }
})
</script>
