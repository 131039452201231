<script setup>
import Layout from "../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../Components/Main/Breadcrumbs.vue";
import {Link} from "@inertiajs/vue3";
import {PlusCircleIcon} from "@heroicons/vue/24/outline/index.js";
import {Head} from '@inertiajs/vue3'
import {BuildingOfficeIcon,ChevronRightIcon} from "@heroicons/vue/20/solid/index.js";
import { inject } from 'vue';

const route = inject('route');

const pageTree = [
    {name: 'locations', href: route('main.locations'), current: true}
]

const props = defineProps({
    locations: {
        type: Array,
        required: true,
    }
})

</script>

<template>
    <Head :title="$t('locations')"/>
    <Layout>
        <div class="flex flex-row align-middle justify-between">
            <Breadcrumbs :pageTree="pageTree"/>
            <Link :href="route('main.locations.add')" class="" as="button">
                <PlusCircleIcon class="size-8 text-green-600 my-2 mb-6" aria-hidden="true" aria-label="Add location"/>
            </Link>
        </div>

        <ul role="list" class="divide-y divide-gray-700">
            <li v-for="location in props.locations" :key="location.id" class="relative flex justify-between gap-x-6 py-5">
                <div class="flex min-w-0 gap-x-4">
                    <!--                <img class="size-12 flex-none rounded-full bg-gray-50" :src="item.imageUrl" alt="" />-->
                    <div class="min-w-0 flex-auto">
                        <p class="text-sm/6 font-semibold text-white">
                            <Link :href="route('main.locations.edit',location)">
                                {{ location.name }}
                            </Link>
                        </p>
                        <p class="mt-1 flex text-xs/5 text-gray-500">
                            {{ location.address }}, {{ location.city }}, {{ location.state }}, {{ location.zip }}
                        </p>
                        <p v-if="location.rooms.length>0" class="mt-1 text-xs/5 text-gray-500">
                            {{  $t('rooms.plural',{value: location.rooms.length}) }}
                        </p>
                    </div>
                </div>
                <div class="flex shrink-0 items-center gap-x-1">
                    <div class="hidden sm:flex sm:flex-col sm:items-end">
                        <p v-if="location.activities.length>0" class="mt-1 text-xs/5 text-gray-500">
                            {{ $t('activity.plural',{value: location.activities.length}) }}
                        </p>
                        <p v-if="location.trainers.length>0" class="mt-1 text-xs/5 text-gray-500">
                            {{ $t('trainer.plural', { value: location.trainers.length}) }}
                        </p>
                    </div>
                    <Link :href="route('main.locations.rooms',location)">
                        <BuildingOfficeIcon class="size-5 flex-none text-green-600" aria-hidden="true"/>
                    </Link>
                    <Link :href="route('main.locations.edit',location)"
                          aria-hidden="true">
                        <ChevronRightIcon class="size-5 flex-none text-gray-400" aria-hidden="true"/>
                    </Link>
                </div>
            </li>
        </ul>
    </Layout>
</template>
