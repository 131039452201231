<template>
    <TolgeeProvider>
    <Head title="Login" />
    <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-sm">
            <img class="mx-auto h-10 w-auto" src="/img/logo.png" alt="EasyManage" />
            <h2 class="mt-10 text-center text-2xl/9 font-bold tracking-tight text-white">{{  $t('signin.to.account') }}</h2>
        </div>

        <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form @submit.prevent="submit" class="space-y-6" :action="route('main.login.post')" method="POST">
                <div>
                    <label for="email" class="block text-sm/6 font-medium text-white">{{ $t('email.address')}}</label>
                    <div class="mt-2">
                        <input type="email" name="email" id="email" v-model="form.email" autocomplete="email" required="" class="block w-full rounded-md bg-white/5 px-3 py-1.5 text-base text-white outline outline-1 -outline-offset-1 outline-white/10 placeholder:text-gray-500 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-500 sm:text-sm/6" />
                    </div>
                </div>

                <div>
                    <div class="flex items-center justify-between">
                        <label for="password" class="block text-sm/6 font-medium text-white">{{  $t('password') }}</label>
                        <div class="text-sm">
                            <a :href="route('password.forgot')" class="font-semibold text-indigo-400 hover:text-indigo-300">{{ $t('forgot.password.question')}}</a>
                        </div>
                    </div>
                    <div class="mt-2">
                        <input type="password" name="password" id="password" v-model="form.password" autocomplete="current-password" required="" class="block w-full rounded-md bg-white/5 px-3 py-1.5 text-base text-white outline outline-1 -outline-offset-1 outline-white/10 placeholder:text-gray-500 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-500 sm:text-sm/6" />
                    </div>
                </div>

                <div class="flex flex-row items-end w-full justify-between">
                    <div></div>
                    <div class="">
                        <label for="remember" class="text-sm/6 font-medium text-white pr-1">{{  $t('remember.me') }}</label>
                        <input type="checkbox" name="remember" id="remember" v-model="form.remember" class="rounded border border-gray-300 bg-white checked:border-indigo-600 checked:bg-indigo-600 indeterminate:border-indigo-600 indeterminate:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto" />
                    </div>
                </div>

                <div>
                    <button type="submit" class="flex w-full justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">{{  $t('sign.in') }}</button>
                </div>

                <div v-if="form.errors.email" class="text-red-600">{{ form.errors.email }}</div>
            </form>

            <div>
                <div class="relative mt-10">
                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="w-full border-t border-gray-200" />
                    </div>
                    <div class="relative flex justify-center text-sm/6 font-medium">
                        <span class="bg-gray-800 px-6 text-gray-100">{{  $t('or.continue.with') }}</span>
                    </div>
                </div>

                <div class="mt-6 flex flex-col gap-3">
                    <a :href="route('socialite.redirect','google')" class="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent">
                        <svg class="h-5 w-5" aria-hidden="true" viewBox="0 0 24 24">
                            <path d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z" fill="#EA4335" />
                            <path d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z" fill="#4285F4" />
                            <path d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z" fill="#FBBC05" />
                            <path d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z" fill="#34A853" />
                        </svg>
                        <span class="text-sm/6 font-semibold">Google</span>
                    </a>
                    <a :href="route('socialite.redirect','facebook')" class="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent">
                        <svg class="h-5 w-5" aria-hidden="true" viewBox="0 0 48 48">
                            <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="Color-" transform="translate(-200.000000, -160.000000)" fill="#4460A0">
                                    <path d="M225.638355,208 L202.649232,208 C201.185673,208 200,206.813592 200,205.350603 L200,162.649211 C200,161.18585 201.185859,160 202.649232,160 L245.350955,160 C246.813955,160 248,161.18585 248,162.649211 L248,205.350603 C248,206.813778 246.813769,208 245.350955,208 L233.119305,208 L233.119305,189.411755 L239.358521,189.411755 L240.292755,182.167586 L233.119305,182.167586 L233.119305,177.542641 C233.119305,175.445287 233.701712,174.01601 236.70929,174.01601 L240.545311,174.014333 L240.545311,167.535091 C239.881886,167.446808 237.604784,167.24957 234.955552,167.24957 C229.424834,167.24957 225.638355,170.625526 225.638355,176.825209 L225.638355,182.167586 L219.383122,182.167586 L219.383122,189.411755 L225.638355,189.411755 L225.638355,208 L225.638355,208 Z" id="Facebook">

                                    </path>
                                </g>
                            </g>
                        </svg>
                        <span class="text-sm/6 font-semibold">Facebook</span>
                    </a>

                <div v-if="props.error" class="text-red-600 w-full text-center">{{ props.error }}</div>
                </div>
            </div>

<!--            <p class="mt-10 text-center text-sm/6 text-gray-400">-->
<!--                Not a member?-->
<!--                <a href="#" class="font-semibold text-indigo-400 hover:text-indigo-300">Start a 14 day free trial</a>-->
<!--            </p>-->
        </div>
    </div>
    </TolgeeProvider>
</template>

<script setup>
import { useForm} from '@inertiajs/vue3'
import { Head } from '@inertiajs/vue3'
import {ref} from "vue";

import { inject } from 'vue';
import {TolgeeProvider} from "@tolgee/vue";

const route = inject('route');
let email = ref();
let password = ref();

const props = defineProps({
    error: {
        type: String,
        default: ''
    }
})

const form = useForm({
    email: email,
    password: password,
    remember: true
})

function submit() {
    form.post(route('main.login.post'),{
        onError: () => {
            password.value = null
        },
        onFinish: () => {
            password.value = null
        }
    })
}
</script>
