<template>
    <TransitionRoot as="template" :show="open">
        <Dialog class="relative z-10" @close="emit('modal-close')">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                             leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500/75 transition-opacity"/>
            </TransitionChild>

            <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <TransitionChild as="template" enter="ease-out duration-300"
                                     enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                     enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                                     leave-from="opacity-100 translate-y-0 sm:scale-100"
                                     leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel
                            class="relative transform overflow-hidden rounded-lg bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                            <div class="sm:flex sm:items-start">
                                <div
                                    class="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:size-10">
                                    <DocumentCurrencyDollarIcon class="size-6 text-green-500" aria-hidden="true"/>
                                </div>
                                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                    <DialogTitle as="h3" class="text-base font-semibold text-gray-100">
                                        {{ $t('payment.add') }}
                                    </DialogTitle>
                                    <div class="mt-2">
                                        <Input :label="$t('payment.amount')" name="paymentAmount"
                                               v-model="props.addPaymentForm.amount" type="number" class="w-full"
                                               :placeholder="$t('payment.amount')"/>
                                        <Select :label="$t('payment.method')" name="paymentMethod"
                                                v-model="props.addPaymentForm.method" class="w-full mt-2"
                                                :options="paymentMethods" placeholder="{{ $t('payment.method') }}"/>
                                        <Input :label="$t('payment.reference')" name="paymentReference"
                                               v-model="props.addPaymentForm.reference" class="w-full mt-2"
                                               :placeholder="$t('payment.reference')"/>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <button type="button"
                                        class="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-green-500 sm:ml-3 sm:w-auto"
                                        @click="emit('modal-confirmed')">{{ $t('payment.add') }}
                                </button>
                                <button type="button"
                                        class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-xs ring-1 ring-inset ring-gray-300 hover:bg-gray-200 sm:mt-0 sm:w-auto"
                                        @click="emit('modal-close')" ref="cancelButtonRef">{{ $t('cancel') }}
                                </button>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue'
import {onClickOutside} from '@vueuse/core'
import {ref} from 'vue'
import {DocumentCurrencyDollarIcon} from "@heroicons/vue/24/outline/index.js";
import Input from "../../../../Components/Form/Input.vue";
import Select from "../../../../Components/Form/Select.vue";

const props = defineProps({
    open: {
        type: Boolean,
        required: true,
        default: false,
    },
    addPaymentForm: {
        type: Object,
        required: true,
    },
})

const paymentMethods = [
    {
        value: 'Ordin de plata',
        label: 'bank.transfer',
    },
    {
        value: 'Card',
        label: 'card',
    },
    {
        value: 'Bon fiscal',
        label: 'receipt.fiscal',
    },
    {
        value: 'Chitanta',
        label: 'receipt',
    },
]

const emit = defineEmits(["modal-close", "modal-confirmed"]);

const target = ref(null)
onClickOutside(target, () => emit('modal-close'))

</script>
