<script setup>
import Layout from "../../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../../Components/Main/Breadcrumbs.vue";
import {Link, router, useForm} from "@inertiajs/vue3";
import { Head } from '@inertiajs/vue3'
import SelectModel from "../../../../Components/Form/SelectModel.vue";
import {ref} from "vue";
import Alert from "../../../../Components/Alert.vue";
import { inject } from 'vue';
import TextArea from "../../../../Components/Form/TextArea.vue";
import {localeDate} from "../../../../helpers.js";
const route = inject('route');

const props = defineProps({
    student: {
        type: Object,
        required: true,
    },
    contract: {
        type: Object,
        required: true,
    },
    availableTimes: {
        type: Array,
        required: true,
    },
    scheduledClass: {
        type: Object,
        required: true,
    }
})

const availableTimes = props.availableTimes.map(time => {
    return {
        name:  localeDate(time.start_date,'long') + " - " + localeDate(time.end_date,'short'),
        value: time.start_date,
        id: time.start_date
    }
})

const pageTree = [
    {name: 'schedule.student', href: route('main.schedule.student'), current: false},
    {name: props.student.name, href: route('main.schedule.student.detail',[props.student,props.contract]), current: false},
    {name: 'schedule.edit', href: route('main.schedule.student.edit',[props.student,props.contract,props.scheduledClass]), current: true}
]

const form = useForm({
    status: props.scheduledClass.status,
    time: props.scheduledClass.start_date,
    comment: props.scheduledClass.comment,
})

function submit() {
    form.patch(route('main.schedule.student.patch',[props.student,props.contract,props.scheduledClass]), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.schedule.student.detail',[props.student,props.contract]))
        }
    })
}

let deleteModalOpen = ref(false)

function openRemoveModal() {
    deleteModalOpen.value = true
}

function closeRemoveModal() {
    deleteModalOpen.value = false
}

function remove() {
    closeRemoveModal()
    router.delete(route('main.schedule.student.delete',[props.student,props.contract,props.scheduledClass]), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.schedule.student.detail',[props.student,props.contract]))
        }
    })
}
</script>

<template>
    <Head :title="$t('schedule.edit')" />
    <Layout>
        <Breadcrumbs :pageTree=pageTree />
        <form @submit.prevent="submit" >
            <div class="space-y-12">
                <div class="border-b border-white/10 pb-12">
                    <h2 class="text-base/7 font-semibold text-white">{{  $t('scheduled.class') }} - {{ props.scheduledClass.activity.name}}</h2>
                    <p class="mt-1 text-sm/6 text-gray-400">{{ props.scheduledClass.location.name }}, {{ props.scheduledClass.room.name}} {{ $t('with')}} {{ props.scheduledClass.trainer.name}}</p>


                    <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <SelectModel name="time" :label="$t('date.and.time')" :options="availableTimes" v-model="form.time" :error="form.errors.time" />
                        <TextArea name="comment" :label="$t('comment')" v-model="form.comment" :error="form.errors.comment" />
                    </div>
                </div>


            </div>

            <div class="mt-6 flex items-center justify-end gap-x-6">
                <button type="button"
                        class="rounded-md bg-red-800 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-red-700 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
                        @click="openRemoveModal">{{ $t('delete')}}</button>
                <Link :href="route('main.schedule.student.detail',[props.student,props.contract])" as="button" class="text-sm/6 font-semibold text-white">{{ $t('cancel') }}
                </Link>
                <button type="submit"
                        class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    {{ $t('save') }}
                </button>
            </div>

            <Alert :open="deleteModalOpen" :title="$t('delete')+' '+$t('scheduled.class').toLowerCase()" :message="$t('confirm.delete')+' '+$t('scheduled.class').toLowerCase()+'? '+$t('data.permanently.removed')" :confirmLabel="$t('delete')" :cancelLabel="$t('cancel')"
                   @modal-close="closeRemoveModal" @modal-confirmed="remove"/>
        </form>
    </Layout>
</template>
