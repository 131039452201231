<script setup>
import Layout from "../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../Components/Main/Breadcrumbs.vue";
import {Link, router, useForm} from "@inertiajs/vue3";
import { Head } from '@inertiajs/vue3'
import ScheduleForm from "./ScheduleForm.vue";
import { inject } from 'vue';

const route = inject('route');

const props = defineProps({
    trainer: {
        type: Object,
        required: true,
    }
})

const pageTree = [
    {name: 'trainers', href: route('main.trainers'), current: false},
    {name: 'trainer.edit', href: route('main.trainers.edit',props.trainer), current: false},
    {name: 'schedule.work', href: route('main.trainers.schedule',props.trainer), current: false},
    {name: 'add', href: route('main.trainers.schedule.add',props.trainer), current: true}
]

const form = useForm({
    day: null,
    start_time: null,
    end_time: null,
    location_id: null,
})

function submit() {
    form.post(route('main.trainers.schedule.store',props.trainer), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.trainers.schedule',props.trainer))
        }
    })
}

</script>

<template>
    <Head :title="$t('add')" />
    <Layout>
        <Breadcrumbs :pageTree="pageTree" />
        <form @submit.prevent="submit">
            <ScheduleForm :form="form" :trainer="trainer"/>

            <div class="mt-6 flex items-center justify-end gap-x-6">
                <Link :href="route('main.trainers.schedule',props.trainer)" as="button" class="text-sm/6 font-semibold text-white">
                    {{  $t('cancel') }}
                </Link>
                <button type="submit"
                        class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    {{  $t('save') }}
                </button>
            </div>
        </form>
    </Layout>
</template>
