<template>
    <TransitionRoot as="template" :show="props.open">
        <Dialog class="relative z-10" @close="$emit('close')">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                             leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500/75 transition-opacity"/>
            </TransitionChild>

            <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <TransitionChild as="template" enter="ease-out duration-300"
                                     enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                     enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                                     leave-from="opacity-100 translate-y-0 sm:scale-100"
                                     leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel
                            class="relative transform overflow-hidden rounded-lg bg-gray-300 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                            <div>
                                <div class="mt-3 text-center sm:mt-5">
                                    <DialogTitle as="h3" class="text-base font-semibold text-gray-900">
                                        {{ event.activity_name }}
                                    </DialogTitle>
                                    <DialogDescription as="h4" class="text-sm text-gray-600" v-if="event.status!=='scheduled'">
                                        {{ $t(event.status) }}
                                    </DialogDescription>
                                </div>
                                <div class="mt-5 text-left">
                                    <div class="mt-2">
                                        <CalendarDaysIcon class="h-6 w-6 inline-block text-gray-600"/>
                                        <span class="text-sm text-gray-600 pl-1">{{ event.start }}</span> <br/>
                                        <UserIcon class="h-6 w-6 inline-block text-gray-600"/>
                                        <span class="text-sm text-gray-600 pl-1">{{ event.trainer_name }}</span> <br/>
                                        <MapPinIcon class="h-6 w-6 inline-block text-gray-600"/>
                                        <span class="text-sm text-gray-600 pl-1">{{ event.location }}</span> <br/>
                                    </div>
                                </div>
                            </div>
                            <div v-if="props.error" class="text-red-700">{{ $t(props.error) }}</div>

                            <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                <button type="button" v-if="event.status==='scheduled'"
                                        class="inline-flex w-full justify-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                                        @click="$emit('cancelAppointment')">{{  $t('appointment.cancel') }}
                                </button>
                                <button type="button"
                                        class="mt-3 inline-flex w-full justify-center rounded-md bg-gray-100 hover:bg-gray-200 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                        @click="$emit('close')" ref="cancelButtonRef">{{ $t('back')}}
                                </button>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
import {Dialog, DialogDescription, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue'
import {CalendarDaysIcon, MapPinIcon, UserIcon} from "@heroicons/vue/24/outline/index.js";

const props = defineProps({
    open: {
        type: Boolean,
        required: true,
    },
    event: {
        type: Object,
        required: true
    },
    error: {
        type: String,
        required: false
    }
})
</script>
