<script setup>
import Layout from "../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../Components/Main/Breadcrumbs.vue";
import {Link, router, useForm} from "@inertiajs/vue3";
import { Head } from '@inertiajs/vue3'
import {ref} from "vue";
import Alert from "../../../Components/Alert.vue";
import PriceForm from "./PriceForm.vue";
import PriceBundleIndex from "./PriceBundleIndex.vue";
import { inject } from 'vue';

const route = inject('route');

const props = defineProps({
    price: {
        type: Object,
        required: true,
    },
    activities: {
        type: Array,
        required: true,
    },
    trainers: {
        type: Array,
        required: true,
    },
    locations: {
        type: Array,
        required: true,
    },
    currency: {
        type: String,
        required: true,
    }
})
const pageTree = [
    {name: 'pricing', href: route('main.prices'), current: false},
    {name: 'pricing.edit', href: route('main.prices.edit',props.price), current: true},
]

const form = useForm({
    activity_id: props.price.activity_id,
    trainer_id: props.price.trainer_id,
    location_id: props.price.location_id,
    price: props.price.price,
    start_date: props.price.start_date,
    end_date: props.price.end_date,
    expiry_days: props.price.expiry_days,
})

function submit() {
    form.patch(route('main.prices.patch',props.price.id), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.prices'))
        }
    })
}

let deleteModalOpen = ref(false)

function openRemoveModal() {
    deleteModalOpen.value = true
}

function closeRemoveModal() {
    deleteModalOpen.value = false
}

function remove() {
    closeRemoveModal()
    router.delete(route('main.prices.delete',props.price.id), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.prices'))
        }
    })
}
</script>

<template>
    <Head :title="$t('pricing.edit')" />
    <Layout>
        <Breadcrumbs :pageTree=pageTree />
        <form @submit.prevent="submit">
            <PriceForm :form="form" :activities="activities" :trainers="trainers" :currency="currency"/>
            <PriceBundleIndex :price="price" :currency="currency"/>

            <div class="mt-6 flex items-center justify-between">
                <div class="flex justify-start gap-x-6">
                    <Link :href="route('main.prices.bundle.add',props.price.id)" as="button" class="text-sm/6 font-semibold text-white shadow-xs px-3 py-2 bg-green-800 hover:bg-green-700 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500 rounded-md">{{ $t('bundle.add')}}</Link>
                </div>
                <div class="mt-6 flex items-center justify-end gap-x-6">
                    <button type="button"
                            class="rounded-md bg-red-800 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-red-700 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
                            @click="openRemoveModal">{{ $t('delete') }}</button>
                    <Link :href="route('main.prices')" as="button" class="text-sm/6 font-semibold text-white">{{ $t('cancel') }}
                    </Link>
                    <button type="submit"
                            class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                        {{ $t('save') }}
                    </button>
                </div>
            </div>

            <Alert :open="deleteModalOpen" :title="$t('delete')+' '+$t('price').toLowerCase()" :message="$t('confirm.delete')+' '+$t('price').toLowerCase()+'? '+$t('data.permanently.removed')" :confirmLabel="$t('delete')" :cancelLabel="$t('cancel')"
                   @modal-close="closeRemoveModal" @modal-confirmed="remove"/>
        </form>
    </Layout>
</template>
