<script setup>
import Layout from "../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../Components/Main/Breadcrumbs.vue";
import {Link, router, useForm} from "@inertiajs/vue3";
import { Head } from '@inertiajs/vue3'
import {ref} from "vue";
import Alert from "../../../Components/Alert.vue";
import RoomForm from "./RoomForm.vue";
import { inject } from 'vue';

const route = inject('route');

const props = defineProps({
    location: {
        type: Object,
        required: true,
    },
    room: {
        type: Object,
        required: true,
    }
})

const pageTree = [
    {name: 'Locations', href: route('main.locations'), current: false},
    {name: props.location.name, href: route('main.locations.edit',props.location), current: false},
    {name: 'rooms.capitalized', href: route('main.locations.rooms',props.location), current: false},
    {name: 'room.edit', href: route('main.locations.rooms.edit',[props.location,props.room]), current: true}
]

const form = useForm({
    name: props.room.name,
    description: props.room.description,
    capacity: props.room.capacity,
})

function submit() {
    form.patch(route('main.locations.rooms.patch',[props.location,props.room]), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.locations.rooms',props.location))
        }
    })
}

let deleteModalOpen = ref(false)

function openRemoveModal() {
    deleteModalOpen.value = true
}

function closeRemoveModal() {
    deleteModalOpen.value = false
}

function remove() {
    closeRemoveModal()
    router.delete(route('main.locations.rooms.delete',[props.location,props.room]), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.locations.rooms',props.location))
        }
    })
}
</script>

<template>
    <Head :title="$t('room.edit')" />
    <Layout>
        <Breadcrumbs :pageTree=pageTree />
        <form @submit.prevent="submit">
            <RoomForm :form="form"/>

            <div class="mt-6 flex items-center justify-end gap-x-6">
                <button type="button"
                        class="rounded-md bg-red-800 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-red-700 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
                        @click="openRemoveModal">{{ $t('delete')}}</button>
                <Link :href="route('main.locations.rooms',props.location)" as="button" class="text-sm/6 font-semibold text-white">{{$t('cancel')}}
                </Link>
                <button type="submit"
                        class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    {{ $t('save')}}
                </button>
            </div>
        </form>

        <Alert :open="deleteModalOpen" :title="$t('delete')+' '+$t('room')" :message="$t('confirm.delete')+' '+$t('room')+'? '+$t('data.permanently.removed')" :confirmLabel="$t('delete')" :cancelLabel="$t('cancel')"
               @modal-close="closeRemoveModal" @modal-confirmed="remove"/>
    </Layout>
</template>
