<script setup>
import Layout from "../../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../../Components/Main/Breadcrumbs.vue";
import {Link} from "@inertiajs/vue3";
import {PlusCircleIcon} from "@heroicons/vue/24/outline/index.js";
import {Head} from '@inertiajs/vue3'
import {ChevronRightIcon} from "@heroicons/vue/20/solid/index.js";
import { inject } from 'vue';

const route = inject('route');

const props = defineProps({
    trainer: {
        type: Object,
        required: true,
    },
    vacations: {
        type: Array,
        required: true,
    },
})
const pageTree = [
    {name: 'vacation', href: route('main.trainers.vacation.index',props.trainer.id), current: true},
]

</script>

<template>
    <Head :title="$t('vacation')"/>
    <Layout>
        <div class="flex flex-row align-middle justify-between">
            <Breadcrumbs :pageTree="pageTree"/>
            <Link :href="route('main.trainers.vacation.add',props.trainer.id)" class="" as="button">
                <PlusCircleIcon class="size-8 text-green-600 my-2 mb-6" aria-hidden="true" aria-label="Add vacation"/>
            </Link>
        </div>
        <ul role="list" class="divide-y divide-gray-700">
            <li v-for="vacation in props.vacations" :key="vacation.id" class="relative flex justify-between gap-x-6 py-5">
                <div class="flex min-w-0 gap-x-4">
                    <div class="min-w-0 flex-auto">
                        <p class="text-sm/6 font-semibold text-white">
                            <Link :href="route('main.trainers.vacation.edit',{trainer: props.trainer.id,vacation: vacation.id})">
                                {{ vacation.start_date }} - {{ vacation.end_date }}
                                <p class="mt-1 text-xs/5 text-gray-500">
                                    {{ $t('vacation.' + vacation.type)}}, {{ $t('vacation.status.' + vacation.status)}}
                                </p>
                            </Link>
                        </p>
                    </div>
                </div>
                <div class="flex shrink-0 items-center gap-x-4">
                    <Link :href="route('main.trainers.vacation.edit',{trainer: props.trainer.id, vacation: vacation.id})" class="size-5 flex-none text-gray-400"
                          aria-hidden="true">
                        <ChevronRightIcon class="size-5 flex-none text-gray-400" aria-hidden="true"/>
                    </Link>
                </div>
            </li>
        </ul>
    </Layout>
</template>
