<script setup>
import Layout from "../../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../../Components/Main/Breadcrumbs.vue";
import {Link, router, useForm, usePage} from "@inertiajs/vue3";
import {Head} from '@inertiajs/vue3'
import { inject } from 'vue';
import Input from "../../../../Components/Form/Input.vue";
import Select from "../../../../Components/Form/Select.vue";
import TextArea from "../../../../Components/Form/TextArea.vue";
const route = inject('route');
const page = usePage();
const props = defineProps({
    trainer: {
        type: Object,
        required: true,
    },
    vacation: {
        type: Object,
        required: true,
    },
})

const form = useForm({
    start_date: props.vacation.start_date,
    end_date: props.vacation.end_date,
    type: props.vacation.type,
    notes: props.vacation.notes,
    status: props.vacation.status,
    _method: 'patch',
})

const pageTree = [
    {name: 'vacation', href: route('main.trainers.vacation.index',props.trainer.id), current: false},
    {name: 'vacation.edit', href: route('main.trainers.vacation.edit',{trainer: props.trainer.id,vacation: props.vacation.id}), current: true},
]

function submit() {
    form.post(route('main.trainers.vacation.patch', {trainer: props.trainer.id,vacation: props.vacation.id}), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.trainers.vacation.index',props.trainer.id))
        }
    })
}

</script>

<template>
    <Head :title="$t('trainer.vacation.edit')"/>
    <Layout>
        <Breadcrumbs :pageTree="pageTree"/>
        <form @submit.prevent="submit">
            <div class="space-y-12">
                <div class="border-b border-white/10 pb-12">
                    <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <Input name="start_date" type="date" :label="$t('date.start')" size="30" v-model="form.start_date" :error="form.errors.start_date" :disabled="page.props.accessType!=='admin' && props.vacation.status!=='pending'"/>
                        <Input name="end_date" type="date" :label="$t('date.end')" size="30" v-model="form.end_date" :error="form.errors.end_date" :disabled="page.props.accessType!=='admin' && props.vacation.status!=='pending'"/>
                        <Select name="type" :label="$t('vacation.type')" :options="[
                        {value: 'vacation', label: $t('vacation.vacation')},
                        {value: 'sick', label: $t('vacation.sick')},
                        {value: 'other', label: $t('vacation.other')}
                    ]" v-model="form.type" :error="form.errors.type" :disabled="page.props.accessType!=='admin' && props.vacation.status!=='pending'"/>
                        <Select name="status" :label="$t('status')" :options="[
                        {value: 'pending', label: $t('vacation.status.pending')},
                        {value: 'approved', label: $t('vacation.status.approved')},
                        {value: 'rejected', label: $t('vacation.status.rejected')},
                        {value: 'cancelled', label: $t('vacation.status.cancelled')}]"
                        v-model="form.status" :error="form.errors.status" v-if="page.props.accessType==='admin'"/>
                        <TextArea name="notes" :label="$t('vacation.notes')" size="30" v-model="form.notes" :error="form.errors.notes" :disabled="page.props.accessType!=='admin' && props.vacation.status!=='pending'"/>
                    </div>
                </div>
            </div>
            <div class="mt-6 flex items-center justify-end gap-x-6">
                <Link :href="route('main.trainers.vacation.index',props.trainer.id)" as="button" class="text-sm/6 font-semibold text-white">
                    {{ $t('cancel')}}
                </Link>
                <button type="submit"
                        class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500" :disabled="page.props.accessType!=='admin' && props.vacation.status!=='pending'">
                    {{ $t('save')}}
                </button>
            </div>
        </form>
    </Layout>
</template>
