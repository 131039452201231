<template>
    <nav :class="props.showVersion ? 'flex flex-1 flex-col grow' : 'flex flex-1 flex-col grow-0'">
        <ul role="list" class="flex flex-1 flex-col gap-y-7">
            <li>
                <ul role="list" class="-mx-2 space-y-1">
                    <li v-for="item in props.navigation" :key="item.name">
                        <Link v-if="!item.children" :href="item.href" :class="[item.current ? 'bg-gray-900' : 'hover:bg-gray-800', 'group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold text-white']">
                            <component :is="item.icon" class="size-6 shrink-0 text-gray-400" aria-hidden="true" />
                            {{ $t(item.name) }}
                        </Link>
                        <Disclosure as="div" v-else v-slot="{ open }" :default-open="item.opened">
                            <DisclosureButton :class="[item.current ? 'bg-gray-900' : 'hover:bg-gray-800', 'flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm/6 font-semibold text-white']">
                                <component :is="item.icon" class="size-6 shrink-0 text-gray-400" aria-hidden="true" />
                                {{ $t(item.name) }}
                                <ChevronRightIcon :class="[open ? 'rotate-90 text-gray-500' : 'text-gray-400', 'ml-auto size-5 shrink-0']" aria-hidden="true" />
                            </DisclosureButton>
                            <DisclosurePanel as="ul" class="mt-1 px-2">
                                <li v-for="subItem in item.children" :key="$t(subItem.name)">
                                    <!-- 44px -->
                                    <DisclosureButton :as="Link" :href="subItem.href" :class="[subItem.current ? 'bg-gray-900' : 'hover:bg-gray-800', 'block rounded-md py-2 pl-9 pr-2 text-sm/6 text-white']">{{ $t(subItem.name) }}</DisclosureButton>
                                </li>
                            </DisclosurePanel>
                        </Disclosure>
                    </li>
                </ul>
            </li>
            <li class="-mx-6 mt-auto flex flex-row items-center" v-if="showVersion">
                <div class="w-full pb-2 text-xs text-gray-400 text-center">
                    {{ $t('version')}}: {{ $page.props.version }}
                </div>
            </li>
        </ul>
    </nav>
    <hr v-if="!showVersion"/>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronRightIcon } from '@heroicons/vue/20/solid'
import {Link} from "@inertiajs/vue3";

const props = defineProps({
    navigation: {
        type: Array,
        required: true,
    },
    showVersion: {
        type: Boolean,
        default: true,
    },
})

</script>
