<template>
    <Head :title="$t('plan.edit')"/>
    <Layout>
        <Breadcrumbs :pageTree="pageTree"/>
        <form @submit.prevent="submit">
            <PlanForm :form="form" :plan="plan"/>

            <div class="border-b border-white/10 pb-12">
                <h2 class="text-base/7 font-semibold text-white">{{  $t('pricing.details') }}</h2>
                <p class="mt-1 text-sm/6 text-gray-400">{{ $t('information.pricing')}}</p>
                <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <PlanPriceIndex :plan="props.plan"/>
                </div>
            </div>

            <div class="mt-6 flex items-center justify-between">
                <div class="flex justify-start gap-x-6">
                    <Link :href="route('superAdmin.plans.prices.add',props.plan.id)" as="button" class="text-sm/6 font-semibold text-white shadow-xs px-3 py-2 bg-green-800 hover:bg-green-700 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500 rounded-md">{{ $t('price.add')}}</Link>
                </div>
            <div class="mt-6 flex items-center justify-end gap-x-6">
                <Link :href="route('superAdmin.plans.index')" as="button" class="text-sm/6 font-semibold text-white">{{$t('cancel') }}
                </Link>
                <button type="submit"
                        class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    {{$t('save')}}
                </button>
            </div>
            </div>
        </form>
    </Layout>
</template>

<script setup lang="ts">
import {Head, Link, router,useForm} from "@inertiajs/vue3";
import Breadcrumbs from "../../../../Components/Main/Breadcrumbs.vue";
import Layout from "../../../../Layouts/Main/Layout.vue";
import { inject } from 'vue';
import PlanForm from "./PlanForm.vue";
import PlanPriceIndex from "./Price/PlanPriceIndex.vue";

const route = inject('route');

const props = defineProps({
    plan: {
        type: Object,
        required: true
    }
})

const pageTree = [
    {name: 'plans', href: route('superAdmin.plans.index'), current: false},
    {name: 'plan.edit', href: route('superAdmin.plans.edit',props.plan.id), current: true}
]

function submit() {
    form.patch(route('superAdmin.plans.update',props.plan.id), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('superAdmin.plans.index'))
        }
    })
}

const form = useForm({
    name: props.plan.name,
    trainersLimit: props.plan.trainersLimit,
    locationsLimit: props.plan.locationsLimit,
    ownDomainAccess: props.plan.ownDomainAccess,
    feedbackModuleAccess: props.plan.feedbackModuleAccess,
    homeworkModuleAccess: props.plan.homeworkModuleAccess,
    homeworkModuleType: props.plan.homeworkModuleType,
})
</script>
