<script setup>
import Layout from "../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../Components/Main/Breadcrumbs.vue";
import {Link, router, useForm} from "@inertiajs/vue3";
import { Head } from '@inertiajs/vue3'
import Input from "../../../Components/Form/Input.vue";
import Select from "../../../Components/Form/Select.vue";
import SelectModel from "../../../Components/Form/SelectModel.vue";
import { inject } from 'vue';
import {useTolgee} from '@tolgee/vue';

const route = inject('route');

const props = defineProps({
    contract: {
        type: Object,
        required: true,
    },
    students: {
        type: Array,
        required: true,
    },
    prices: {
        type: Array,
        required: true,
    },
    coupons: {
        type: Array,
        required: true,
    },
    locations: {
        type: Array,
        required: true,
    },
    trainers: {
        type: Array,
        required: true,
    },
    currency: {
    type: String,
        required: true,
    },
    availableReservedSlots: {
        type: Array,
        required: false,
    }
})

const pageTree = [
    {name: 'contracts', href: route('main.contracts'), current: false},
    {name: 'contract.edit', href: route('main.contracts.edit',props.contract.id), current: true},
]

const form = useForm({
    student_id: props.contract.student_id,
    price_id: props.contract.price_id,
    price_bundle_id: props.contract.price_bundle_id,
    coupon_id: props.contract.coupon_id,
    price: props.contract.price,
    start_date: props.contract.start_date,
    end_date: props.contract.end_date,
    status: props.contract.status,
    payment_status: props.contract.payment_status,
    item_location_id: props.contract.item_location_id,
    item_trainer_id: props.contract.item_trainer_id,
    item_activity_id: props.contract.item_activity_id,
    room_id: props.contract.room_id,
    reserved_slot: props.contract.reserved_slot,
})

function submit() {
    form.patch(route('main.contracts.patch',props.contract.id), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.contracts'))
        }
    })
}

let price_bundles = []

let localLocations = []
let localTrainers = []
let rooms = []
let reservedSlots = []

selectPrice(false)

processLocations(props.prices.find(price => price.id === form.price_id).location,props.contract.item_location_id)
processTrainers(props.prices.find(price => price.id === form.price_id).trainer,props.contract.item_trainer_id)
processReservedSlots()

function selectPrice(update=true) {
    const prices = props.prices.find(price => price.id === form.price_id)
    const price_bundles_data = prices.bundles
    processLocations(prices.location)
    processTrainers(prices.trainer)
    form.item_activity_id = prices.activity_id
    if (price_bundles_data.length === 0) {
        form.price_bundle_id = null
    }
    price_bundles = price_bundles_data.map(bundle => {
        return {
            id: bundle.id,
            name: bundle.quantity + " classes"
        }
    })
    if (update) {
        updatePrice()
    }
}

function processLocations(locations_data) {
    if (locations_data) {
        localLocations = props.locations.filter(location => location.id === locations_data.id)
            form.item_location_id = locations_data.id
    } else {
            form.item_location_id = props.contract.item_location_id
        localLocations=props.locations
    }
    updateRooms()
}

function processTrainers(trainers_data) {
    if (trainers_data) {
        localTrainers = props.trainers.filter(trainer => trainer.id === trainers_data.id)
            form.item_trainer_id = trainers_data.id
    } else {
            form.item_trainer_id = props.contract.item_trainer_id
        localTrainers=props.trainers
    }
}

function updatePrice() {
    const price_data = props.prices.find(price => price.id === form.price_id)
    form.price= price_data.price
    const start_date=new Date(form.start_date)
    form.end_date=new Date(new Date(start_date).setDate(start_date.getDate() + price_data.expiry_days)).toISOString().substring(0,10);
    if (form.price_bundle_id) {
        const price_bundle_data = price_data.bundles.find(bundle => bundle.id === form.price_bundle_id)
        form.price = price_bundle_data.price
        form.end_date=new Date(new Date(start_date).setDate(start_date.getDate() + price_bundle_data.expiry_days)).toISOString().substring(0,10);
    }
    if (form.coupon_id) {
        const coupon_data = props.coupons.find(coupon => coupon.id === form.coupon_id)
        if (coupon_data.discount_type==='percentage') {
            form.price = form.price - (form.price * coupon_data.discount / 100)
        } else {
            form.price = form.price - coupon_data.discount
        }
    }

}


function updateRooms() {
    const location = props.locations.find(location => location.id === form.item_location_id)
    if (location) {
        rooms = location.rooms
    }
}

function processReservedSlots() {
    const tolgee = useTolgee().value
    reservedSlots = props.availableReservedSlots.map(slot => {
        return {
            id: slot.dow + "_" + slot.start_time + "_" + slot.end_time,
            name: tolgee.t(prettyDayOfWeek(slot.dow)) + " " + slot.start_time + ' - ' + slot.end_time
        }
    })
}

function prettyDayOfWeek(idx) {
    return ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'][idx];
}
</script>

<template>
    <Head title="Edit contract" />
    <Layout>
        <Breadcrumbs :pageTree=pageTree />
        <form @submit.prevent="submit">
            <div class="space-y-12">
                <div class="border-b border-white/10 pb-12">
                    <h2 class="text-base/7 font-semibold text-white">{{$t('information.contract')}}</h2>
                    <p class="mt-1 text-sm/6 text-gray-400">{{$t('information.billing.attention')}}</p>

                    <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <SelectModel name="student_id" :label="$t('student')" model="students" :options="props.students" :error="form.errors.student_id" v-model="form.student_id" />
                        <SelectModel name="price_id" :label="$t('price')" model="prices" :options="props.prices" :error="form.errors.price_id" v-model="form.price_id" v-on:update:model-value="selectPrice"/>
                        <SelectModel name="price_bundle_id" :label="$t('bundle')" :options="price_bundles" model="price_bundles" :error="form.errors.price_bundle_id" v-model="form.price_bundle_id" :allow-none="true" v-on:update:model-value="updatePrice"/>
                        <SelectModel name="coupon_id" :label="$t('coupon')" model="coupons" :options="props.coupons" :error="form.errors.coupon_id" v-model="form.coupon_id" :allow-none="true" v-on:update:model-value="updatePrice"/>
                        <SelectModel name="item_location_id" :label="$t('location')" model="locations" :options="localLocations" :error="form.errors.item_location_id" v-model="form.item_location_id" v-on:update:model-value="updateRooms"/>
                        <SelectModel name="room_id" :label="$t('room')" model="rooms" :options="rooms" :error="form.errors.room_id" v-model="form.room_id"/>
                        <SelectModel name="item_trainer_id" :label="$t('trainer')" model="trainers" :options="localTrainers" :error="form.errors.item_trainer_id" v-model="form.item_trainer_id"/>
                        <SelectModel name="reserved_slot" :label="$t('reserved.slot')" model="reservedSlots" :options="reservedSlots" :error="form.errors.reserved_slot" v-model="form.reserved_slot" allow-none="allow-none"/>
                        <Input name="price" type="number" :label="$t('price') + ' (' + currency + ')'" size="30" v-model="form.price" placeholder="Price" :error="form.errors.price"/>
                        <Input name="start_date" type="date" :label="$t('valid.from')" size="30" v-model="form.start_date" placeholder="Start date" :error="form.errors.start_date" v-on:update:model-value="updatePrice"/>
                        <Input name="end_date" type="date" :label="$t('valid.until')" size="30" v-model="form.end_date" placeholder="End date" :error="form.errors.end_date"/>
                        <Select name="status" :label="$t('status')" :options="[{value:'pending',label:'pending'},{value:'active',label:'active'},{value:'completed',label:'completed'}]" :error="form.errors.status" v-model="form.status"/>
                    </div>
                </div>

            </div>

            <div class="mt-6 flex items-center justify-end gap-x-6">
                <Link :href="route('main.contracts')" as="button" class="text-sm/6 font-semibold text-white">{{ $t('cancel') }}
                </Link>
                <button type="submit"
                        class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    {{ $t('save') }}
                </button>
            </div>
        </form>
    </Layout>
</template>
