<script setup>
import Layout from "../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../Components/Main/Breadcrumbs.vue";
import {Link, router, useForm} from "@inertiajs/vue3";
import { Head } from '@inertiajs/vue3'
import LocationForm from "./LocationForm.vue";
import { inject } from 'vue';

const route = inject('route');

const pageTree = [
    {name: 'locations', href: route('main.locations'), current: false},
    {name: 'location.add', href: route('main.locations.add'), current: true},
]

const form = useForm({
    name: '',
    description: '',
    phone: '',
    email: '',
    website: '',
    country: 'ro',
    address: '',
    city: '',
    state: '',
    zip: '',
    is_active: true,
})

function submit() {
    form.post(route('main.locations.store'), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.locations'))
        }
    })
}


const countries = [
    {value: 'ro', label: 'Romania'},
    {value: 'us', label: 'United States'},
]
</script>

<template>
    <Head :title="$t('location.add')" />
    <Layout>
        <Breadcrumbs :pageTree=pageTree />
        <form @submit.prevent="submit">
            <LocationForm :form="form" :countries="countries" />

            <div class="mt-6 flex items-center justify-end gap-x-6">
                <Link :href="route('main.locations')" as="button" class="text-sm/6 font-semibold text-white">{{ $t('cancel') }}
                </Link>
                <button type="submit"
                        class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    {{ $t('save') }}
                </button>
            </div>
        </form>
    </Layout>
</template>
