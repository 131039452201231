<template>
    <div class="space-y-12">
        <div class="border-b border-white/10 pb-12">
            <h2 class="text-base/7 font-semibold text-white">{{ $t('pricing.information')}}</h2>
            <p class="mt-1 text-sm/6 text-gray-400">{{ $t('information.public')}}</p>

            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <SelectModel name="activity_id" :label="$t('activity')" :options="activity" :error="form.errors.activity_id" v-model="form.activity_id" @update:model-value="selectActivity"/><br />
                <SelectModel name="trainer_id" :label="$t('trainer')" :options="trainer" :error="form.errors.trainer_id" v-model="form.trainer_id" @update:model-value="selectTrainer" :allow-none="true"/><br />
                <SelectModel name="location_id" :label="$t('location')" :options="location" :error="form.errors.location_id" v-model="form.location_id" :allow-none="true"/>
            </div>
        </div>

        <div class="border-b border-white/10 pb-12">
            <h2 class="text-base/7 font-semibold text-white">{{  $t('pricing.details') }}</h2>
            <p class="mt-1 text-sm/6 text-gray-400">{{ $t('information.pricing')}}</p>
            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <Input name="price" type="number" :label="$t('price.class') + ' (' + currency + ')'" size="30" v-model="form.price" :placeholder="$t('price')" :error="form.errors.price"/>
                <Input name="start_date" type="date" :label="$t('valid.from')" size="30" v-model="form.start_date" :placeholder="$t('valid.from')" :error="form.errors.start_date"/>
                <Input name="end_date" type="date" :label="$t('valid.until')" size="30" v-model="form.end_date" :placeholder="$t('valid.until')" :error="form.errors.end_date"/>
                <Input name="expiry_days" type="number" :label="$t('validity') + ' (' + $t('days') + ')'" size="30" v-model="form.expiry_days" :placeholder="$t('validity')" :error="form.errors.expiry_days"/>
            </div>
        </div>
    </div>
</template>

<script setup>
import Input from "../../../Components/Form/Input.vue";
import SelectModel from "../../../Components/Form/SelectModel.vue";
import {ref} from "vue";

const props = defineProps({
    form: {
        type: Object,
        required: true
    },
    activities: {
        type: Array,
        required: true,
    },
    trainers: {
        type: Array,
        required: true,
    },
    currency: {
        type: String,
        required: true,
    }
})

let activity = ref([])
let trainer = ref([])
let location = ref([])

props.activities.forEach((item) => {
    activity.value = []
    activity.value.push({id: item.id, name: item.name})
})

function selectActivity(activityId) {
    trainer.value = []
    props.trainers.forEach((item) => {
        item.activities.forEach((activity) => {
            if (activity.id === activityId) {
                trainer.value.push({id: item.id, name: item.name})
            }
        })
    })

}

function selectTrainer(trainerId) {
    location.value = []
    props.trainers.forEach((item) => {
        if (item.id === trainerId) {
            item.locations.forEach((itemLocation) => {
                location.value.push({id: itemLocation.id, name: itemLocation.name})
            })
        }
    })
}

selectActivity(props.form.activity_id)
selectTrainer(props.form.trainer_id)
</script>
