<template>
    <div :class="props.class">
        <label v-if="props.label" :for="props.name" class="block text-sm/6 font-medium text-white">{{ props.label }}</label>
        <input v-if="type==='checkbox'" :type="props.type" :name="props.name" :id="props.name" :size="props.size" v-model="modelValue"
               class="block rounded-sm bg-white/5 px-1 py-1.5 text-base text-white outline outline-1 -outline-offset-1 outline-white/10 placeholder:text-gray-500 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-500 sm:text-sm/6"
               :placeholder="props.placeholder" :min="props.min" :max="props.max" :step="props.step"/>
        <div class="mt-2 flex">
            <input v-if="type!=='checkbox'" :type="props.type" :name="props.name" :id="props.name" :size="props.size" v-model="modelValue"
                   class="w-full rounded-md bg-white/5 px-3 py-1.5 text-base text-white outline outline-1 -outline-offset-1 outline-white/10 placeholder:text-gray-500 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-500 sm:text-sm/6"
                   :placeholder="props.placeholder" :min="props.min" :max="props.max" :step="props.step"/>
            <button type="button" @click="$emit('remove')" v-if="props.hasRemoveButton"
                    class="rounded-md ml-1 px-2 py-2 text-sm font-semibold text-white shadow-xs hover:bg-red-500 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500">
                <TrashIcon class="h-4 w-4 inline-block"/>
            </button>
        </div>
        <div>
        <div v-if="props.error" class="text-red-700">{{ props.error }}</div>
        </div>
    </div>
</template>

<script setup>
import {TrashIcon} from "@heroicons/vue/24/solid/index.js";
const props = defineProps({
    type: {
        type: String,
        default: 'text',
    },
    name: {
        type: String,
        required: true,
    },
    label: {
        type: String,
    },
    size: {
        type: String,
        default: '30',
    },
    placeholder: {
        type: String,
        default: '',
    },
    error: {
        type: String,
        default: '',
    },
    class: {
        type: String,
        default: 'sm:col-span-4',
    },
    min: {
        type: String,
        default: '',
    },
    max: {
        type: String,
        default: '',
    },
    step: {
        type: String,
        default: '',
    },
    hasRemoveButton: {
        type: Boolean,
        default: false,
    },
});

const modelValue = defineModel()
</script>
