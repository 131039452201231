<template>
    <Head title="Forgot password" />
    <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-sm">
            <img class="mx-auto h-10 w-auto" :src="$page.props.tenant.icon" :alt="$page.props.tenant.name" />
            <h2 class="mt-10 text-center text-2xl/9 font-bold tracking-tight text-white">Forgot password</h2>
        </div>

        <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form @submit.prevent="submit" class="space-y-6" :action="route('password.forgot.post')" method="POST">
                <div>
                    <label for="email" class="block text-sm/6 font-medium text-white">Email address</label>
                    <div class="mt-2">
                        <input type="email" name="email" id="email" v-model="form.email" autocomplete="email" required="" class="block w-full rounded-md bg-white/5 px-3 py-1.5 text-base text-white outline outline-1 -outline-offset-1 outline-white/10 placeholder:text-gray-500 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-500 sm:text-sm/6" />
                    </div>
                </div>

                <div>
                    <button type="submit" class="flex w-full justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">Reset password</button>
                </div>

                <div v-if="form.errors.email" class="text-red-600">{{ form.errors.email }}</div>
                <div v-if="form.status" class="text-green-500">{{  form.status }}</div>
            </form>

        </div>
    </div>
</template>

<script setup>
import {router, useForm} from '@inertiajs/vue3'
import { Head } from '@inertiajs/vue3'
import {ref} from "vue";

import { inject } from 'vue';

const route = inject('route');
let email = ref();

const form = useForm({
    email: email,
})

function submit() {
    form.post(route('tenant.password.forgot.post'),{
        onSuccess: () => {
            router.visit(route('tenant.login'))
        }
    })
}
</script>
