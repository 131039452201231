<template>
    <div>
        <TransitionRoot as="template" :show="sidebarOpen">
            <Dialog class="relative z-50 lg:hidden" @close="sidebarOpen = false">
                <TransitionChild as="template" enter="transition-opacity ease-linear duration-300"
                                 enter-from="opacity-0" enter-to="opacity-100"
                                 leave="transition-opacity ease-linear duration-300" leave-from="opacity-100"
                                 leave-to="opacity-0">
                    <div class="fixed inset-0 bg-gray-900/80"/>
                </TransitionChild>

                <div class="fixed inset-0 flex">
                    <TransitionChild as="template" enter="transition ease-in-out duration-300 transform"
                                     enter-from="-translate-x-full" enter-to="translate-x-0"
                                     leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0"
                                     leave-to="-translate-x-full">
                        <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
                            <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0"
                                             enter-to="opacity-100" leave="ease-in-out duration-300"
                                             leave-from="opacity-100" leave-to="opacity-0">
                                <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                                    <button type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false">
                                        <span class="sr-only">Close sidebar</span>
                                        <XMarkIcon class="size-6 text-white" aria-hidden="true"/>
                                    </button>
                                </div>
                            </TransitionChild>
                            <div
                                class="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-2 ring-1 ring-white/10">
                                <div class="flex h-16 shrink-0 items-center">
                                    <img class="h-8 w-auto"
                                         :src="$page.props.tenantLogo"
                                         :alt="$page.props.tenantName"/>
                                </div>
                                <SelectWithIcon v-if="$page.props.tenants.length>1" :selected="selectedTenant"
                                                :items="$page.props.tenants" @change="(value) => selectTenant(value)"
                                                label=""/>
                                <Nav :navigation="navigation" :showVersion="page.props.user.super_admin!==true"/>
                                <Nav :navigation="navigationSuperAdmin" v-if="page.props.user.super_admin===true"/>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </Dialog>
        </TransitionRoot>

        <!-- Static sidebar for desktop -->
        <div class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
            <!-- Sidebar component, swap this element with another sidebar if you like -->
            <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6">
                <div class="flex h-16 shrink-0 items-center">
                    <img class="h-16 w-auto mt-2" :src="$page.props.tenantLogo"
                         :alt="$page.props.tenantName"/>
                </div>
                <SelectWithIcon v-if="$page.props.tenants.length>1" :selected="selectedTenant"
                                :items="$page.props.tenants" @change="(value) => selectTenant(value)" label=""/>

                <Nav :navigation="navigation" :showVersion="page.props.user.super_admin!==true"/>
                <Nav :navigation="navigationSuperAdmin" v-if="page.props.user.super_admin===true"/>
            </div>
        </div>

        <div
            class="sticky top-0 z-40 flex items-center justify-between gap-x-6 bg-gray-900 px-4 py-1 shadow-xs sm:px-6">
            <div class="flex-row items-center gap-x-2 flex">
                <button type="button" class="-m-2.5 p-2.5 text-gray-400 lg:hidden" @click="sidebarOpen = true">
                    <span class="sr-only">Open sidebar</span>
                    <Bars3Icon class="size-6" aria-hidden="true"/>
                </button>
            </div>
            <div class="flex-row items-center gap-x-2 flex">
                <ProfileDropdown/>
                <LanguagePicker direction="down"/>
            </div>
        </div>

        <main class="py-10 lg:pl-72">
            <div class="px-4 sm:px-6 lg:px-8">
                <slot/>
            </div>
        </main>
    </div>
</template>

<script setup>
import {ref} from 'vue'
import {Dialog, DialogPanel, TransitionChild, TransitionRoot} from '@headlessui/vue'
import {router, usePage} from '@inertiajs/vue3'

import {
    AcademicCapIcon, BanknotesIcon,
    Bars3Icon, BuildingLibraryIcon,
    CalendarIcon,
    CogIcon,
    CurrencyDollarIcon, DocumentDuplicateIcon,
    DocumentTextIcon, GlobeAltIcon, HomeIcon, PhotoIcon, QuestionMarkCircleIcon, UserGroupIcon,
    UsersIcon, XMarkIcon,
} from '@heroicons/vue/24/outline'
import SelectWithIcon from "../Form/SelectWithIcon.vue";
import Nav from "./Nav.vue";
import LanguagePicker from "./LanguagePicker.vue";
import {inject} from 'vue';
import ProfileDropdown from "./ProfileDropdown.vue";

const page = usePage()

const route = inject('route');

const navigationAdmin = [
    {
        name: 'dashboard',
        href: route("main.dashboard"),
        icon: HomeIcon,
        current: route().current().startsWith('main.dashboard')
    },
    {
        name: 'students',
        icon: UserGroupIcon,
        current: false,
        opened: route().current().startsWith('main.students'),
        children: [
            {
                name: 'students.all',
                href: route('main.students'),
                current: route().current().startsWith('main.students')
            },
            {
                name: 'student.add',
                href: route('main.students.add'),
                current: route().current().startsWith('main.students.add')
            },
        ],
    },
    {
        name: 'locations',
        icon: BuildingLibraryIcon,
        current: false,
        opened: route().current().startsWith('main.locations'),
        children: [
            {
                name: 'locations.all',
                href: route('main.locations'),
                current: route().current().startsWith('main.locations')
            },
            {
                name: 'location.add',
                href: route('main.locations.add'),
                current: route().current().startsWith('main.locations.add')
            },
        ],
    },
    {
        name: 'classes',
        icon: AcademicCapIcon,
        current: false,
        opened: route().current().startsWith('main.activities'),
        children: [
            {
                name: 'classes.all',
                href: route('main.activities'),
                current: route().current().startsWith('main.activities')
            },
            {
                name: 'class.add',
                href: route('main.activities.add'),
                current: route().current().startsWith('main.activities.add')
            },
        ],
    },
    {
        name: 'trainers',
        icon: UsersIcon,
        current: false,
        opened: route().current().startsWith('main.trainers'),
        children: [
            {
                name: 'trainers.all',
                href: route('main.trainers'),
                current: route().current().startsWith('main.trainers')
            },
            {
                name: 'trainer.add',
                href: route('main.trainers.add'),
                current: route().current().startsWith('main.trainers.add')
            },
        ],
    },
    {
      name: 'feedback',
      icon: QuestionMarkCircleIcon,
      current: false,
        opened: route().current().startsWith('main.feedback'),
        children: [
            {
                name: 'feedback.results',
                href: route('main.feedback.results'),
                current: route().current().startsWith('main.feedback.results')
            },
            {
                name: 'feedback.forms',
                href: route('main.feedback.forms'),
                current: route().current().startsWith('main.feedback.forms')
            }
        ]
    },
    {
        name: 'pricing',
        icon: CurrencyDollarIcon,
        current: false,
        opened: route().current().startsWith('main.prices') || route().current().startsWith('main.coupons'),
        children: [
            {name: 'pricing.all', href: route('main.prices'), current: route().current().startsWith('main.prices')},
            {
                name: 'pricing.add',
                href: route('main.prices.add'),
                current: route().current().startsWith('main.prices.add')
            },
            {name: 'coupons', href: route('main.coupons'), current: route().current().startsWith('main.coupons')},
        ],
    },
    {
        name: 'media',
        icon: PhotoIcon,
        href: route('main.media.page'),
        current: false,
        opened: route().current().startsWith('main.media'),
    },
    {
        name: 'contracts',
        icon: DocumentDuplicateIcon,
        current: false,
        opened: route().current().startsWith('main.contracts'),
        children: [
            {
                name: 'contracts.all',
                href: route('main.contracts'),
                current: route().current().startsWith('main.contracts')
            },
            {
                name: 'contract.add',
                href: route('main.contracts.add'),
                current: route().current().startsWith('main.contracts.add')
            },
        ],
    },
    {
        name: 'schedule',
        icon: CalendarIcon,
        current: false,
        opened: route().current().startsWith('main.schedule'),
        children: [
            {
                name: 'students',
                href: route('main.schedule.student'),
                current: route().current().startsWith('main.schedule.student')
            },
            {
                name: 'trainers',
                href: route('main.schedule.trainer'),
                current: route().current().startsWith('main.schedule.trainer')
            },
            {
                name: 'location',
                href: route('main.schedule.location'),
                current: route().current().startsWith('main.schedule.location')
            },
        ],
    },
    {
        name: 'billing',
        icon: BanknotesIcon,
        current: false,
        opened: route().current().startsWith('main.billing'),
        children: [
            {
                name: 'invoices.all',
                href: route('main.billing.invoices.index'),
                current: route().current().startsWith('main.billing.invoices')
            },
            {name: 'invoice.add', href: "#"},
            {
                name: 'billing.settings',
                href: route('main.billing.settings'),
                current: route().current().startsWith('main.billing.settings')
            },
        ],
    },
    {
        name: 'reports',
        icon: DocumentTextIcon,
        current: false,
        opened: false,
        children: [
            {name: 'report.class', href: "#"},
            {name: 'report.trainer', href: "#"},
        ],
    },
    {
        name: 'settings',
        icon: CogIcon,
        href: route('main.settings'),
        current: false,
        opened: route().current().startsWith('main.settings'),
    }

]

function getNavigationTrainer(trainerId) {
    return [
        {
            name: 'dashboard',
            href: route("main.trainer.dashboard"),
            icon: HomeIcon,
            current: route().current().startsWith('main.trainer.dashboard')
        },
        {
            name: 'students',
            icon: UserGroupIcon,
            current: false,
            opened: route().current().startsWith('main.trainer.students'),
            children: [
                {
                    name: 'students.all.my',
                    href: route('main.trainer.students'),
                    current: route().current().startsWith('main.trainer.students')
                }
            ],
        },
        {
            name: 'schedule',
            icon: CalendarIcon,
            current: false,
            opened: route().current().startsWith('main.trainer.schedule'),
            children: [
                {
                    name: 'schedule.work',
                    href: route('main.trainer.schedule.workhours'),
                    current: route().current().startsWith('main.trainer.schedule.workhours')
                },
                {
                    name: 'calendar',
                    href: route('main.trainer.schedule.trainers'),
                    current: route().current().startsWith('main.trainer.schedule.trainers')
                },
                {
                    name: 'location',
                    href: route('main.trainer.schedule.locations'),
                    current: route().current().startsWith('main.trainer.schedule.locations')
                },
            ],
        },
        {
            name: 'vacation',
            icon: GlobeAltIcon,
            current: false,
            opened: route().current().startsWith('main.trainers.vacation'),
            children: [
                {
                    name: 'vacation.all',
                    href: route('main.trainers.vacation.index', trainerId),
                    current: route().current().startsWith('main.trainers.vacation')
                },
                {
                    name: 'vacation.add',
                    href: route('main.trainers.vacation.add', trainerId),
                    current: route().current().startsWith('main.trainers.vacation.add')
                },
            ],
        },
        {
            name: 'media',
            icon: PhotoIcon,
            href: route('main.media.page'),
            current: false,
            opened: route().current().startsWith('main.media'),
        },

    ];
}

const navigationSuperAdmin = [
    {
        name: 'tenants',
        icon: BuildingLibraryIcon,
        current: false,
        opened: route().current().startsWith('superAdmin.tenants'),
        children: [
            {
                name: 'tenants.all',
                href: route('superAdmin.tenants.index'),
                current: route().current().startsWith('superAdmin.tenants.index')
            },
            {
                name: 'tenant.add',
                href: route('superAdmin.tenants.add'),
                current: route().current().startsWith('superAdmin.tenants.add')
            },
        ],
    },
    {
        name: 'plans',
        icon: CurrencyDollarIcon,
        current: false,
        opened: route().current().startsWith('superAdmin.plans'),
        children: [
            {
                name: 'plans.all',
                href: route('superAdmin.plans.index'),
                current: route().current().startsWith('superAdmin.plans.index')
            },
            {
                name: 'plan.add',
                href: route('superAdmin.plans.add'),
                current: route().current().startsWith('superAdmin.plans.add')
            },
            {
                name: 'coupons',
                href: route('superAdmin.plans.coupons.show'),
                current: route().current().startsWith('superAdmin.plans.coupons')
            },
        ],
    }
]

let navigation = page.props.accessType === 'admin' ? navigationAdmin : getNavigationTrainer(page.props.trainer.id)

const sidebarOpen = ref(false)

const selectedTenant = page.props.selected_tenant

function selectTenant(value) {
    router.patch(route('main.tenant.change'), {
        tenant_id: value.id,
    }, {
        preserveState: false,
    });
}

</script>
