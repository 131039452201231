<template>
    <Head :title="$t('billing.settings.long')"/>
    <Layout>
        <div class="flex flex-row align-middle justify-between">
            <Breadcrumbs :pageTree="pageTree"/>
        </div>
        <div class="flex flex-row">

            <div class="flex flex-row">
                <div class="grid grid-cols-1 sm:hidden">
                    <select aria-label="Select a tab"
                            class="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-2 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600"
                            @change="setActiveTab($event.target.value)">
                        <option v-for="tab in tabs" :key="tab.id" :selected="tab.current" :value="tab.id">
                            {{ $t(tab.name) }}
                        </option>
                    </select>
                    <ChevronDownIcon
                        class="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end fill-gray-500"
                        aria-hidden="true"/>
                </div>
                <div class="hidden sm:block">
                    <div class="border-b border-gray-200">
                        <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                            <button v-for="tab in tabs" :key="tab.id"
                                    :class="[tab.current ? 'border-gray-100 text-gray-200' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-400', 'group inline-flex items-center border-b-2 px-1 py-4 text-sm font-medium']"
                                    :aria-current="tab.current ? 'page' : undefined" @click="setActiveTab(tab.id)">
                                <component :is="tab.icon"
                                           :class="[tab.current ? 'text-gray-100' : 'text-gray-400 group-hover:text-gray-500', '-ml-0.5 mr-2 size-5']"
                                           aria-hidden="true"/>
                                <span v-if="tab.type!='direct'">{{ $t(tab.name) }} ({{ $t('billing.account.type.' + tab.type) }})</span>
                                <span v-if="tab.type=='direct'">{{ $t(tab.name) }}</span>
                            </button>
                            <button v-for="tab in extraTabs" :key="tab.id"
                                    :class="[tab.current ? 'border-gray-100 text-gray-200' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-400', 'group inline-flex items-center border-b-2 px-1 py-4 text-sm font-medium']"
                                    :aria-current="tab.current ? 'page' : undefined" @click="setActiveTab(tab.id)">
                                <component :is="tab.icon"
                                           :class="[tab.current ? 'text-gray-100' : 'text-gray-400 group-hover:text-gray-500', '-ml-0.5 mr-2 size-5']"
                                           aria-hidden="true"/>
                                <span>{{ $t(tab.name) }}</span>
                            </button>
                        </nav>
                    </div>
                </div>
            </div>

        </div>

        <div>
            <ShowBillingAccount v-for="account in props.billingAccounts" :key="account.id" :visible="account.id === visibleTab" :billing-account="account" />
            <AddBillingAccount :visible="visibleTab==='add'" />
        </div>
    </Layout>
</template>

<script setup>
import {Head} from "@inertiajs/vue3";
import Breadcrumbs from "../../../../Components/Main/Breadcrumbs.vue";
import Layout from "../../../../Layouts/Main/Layout.vue";
import {inject, reactive, ref} from "vue";
import {ChevronDownIcon} from "@heroicons/vue/16/solid/index.js";
import ShowBillingAccount from "./ShowBillingAccount.vue";
import {PlusIcon} from "@heroicons/vue/24/outline/index.js";
import AddBillingAccount from "./AddBillingAccount.vue";

const route = inject('route');

const pageTree = [
    {name: 'billing.settings.long', href: route('main.billing.settings'), current: true}
]

const props = defineProps({
    billingAccounts: {
        type: Array,
        required: true,
    }
})

let visibleTab = ref(null);
let tabs = reactive([]);

props.billingAccounts.forEach(function (account) {
    tabs.push({
        id: account.id,
        name: account.label,
        type: account.type,
        current: false,
    })
})

const extraTabs = [{
    id: 'add',
    icon: PlusIcon,
    name: 'billing.account.add',
    type: 'new',
    current: false,
}]

function setActiveTab(tabName) {
    visibleTab.value = tabName
    tabs.forEach(tab => {
        tab.current = tab.id === tabName
    })
    extraTabs.forEach(tab => {
        tab.current = tab.id === tabName
    })
}

if (tabs.length > 0) {
    visibleTab.value = tabs[0].id
    tabs[0].current = true
}


</script>
