<template>
    <div class="">
        <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div
                class="relative isolate flex flex-col gap-10 overflow-hidden bg-gray-900 px-6 py-24 shadow-2xl sm:rounded-3xl sm:px-24 xl:flex-row xl:items-center xl:py-32">
                <h2 class="max-w-xl text-balance text-3xl font-semibold tracking-tight text-white sm:text-4xl xl:flex-auto">
                    {{ $t('newsletter.cta') }}</h2>
                <div class="flex gap-x-4 items-center">
                    <div>
                        <label for="email-address" class="sr-only">{{ $t('email.address') }}</label>
                        <input id="email-address" name="email" type="email" autocomplete="email" required=""
                               class="mb-2 min-w-0 flex-auto rounded-md bg-white/5 px-3.5 py-2 text-base text-white outline outline-1 -outline-offset-1 outline-white/10 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-white sm:text-sm/6"
                               :placeholder="$t('email.address')" v-model="email" :disabled="submitDisabled"/>
                        <label for="name" class="sr-only">{{ $t('name') }}</label>
                        <input id="name" name="name" type="text" autocomplete="name" required=""
                               class="min-w-0 flex-auto rounded-md bg-white/5 px-3.5 py-2 text-base text-white outline outline-1 -outline-offset-1 outline-white/10 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-white sm:text-sm/6"
                               :placeholder="$t('your.name')" v-model="name" :disabled="submitDisabled"/>
                    </div>
                    <div>
                        <button @click="submitNewsletter" type="submit"
                                class="flex-none rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-xs hover:bg-gray-100 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                                :disabled="submitDisabled">
                            {{ $t('notify.me') }}
                        </button>
                    </div>
                </div>
                <!--                    <p class="mt-4 text-sm/6 text-gray-300">We care about your data. Read our <a href="#" class="font-semibold text-white">privacy&nbsp;policy</a>.</p>-->
                <svg viewBox="0 0 1024 1024" class="absolute left-1/2 top-1/2 -z-10 size-[64rem] -translate-x-1/2"
                     aria-hidden="true">
                    <circle cx="512" cy="512" r="512" fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
                            fill-opacity="0.7"/>
                    <defs>
                        <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641" cx="0" cy="0" r="1"
                                        gradientUnits="userSpaceOnUse"
                                        gradientTransform="translate(512 512) rotate(90) scale(512)">
                            <stop stop-color="#7775D6"/>
                            <stop offset="1" stop-color="#E935C1" stop-opacity="0"/>
                        </radialGradient>
                    </defs>
                </svg>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref} from "vue";
import axios from "axios";
import {inject} from "vue";

const email = ref('');
const name = ref('');
const submitDisabled = ref(false);
const route = inject('route')

function submitNewsletter() {
    if (email.value) {
        // Perform the newsletter subscription logic here
        axios.post(route('main.newsletter.api.subscribe'), {
            email: email.value,
            name: name.value
        })
        // Reset the input field after submission
        submitDisabled.value = true;
        alert('Thank you for subscribing to our newsletter!');
    }
}
</script>
