<script setup>
import Layout from "../../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../../Components/Main/Breadcrumbs.vue";
import {Head, Link, router, useForm} from '@inertiajs/vue3'
import {inject, ref} from 'vue';
import Input from "../../../../Components/Form/Input.vue";
import Select from "../../../../Components/Form/Select.vue";
import { VueDraggable } from 'vue-draggable-plus'
import {ChevronUpDownIcon} from "@heroicons/vue/24/solid";
import Alert from "../../../../Components/Alert.vue";

const route = inject('route');
const props = defineProps({
    form: {
        type: Object,
        required: true,
    },
    question: {
        type: Object,
        required: true,
    },
})
const pageTree = [
    {name: 'feedback.forms', href: route('main.feedback.forms'), current: false},
    {name: 'feedback.forms.edit', href: route('main.feedback.forms.edit', props.form.id), current: false},
    {name: 'question.edit', href: route('main.feedback.forms.question.edit', {form: props.form.id, question: props.question.id}), current: true}
]

const internalForm = useForm({
    question: props.question.question,
    type: props.question.type,
    required: props.question.required,
    active: props.question.active,
    extra: props.question.extra,
});

let internalRatingNumber = ref(3)
let internalRatingType = ref('stars')
let internalOptions = ref([{
    value: '',
    id: 0,
}])
let optionsError = ref('')

if (internalForm.type==='multipleChoice' || internalForm.type==='dropdown') {
    if (props.question.extra) {
        const extra = JSON.parse(props.question.extra);
        if (extra.options) {
            internalOptions.value = extra.options.map((option, index) => {
                return {
                    value: option.value,
                    id: index,
                }
            })
        }
    }
}
if (internalForm.type==='rating') {
    if (props.question.extra) {
        const extra = JSON.parse(props.question.extra);
        if (extra.rating) {
            internalRatingNumber.value = extra.rating.ratingNumber
            internalRatingType.value = extra.rating.ratingType
        }
    }
}

function addOption(value) {
    optionsError.value='';
    internalOptions.value.push({
        value: value,
        id: internalOptions.value.length,
    })
}

function removeOption(index) {
    if (internalOptions.value.length <= 1) {
        optionsError.value = 'error.need.atleast.one.option'
        return
    }
    internalOptions.value.splice(index, 1);
}

function submit() {
    switch (internalForm.type) {
        case 'rating':
            internalForm.extra = JSON.stringify({
                rating: {
                    ratingNumber: internalRatingNumber.value,
                    ratingType: internalRatingType.value
                }
            })
            break
        case 'multipleChoice':
        case 'dropdown':
            internalForm.extra = JSON.stringify({
                options: internalOptions.value.map((option) => {
                    return {
                        value: option.value,
                    }
                })
            })
            break
        default:
            break
    }
    internalForm.patch(route('main.feedback.forms.question.patch', {form: props.form.id, question: props.question.id}), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.feedback.forms.edit', props.form.id))
        }
    })
}

function updateQuestionType(value) {
    internalForm.type = value
}


let deleteModalOpen = ref(false)

function openRemoveModal() {
    deleteModalOpen.value = true
}

function closeRemoveModal() {
    deleteModalOpen.value = false
}

function remove() {
    closeRemoveModal()
    router.delete(route('main.feedback.forms.question.delete',{form: props.form.id, question: props.question.id}), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.feedback.forms.edit',props.form.id))
        }
    })
}

</script>

<template>
    <Head :title="$t('feedback.forms.edit.edit.question')"/>
    <Layout>
        <div class="flex flex-row align-middle justify-between">
            <Breadcrumbs :pageTree="pageTree"/>
        </div>
        <form @submit.prevent="submit">
            <div class="space-y-12">
                <div class="border-b border-white/10 pb-12">
                    <h2 class="text-base/7 font-semibold text-white">{{ $t('information.feedback.form.question') }}</h2>
                    <p class="mt-1 text-sm/6 text-gray-400">{{ $t('information.feedback.forms.attention') }}</p>

                    <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <Input name="question"
                               :label="$t('question.single')"
                               :placeholder="$t('question.single')"
                               v-model="internalForm.question"
                               :error="internalForm.errors.question"
                               class="sm:col-span-4"/>
                        <Input name="required"
                               :label="$t('question.required')"
                               type="checkbox"
                               v-model="internalForm.required"
                               :error="internalForm.errors.required"
                               class="sm:col-span-4"/>
                        <Input name="active"
                               :label="$t('question.active')"
                               type="checkbox"
                               v-model="internalForm.active"
                               :error="internalForm.errors.active"
                               class="sm:col-span-4"/>
                        <Select name="type"
                                :label="$t('question.type')"
                                v-model="internalForm.type"
                                :options="[
                                    {value: 'text', label: $t('question.text')},
                                    {value: 'paragraph', label: $t('question.paragraph')},
                                    {value: 'multipleChoice', label: $t('question.multipleChoice')},
                                    // {value: 'checkbox', label: $t('question.checkbox')},
                                    {value: 'dropdown', label: $t('question.dropdown')},
                                    {value: 'rating', label: $t('question.rating')},
                                ]"
                                :error="internalForm.errors.type"
                                class="sm:col-span-4"
                                @update:modelValue="updateQuestionType"/>
                        <Input v-if="internalForm.type==='rating'" name="ratingNumber"
                               :label="$t('question.ratingNumber')"
                               :placeholder="$t('question.ratingNumber')"
                               v-model="internalRatingNumber"
                               :error="internalForm.errors.ratingNumber"
                               type="number" min="3" max="10"
                               class="sm:col-span-4"/>
                        <Select v-if="internalForm.type==='rating'" name="ratingType"
                                :label="$t('question.ratingType')"
                                v-model="internalRatingType"
                                :options="[
                                    {value: 'stars', label: $t('question.stars') + ' ⭐'},
                                    {value: 'hearts', label: $t('question.hearts') + ' ❤'},
                                    {value: 'thumbsup', label: $t('question.thumbsup') + ' 👍'},
                                ]"
                                :error="internalForm.errors.ratingType"
                                class="sm:col-span-4"/>

                    </div>
                    <div v-if="internalForm.type==='multipleChoice' || internalForm.type==='dropdown'" class="w-96">
                        <VueDraggable ref="el" v-model="internalOptions" :animation="150" handle=".handle">
                        <div v-for="(input,index) in internalOptions" :key="`options-${input.id}`"
                             class="sm:col-span-4 flex items-center">
                            <ChevronUpDownIcon class="h-8 w-8 handle"/>
                            <Input name="option"
                                   :placeholder="$t('question.option')"
                                   v-model="input.value"
                                   :error="internalForm.errors.option"
                                   class="sm:col-span-4" :has-remove-button="true" @remove="removeOption(index)"/>
                            <br />

                        </div>
                        </VueDraggable>
                        <div v-if="optionsError!==''" class="text-red-700">{{ $t(optionsError)}}</div>
                        <div class="pt-2">
                            <button type="button" @click="addOption()"
                                    class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                                {{ $t('option.add') }}
                            </button>
                        </div>
                    </div>
                </div>

            </div>

            <div class="mt-6 flex items-center justify-end gap-x-6">
                <button type="button"
                        class="rounded-md bg-red-800 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-red-700 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
                        @click="openRemoveModal">{{ $t('delete') }}</button>
                <Link :href="route('main.feedback.forms.edit',props.form.id)" as="button"
                      class="text-sm/6 font-semibold text-white">
                    {{ $t('cancel') }}
                </Link>
                <button type="submit"
                        class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    {{ $t('save') }}
                </button>
            </div>
            <Alert :open="deleteModalOpen" :title="$t('delete')+' '+$t('feedback.form')" :message="$t('confirm.delete')+' '+$t('feedback.form.question')+'? '+$t('data.permanently.removed')" :confirmLabel="$t('delete')" :cancelLabel="$t('cancel')"
                   @modal-close="closeRemoveModal" @modal-confirmed="remove"/>
        </form>
    </Layout>
</template>
