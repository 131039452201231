<template>
    <div :class="props.class">
        <label :for="props.name" class="block text-sm/6 font-medium text-white">{{props.label}}</label>
        <div class="mt-2">
            <textarea :name="props.name" :id="props.name" :rows="props.rows" v-model="modelValue" :placeholder="props.placeholder"
                      class="block w-full rounded-md bg-white/5 px-3 py-1.5 text-base text-white outline outline-1 -outline-offset-1 outline-white/10 placeholder:text-gray-500 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-500 sm:text-sm/6"/>
        </div>
        <div v-if="props.error" class="text-red-700">{{ props.error }}</div>
        <p v-if="props.instructions" class="mt-3 text-sm/6 text-gray-400">{{ props.instructions }}</p>
    </div>
</template>

<script setup>
const props = defineProps({
    name: {
        type: String,
        required: true,
    },
    label: {
        type: String,
    },
    rows: {
        type: Number,
        default: 5,
    },
    placeholder: {
        type: String,
        default: '',
    },
    error: {
        type: String,
        default: '',
    },
    class: {
        type: String,
        default: 'col-span-full',
    },
    instructions: {
        type: String,
        default: '',
    }
});

const modelValue = defineModel()

</script>
