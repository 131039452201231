<script setup>
import Layout from "../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../Components/Main/Breadcrumbs.vue";
import {Link} from "@inertiajs/vue3";
import {PlusCircleIcon} from "@heroicons/vue/24/outline/index.js";
import {Head} from '@inertiajs/vue3'
import {ChevronRightIcon} from "@heroicons/vue/20/solid/index.js";
import { inject } from 'vue';

const route = inject('route');

const props = defineProps({
    location: {
        type: Object,
        required: true,
    }
})

const pageTree = [
    {name: 'locations', href: route('main.locations'), current: false},
    {name: props.location.name, href: route('main.locations.edit',props.location.slug), current: false},
    {name: 'rooms.capitalized', href: route('main.locations.rooms',props.location.slug), current: true}
]
</script>

<template>
    <Head title="Rooms"/>
    <Layout>
        <div class="flex flex-row align-middle justify-between">
            <Breadcrumbs :pageTree="pageTree"/>
            <Link :href="route('main.locations.rooms.add',location)" class="" as="button">
                <PlusCircleIcon class="size-8 text-green-600 my-2 mb-6" aria-hidden="true" aria-label="Add room"/>
            </Link>
        </div>

        <ul role="list" class="divide-y divide-gray-700">
            <li v-for="room in location.rooms" :key="room.id" class="relative flex justify-between gap-x-6 py-5">
                <div class="flex min-w-0 gap-x-4">
                    <!--                <img class="size-12 flex-none rounded-full bg-gray-50" :src="item.imageUrl" alt="" />-->
                    <div class="min-w-0 flex-auto">
                        <p class="text-sm/6 font-semibold text-white">
                            <Link :href="route('main.locations.rooms.edit',[location,room])" aria-hidden="true">
                                {{ room.name }}
                            </Link>
                        </p>
                    </div>
                </div>
                <div class="flex shrink-0 items-center gap-x-1">
                    <div class="hidden sm:flex sm:flex-col sm:items-end">
                        <p v-if="room.contract_items.length>0" class="mt-1 text-xs/5 text-gray-500">
                            {{ $t('activities.plural', {value: room.contract_items.length}) }}
                        </p>
                    </div>
                    <Link :href="route('main.locations.rooms.edit',[location,room])"
                          aria-hidden="true">
                        <ChevronRightIcon class="size-5 flex-none text-gray-400" aria-hidden="true"/>
                    </Link>
                </div>
            </li>
        </ul>
    </Layout>
</template>
