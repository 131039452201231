<script setup>
import Layout from "../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../Components/Main/Breadcrumbs.vue";
import {Link, router, useForm} from "@inertiajs/vue3";
import { Head } from '@inertiajs/vue3'
import RoomForm from "./RoomForm.vue";
import { inject } from 'vue';

const route = inject('route');

const props = defineProps({
    location: {
        type: Object,
        required: true,
    }
})

const pageTree = [
    {name: 'locations', href: route('main.locations'), current: false},
    {name: props.location.name, href: route('main.locations.edit',props.location), current: false},
    {name: 'rooms.capitalized', href: route('main.locations.rooms',props.location), current: false},
    {name: 'room.add', href: route('main.locations.rooms.add',props.location), current: true}
]

const form = useForm({
    name: '',
    description: '',
    capacity: 1,
})

function submit() {
    form.post(route('main.locations.rooms.store',props.location), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.locations.rooms',props.location))
        }
    })
}

</script>

<template>
    <Head :title="$t('room.add')" />
    <Layout>
        <Breadcrumbs :pageTree=pageTree />
        <form @submit.prevent="submit">
            <RoomForm :form="form"/>

            <div class="mt-6 flex items-center justify-end gap-x-6">
                <Link :href="route('main.locations.rooms',props.location)" as="button" class="text-sm/6 font-semibold text-white">{{ $t('cancel')}}
                </Link>
                <button type="submit"
                        class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    {{ $t('save')}}
                </button>
            </div>
        </form>
    </Layout>
</template>
