<script setup>
import Layout from "../../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../../Components/Main/Breadcrumbs.vue";
import {Link} from "@inertiajs/vue3";
import {PlusCircleIcon} from "@heroicons/vue/24/outline/index.js";
import {Head} from '@inertiajs/vue3'
import { inject } from 'vue';
import {localeDate} from "../../../../helpers.js";
const route = inject('route');


const props = defineProps({
    student: {
        type: Object,
        required: true,
    },
    scheduledClasses: {
        type: Array,
        required: true,
    },
    contract: {
        type: Object,
        required: true,
    }
})
const pageTree = [
    {name: 'schedule.student', href: route('main.schedule.student'), current: false},
    {name: props.student.name, href: route('main.schedule.student.detail',[props.student,props.contract]), current: true}
]

</script>

<template>
    <Head :title="$t('schedule.student')"/>
    <Layout>
        <div class="flex flex-row align-middle justify-between">
            <Breadcrumbs :pageTree="pageTree"/>
            <Link :href="route('main.schedule.student.add',[props.student,props.contract])" class="" as="button">
                <PlusCircleIcon class="size-8 text-green-600 my-2 mb-6" aria-hidden="true" aria-label="Add scheduled class"/>
            </Link>
        </div>

        <ul role="list" class="divide-y divide-gray-700">
            <li v-for="scheduledClass in scheduledClasses" :key="scheduledClass.id" class="relative flex justify-between gap-x-6 py-5">
                <div class="flex min-w-0 gap-x-4">
                    <!--                <img class="size-12 flex-none rounded-full bg-gray-50" :src="item.imageUrl" alt="" />-->
                    <div class="min-w-0 flex-auto">
                        <Link :href="route('main.schedule.student.edit',[props.student,props.contract,scheduledClass])" class="block">
                        <p class="text-sm/6 font-semibold text-white">
                                {{ scheduledClass.activity.name }}
                        </p>
                        </Link>
                        <ul class="mt-1 text-xs/5 text-gray-500 flex flex-wrap items-start gap-x-2">
                            <li>
                                {{ localeDate(scheduledClass.start_date,'long') + " - " + localeDate(scheduledClass.end_date,'short') }} &bull;
                            </li>
                            <li v-if="scheduledClass.trainer">{{ scheduledClass.trainer.name }} &bull;</li>
                            <li v-if="scheduledClass.location">{{ scheduledClass.location.name }} ({{ scheduledClass.room.name }})</li>
                        </ul>
                    </div>
                </div>
                <div class="flex shrink-0 items-center gap-x-4">

                </div>
            </li>
        </ul>
    </Layout>
</template>
