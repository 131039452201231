<template>
    <Head :title="$t('invoices.all')"/>
    <Layout>
        <div class="flex flex-row align-middle justify-between">
            <Breadcrumbs :pageTree="pageTree"/>
        </div>
        <InvoiceListTable :invoices="props.invoices.data" :sortBy="sortBy" :sortDirection="sortDirection"/>
        <InvoiceListPaginator :invoices="props.invoices"/>
    </Layout>
</template>

<script setup>
import {Head} from "@inertiajs/vue3";
import Breadcrumbs from "../../../../Components/Main/Breadcrumbs.vue";
import Layout from "../../../../Layouts/Main/Layout.vue";
import {inject} from "vue";
import InvoiceListTable from "./InvoiceListTable.vue";
import InvoiceListPaginator from "./InvoiceListPaginator.vue";

const route = inject('route');

const pageTree = [
    {name: 'invoices.all', href: route('main.billing.invoices.index'), current: true}
]

const props = defineProps({
    invoices: {
        type: Object,
        required: true,
    },
    sortBy: {
        type: String,
        required: false,
        default: 'issue_date',
    },
    sortDirection: {
        type: String,
        required: false,
        default: 'desc',
    },
})
</script>
