<template>
    <div class="border-b border-white/10 " v-if="props.price.bundles.length>0">
        <h2 class="text-base/7 font-semibold text-white">{{$t('bundle')}}</h2>
        <p class="mt-1 text-sm/6 text-gray-400">{{$t('information.bundle')}}</p>

        <ul role="list" class="divide-y divide-gray-700">
            <li v-for="item in props.price.bundles" :key="item.id" class="relative flex justify-between gap-x-6 py-5">
                <Link :href="route('main.prices.bundle.edit',{price: price.id, bundle: item.id})" as="button">
                    <div class="flex min-w-0 gap-x-1">
                        <span>{{ $t('classes.plural',{value: item.quantity}) }} &bullet; </span>
                        <span>{{  $t('priced.at') }} {{ item.price }} {{ currency }} &bullet;</span>
                        <span> {{ $t('valid.from') }}: {{ price.start_date }} &bullet;</span>
                        <span v-if="item.end_date">{{  $t('valid.until') }}: {{ price.end_date }} &bullet;</span>
                        <span>{{  $t('validity') }}: {{ item.expiry_days }} {{  $t('days') }}</span>
                    </div>
                </Link>

                <div class="flex shrink-0 items-center gap-x-4">
                    <div class="hidden sm:flex sm:flex-col sm:items-end">
                    </div>
                    <Link :href="route('main.prices.bundle.edit',{price: price.id, bundle: item.id})" class="size-5 flex-none text-gray-400"
                          aria-hidden="true">
                        <ChevronRightIcon class="size-5 flex-none text-gray-400" aria-hidden="true"/>
                    </Link>
                </div>
            </li>
        </ul>
    </div>
</template>

<script setup>
import {ChevronRightIcon} from "@heroicons/vue/20/solid/index.js";
import {Link} from "@inertiajs/vue3";

const props = defineProps({
    price: {
        type: Object,
        required: true
    },
    currency: {
        type: String,
        required: true,
    }
})
</script>
