<script setup>
import Layout from "../../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../../Components/Main/Breadcrumbs.vue";
import {Link, router, useForm} from "@inertiajs/vue3";
import { Head } from '@inertiajs/vue3'
import BundleForm from "./BundleForm.vue";
import { inject } from 'vue';

const route = inject('route');

const props = defineProps({
    price: {
        type: Object,
        required: true,
    },
    currency: {
        type: String,
        required: true,
    }
})

const pageTree = [
    {name: 'pricing', href: route('main.prices'), current: false},
    {name: 'pricing.edit', href: route('main.prices.edit',props.price.id), current: false},
    {name: 'bundle.add', href: route('main.prices.bundle.add',props.price.id), current: true}
]

var date = new Date();
var today = date.toISOString().substring(0,10);

const form = useForm({
    price: 0,
    start_date: today,
    end_date: '',
    expiry_days: 30,
    quantity: 4,
    cancelNumber: 0,
    cancelBeforeInterval: 0,
})

function submit() {
    form.post(route('main.prices.bundle.store',props.price.id), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.prices.edit',props.price.id))
        }
    })
}

</script>

<template>
    <Head :title="$t('bundle.add')" />
    <Layout>
        <Breadcrumbs :pageTree=pageTree />
        <form @submit.prevent="submit">
            <BundleForm :form="form" :currency="currency"/>

            <div class="mt-6 flex items-center justify-end gap-x-6">
                <Link :href="route('main.prices.edit',props.price.id)" as="button" class="text-sm/6 font-semibold text-white">{{$t('cancel') }}
                </Link>
                <button type="submit"
                        class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    {{$t('save')}}
                </button>
            </div>
        </form>
    </Layout>
</template>
