<script setup>
import Layout from "../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../Components/Main/Breadcrumbs.vue";
import {Link, router, useForm} from "@inertiajs/vue3";
import { Head } from '@inertiajs/vue3'
import ActivityForm from "./ActivityForm.vue";
import { inject } from 'vue';

const route = inject('route');

const pageTree = [
    {name: 'classes', href: route('main.activities'), current: false},
    {name: 'class.add', href: route('main.activities.add'), current: true},
]

const form = useForm({
    name: '',
    description: '',
    curriculum: '',
    age_min: '',
    age_max: '',
    capacity: '',
    duration: '',
    trainers: [],
    locations: [],
})

function submit() {
    form.post(route('main.activities.store'), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.activities'))
        }
    })
}

const props = defineProps({
    trainers: {
        type: Array,
        required: true,
    },
    locations: {
        type: Array,
        required: true,
    }
})

</script>

<template>
    <Head :title="$t('class.add')" />
    <Layout>
        <Breadcrumbs :pageTree=pageTree />
        <form @submit.prevent="submit" >
            <ActivityForm :form="form" :trainers="props.trainers" :locations="props.locations"/>
            <div class="mt-6 flex items-center justify-end gap-x-6">
                <Link :href="route('main.activities')" as="button" class="text-sm/6 font-semibold text-white">{{$t('cancel')}}
                </Link>
                <button type="submit"
                        class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    {{$t('save')}}
                </button>
            </div>
        </form>
    </Layout>
</template>
