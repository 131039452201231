<template>
    <Head :title="$t('billing.settings.integration.smartbill')"/>
    <Layout>
        <div class="flex flex-row align-middle justify-between">
            <Breadcrumbs :pageTree="pageTree"/>
        </div>
        <form @submit.prevent="submit">
            <div class="flex flex-row">
                <div class="space-y-12">
                    <div class="border-b border-white/10 pb-12 mt-10">
                        <SelectModel name="proforma_series_id" :options="smartbillDocumentSeries"
                                     :label="$t('billing.settings.integration.smartbill.proforma.document.series')"
                                     v-model="form.proforma_series_id" :error="form.errors.proforma_series_id"/>
                        <SelectModel name="invoice_series_id" :options="smartbillDocumentSeries"
                                     :label="$t('billing.settings.integration.smartbill.invoice.document.series')"
                                     v-model="form.invoice_series_id" :error="form.errors.invoice_series_id"/>
                        <SelectModel name="vat_id" :options="smartbillVAT"
                                     :label="$t('billing.settings.integration.smartbill.vat')" v-model="form.vat_id"
                                     :error="form.errors.smartbill_vat_id" allowNone="true"/>
                    </div>
                </div>
            </div>
            <div class="mt-6 flex items-center justify-end gap-x-6">
                <button type="submit"
                        class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    {{ $t('save') }}
                </button>
            </div>
        </form>
    </Layout>
</template>

<script setup>
import {Head, router, useForm} from "@inertiajs/vue3";
import Breadcrumbs from "../../../../../Components/Main/Breadcrumbs.vue";
import Layout from "../../../../../Layouts/Main/Layout.vue";
import {inject} from "vue";
import SelectModel from "../../../../../Components/Form/SelectModel.vue";

const route = inject('route');

const props = defineProps({
    smartbillSettings: {
        type: Object,
        required: true
    },
    billingAccount: {
        type: Object,
        required: true
    },
    smartbillDocumentSeries: {
        type: Array,
        required: true
    },
    smartbillVAT: {
        type: Array,
        required: true
    }
})

const form = useForm({
    proforma_series_id: props.smartbillSettings.proforma_series_id,
    invoice_series_id: props.smartbillSettings.invoice_series_id,
    vat_id: props.smartbillSettings.smartbill_vat_id,
})
const pageTree = [
    {name: 'billing.settings.long', href: route('main.billing.settings'), current: false},
    {name: props.billingAccount.label, href: '#', current: false},
    {
        name: 'billing.settings.integration.smartbill',
        href: route('main.billing.settings.integrations.smartbill', props.billingAccount.id),
        current: true
    }
]


function submit() {
    form.patch(route('main.billing.settings.integrations.smartbill.patch', props.billingAccount.id), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.billing.settings'))
        }
    })
}

</script>
<script setup>

</script>
