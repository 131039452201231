<template>
    <div class="space-y-12">
        <div class="border-b border-white/10 pb-12">
            <h2 class="text-base/7 font-semibold text-white">{{ $t('trainer.information')}}</h2>
            <p class="mt-1 text-sm/6 text-gray-400">{{ $t('information.public')}}</p>

            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <Input name="name" type="text" :label="$t('name')" size="30" v-model="props.form.name" :placeholder="$t('trainer.name')" :error="props.form.errors.name"/>
                <TextArea name="description" :label="$t('description')" :rows=5 v-model="props.form.description" :placeholder="$t('description')" :error="props.form.errors.description" :instructions="$t('instructions.describe.trainer')"/>
            </div>
            <div class="mt-10">
                <label for="photo" class="block text-sm/6 font-medium text-white">{{  $t('photo') }}</label>
                <div class="mt-2 flex items-center gap-x-3">
                    <img v-if="imageUrl" :src="imageUrl" class="size-12" />
                    <button v-if="imageUrl" type="button" class="rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-white/20" @click="imageUrl=''">{{ $t('change')}}</button>
                    <UserCircleIcon v-if="!imageUrl" class="size-12 text-gray-500" aria-hidden="true" />
                    <input v-if="!imageUrl" type="file" id="photo" name="photo" @change="photoUploaded($event.target.files)">
                </div>
            </div>
        </div>

        <div class="border-b border-white/10 pb-12">
            <h2 class="text-base/7 font-semibold text-white">{{$t('trainer.details')}}</h2>
            <p class="mt-1 text-sm/6 text-gray-400">{{$t('information.trainer.reach')}}</p>
            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <Input name="email" type="email" :label="$t('email.address')" size="30" v-model="props.form.email" :placeholder="$t('email.address')" :error="props.form.errors.email"/>
                <Input name="phone" type="tel" :label="$t('phone.number')" size="30" v-model="props.form.phone" :placeholder="$t('phone.number')" :error="props.form.errors.phone"/>
                <Input name="website" type="url" :label="$t('website')" size="30" v-model="props.form.website" :placeholder="$t('website')" :error="props.form.errors.website"/>
                <Input name="facebook" type="url" label="Facebook" size="30" v-model="props.form.facebook"
                       placeholder="Facebook" :error="props.form.errors.facebook"/>
                <Input name="instagram" type="url" label="Instagram" size="30" v-model="props.form.instagram"
                       placeholder="Instagram" :error="props.form.errors.instagram"/>
                <Input name="twitter" type="url" label="Twitter" size="30" v-model="props.form.twitter"
                       placeholder="Twitter" :error="props.form.errors.twitter"/>
                <Input name="youtube" type="url" label="YouTube" size="30" v-model="props.form.youtube"
                       placeholder="YouTube" :error="props.form.errors.youtube"/>
                <Input name="linkedin" type="url" label="LinkedIn" size="30" v-model="props.form.linkedin"
                       placeholder="LinkedIn" :error="props.form.errors.linkedin"/>
                <Input name="pinterest" type="url" label="Pinterest" size="30" v-model="props.form.pinterest"
                       placeholder="Pinterest" :error="props.form.errors.pinterest"/>
                <Input name="tiktok" type="url" label="TikTok" size="30" v-model="props.form.tiktok"
                       placeholder="TikTok" :error="props.form.errors.tiktok"/>
                <Input name="snapchat" type="url" label="Snapchat" size="30" v-model="props.form.snapchat"
                       placeholder="Snapchat" :error="props.form.errors.snapchat"/>

            </div>
            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <SelectMultiple name="locations[]" :label="$t('locations')" :options="$page.props.locations" v-model="props.form.locations" :error="props.form.errors.locations" class="sm:col-span-1"/>
                <SelectMultiple name="activities[]" :label="$t('classes')" :options="$page.props.activities" v-model="props.form.activities" :error="props.form.errors.activities" class="sm:col-span-1"/>
            </div>
        </div>
    </div>
</template>

<script setup>
import TextArea from "../../../Components/Form/TextArea.vue";
import {UserCircleIcon} from "@heroicons/vue/24/solid/index.js";
import Input from "../../../Components/Form/Input.vue";
import SelectMultiple from "../../../Components/Form/SelectMultiple.vue";

const props=defineProps({
    form: {
        type: Object,
        required: true
    },
    imageUrl: {
        type: String,
        required: false
    }
})

</script>
