<template>
    <div class="border-b border-white/10 " v-if="props.plan.prices.length>0">

        <ul role="list" class="divide-y divide-gray-700">
            <li v-for="item in props.plan.prices" :key="item.id" class="relative flex justify-between gap-x-6 py-5">
                <Link :href="route('superAdmin.plans.prices.edit',{plan: plan.id, price: item.id})" as="button">
                    <div class="flex min-w-0 gap-x-1">
                        <span>{{ $t('months',{value: item.duration})}} {{  $t('priced.at') }} {{ item.price }} RON</span>
                    </div>
                </Link>

                <div class="flex shrink-0 items-center gap-x-4">
                    <div class="hidden sm:flex sm:flex-col sm:items-end">
                    </div>
                    <Link :href="route('superAdmin.plans.prices.edit',{plan: plan.id, price: item.id})" class="size-5 flex-none text-gray-400"
                          aria-hidden="true">
                        <ChevronRightIcon class="size-5 flex-none text-gray-400" aria-hidden="true"/>
                    </Link>
                </div>
            </li>
        </ul>
    </div>
</template>

<script setup>
import {ChevronRightIcon} from "@heroicons/vue/20/solid/index.js";
import {Link} from "@inertiajs/vue3";

const props = defineProps({
    plan: {
        type: Object,
        required: true
    },
})
</script>
