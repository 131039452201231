<script setup>
import Layout from "../../../../Layouts/Main/Layout.vue";
import Breadcrumbs from "../../../../Components/Main/Breadcrumbs.vue";
import {Link, router, useForm} from "@inertiajs/vue3";
import { Head } from '@inertiajs/vue3'
import SubscriptionForm from "./SubscriptionForm.vue";
import { inject } from 'vue';

const route = inject('route');

const props = defineProps({
    contract: {
        type: Object,
        required: true,
    },
    subscription: {
        type: Object,
        required: true,
    }
})

const pageTree = [
    {name: 'contracts', href: route('main.contracts'), current: false},
    {name: 'contract.edit', href: route('main.contracts.edit',props.contract.id), current: false},
    {name: 'subscription.edit', href: route('main.contracts.subscriptions.edit',[props.contract.id,props.subscription.id]), current: true},
]

const form = useForm({
    start_date: props.subscription.start_date,
    end_date: props.subscription.end_date,
    status: props.subscription.status,
})

function submit() {
    form.patch(route('main.contracts.subscriptions.patch',[props.contract.id,props.subscription.id]), {
        preserveScroll: true,
        onSuccess: () => {
            router.visit(route('main.contracts.subscriptions',props.contract.id))
        }
    })
}

const statusOptions = [
    {
        value: 'pending',
        label: 'pending'
    },
    {
        value: 'active',
        label: 'active'
    },
    {
        value: 'completed',
        label: 'completed'
    },
    {
        value: 'expired',
        label: 'expired'
    },
    {
        value: 'cancelled',
        label: 'cancelled'
    }
]

</script>

<template>
    <Head :title="$t('subscription.edit')" />
    <Layout>
        <Breadcrumbs :pageTree=pageTree />
        <form @submit.prevent="submit">
            <SubscriptionForm :status-options="statusOptions" :form="form"/>

            <div class="mt-6 flex items-center justify-end gap-x-6">
                <Link :href="route('main.contracts.subscriptions',props.contract.id)" as="button" class="text-sm/6 font-semibold text-white">{{ $t('cancel') }}
                </Link>
                <button type="submit"
                        class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    {{ $t('save') }}
                </button>
            </div>
        </form>
    </Layout>
</template>
